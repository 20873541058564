/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MimeTypeDto } from './mimeTypeDto';
import { SectionItemType } from './sectionItemType';
import { ContentItemMetaDto } from './contentItemMetaDto';
import { PermissionTypeDto } from './permissionTypeDto';


export interface ContentItemDto { 
    id: string;
    name: string;
    mimeType: MimeTypeDto;
    fileHash: string;
    creationTimestampUtc: Date;
    lastModifiedTimestampUtc: Date;
    size: number;
    thumbnail: string;
    blobFileName: string;
    contentItemMetas: Array<ContentItemMetaDto>;
    userId: string;
    copiedFrom?: string | null;
    permissionType?: PermissionTypeDto;
    type: SectionItemType;
    workspaceId: string;
    parentId?: string | null;
}
export namespace ContentItemDto {
}


