import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface NoteStatus {
  active: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotePopupService {
  public notePopupStatus: NoteStatus = { active: false };
  public noteStatusChange: Subject<NoteStatus> = new Subject<NoteStatus>();
  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  public noteAdded = new EventEmitter();

  constructor() {}

  public get(): NoteStatus {
    return this.notePopupStatus;
  }

  public hide(): void {
    this.notePopupStatus.active = false;
    this.hidden.emit(true);
  }

  public show(): void {
    this.notePopupStatus.active = true;
  }
}
