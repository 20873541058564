import { Component, Input } from '@angular/core';
import { PageSliderService } from '@shared/services/page-slider.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  @Input() fileUrl: string | undefined = '';
  @Input() fileName: string | undefined = '';
  @Input() fileSize: number = 0;
  sliderExpanded: boolean = false;

  docSupport = [
    'TXT',
    'CSS',
    'HTML',
    'PHP',
    'C',
    'CPP',
    'H',
    'HPP',
    'JS',
    'DOC',
    'DOCX',
    'XLS',
    'XLSX',
    'PPT',
    'PPTX',
    'PDF',
    'PAGES',
    'AI',
    'PSD',
    'TIFF',
    'DXF',
    'SVG',
    'EPS',
    'PS',
    'TTF',
    'XPS',
  ];

  imgSupport = ['JPG', 'JPEG', 'PNG', 'GIF', 'BMP'];
  videoSupport = ['WEBM', 'OGG', 'MP4', '3GP'];

  constructor(private pageSliderService: PageSliderService) {
    this.pageSliderService.pageSliderStatus$.pipe(takeUntilDestroyed()).subscribe((status) => {
      this.sliderExpanded = status.active;
    });
  }

  get tooBig(): boolean {
    return this.fileSize > 20000000;
  }

  get isSupportedFile(): boolean {
    return this.isImage || this.isVideo || this.isDoc;
  }

  get showPreview(): boolean {
    return !this.tooBig && this.isSupportedFile;
  }

  get fileType(): string {
    const fileBreak = this.fileName?.split('.') || [];
    return fileBreak[fileBreak.length - 1]?.toUpperCase();
  }

  get isImage(): boolean {
    return this.imgSupport.includes(this.fileType);
  }

  get isVideo(): boolean {
    return this.videoSupport.includes(this.fileType);
  }

  get isDoc(): boolean {
    return this.docSupport.includes(this.fileType);
  }
}
