export class Shaders {
  fragmentShader = `
	flat varying vec4 vertexColor;
	varying float batchid;

	#define LAMBERT
	uniform vec3 diffuse;
	uniform vec3 emissive;
	uniform float opacity;
	#include <common>
	#include <packing>
	#include <dithering_pars_fragment>
	#include <color_pars_fragment>
	#include <uv_pars_fragment>
	#include <map_pars_fragment>
	#include <alphamap_pars_fragment>
	#include <alphatest_pars_fragment>
	#include <aomap_pars_fragment>
	#include <lightmap_pars_fragment>
	#include <emissivemap_pars_fragment>
	#include <envmap_common_pars_fragment>
	#include <envmap_pars_fragment>
	#include <fog_pars_fragment>
	#include <bsdfs>
	#include <lights_pars_begin>
	#include <normal_pars_fragment>
	#include <lights_lambert_pars_fragment>
	#include <shadowmap_pars_fragment>
	#include <bumpmap_pars_fragment>
	#include <normalmap_pars_fragment>
	#include <specularmap_pars_fragment>
	#include <logdepthbuf_pars_fragment>
	#include <clipping_planes_pars_fragment>

	void main() {
		#include <clipping_planes_fragment>

		vec4 diffuseColor = vertexColor;

		ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
		vec3 totalEmissiveRadiance = emissive;
		// #include <logdepthbuf_fragment>
		// #include <map_fragment>
		// #include <color_fragment>
		#include <alphamap_fragment>
		#include <alphatest_fragment>
		// #include <normal_fragment_maps>
		// #include <emissivemap_fragment>
		#include <specularmap_fragment>
		#include <normal_fragment_begin>
		#include <lights_lambert_fragment>
		#include <lights_fragment_begin>
		#include <lights_fragment_maps>
		#include <lights_fragment_end>
		#include <aomap_fragment>
		vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
		#include <envmap_fragment>
		#include <opaque_fragment>
		#include <tonemapping_fragment>
		#include <colorspace_fragment>
		#include <fog_fragment>
		#include <premultiplied_alpha_fragment>
		#include <dithering_fragment>
	}`;

  vertexShader = `
	attribute vec4 _vertexColor;
	flat varying vec4 vertexColor;

	#define LAMBERT
	varying vec3 vViewPosition;

	#include <common>
	#include <uv_pars_vertex>
	#include <displacementmap_pars_vertex>
	#include <envmap_pars_vertex>
	#include <color_pars_vertex>
	#include <fog_pars_vertex>
	#include <normal_pars_vertex>
	#include <morphtarget_pars_vertex>
	#include <skinning_pars_vertex>
	#include <shadowmap_pars_vertex>
	#include <logdepthbuf_pars_vertex>
	#include <clipping_planes_pars_vertex>

	void main() {

		#include <uv_vertex>
		vertexColor = _vertexColor;

		#include <color_vertex>
		#include <morphcolor_vertex>
		#include <beginnormal_vertex>
		#include <morphnormal_vertex>
		#include <skinbase_vertex>
		#include <skinnormal_vertex>
		#include <defaultnormal_vertex>
		#include <normal_vertex>
		#include <begin_vertex>
		#include <morphtarget_vertex>
		#include <skinning_vertex>
		#include <displacementmap_vertex>
		#include <project_vertex>
		#include <logdepthbuf_vertex>
		#include <clipping_planes_vertex>
		#include <worldpos_vertex>
		#include <envmap_vertex>
		#include <shadowmap_vertex>
		#include <fog_vertex>
		vViewPosition = - mvPosition.xyz;
	}`;
}
