/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoomCategory = 'Undefined' | 'Kitchen' | 'LivingRoom' | 'Bedroom' | 'Bathroom' | 'Hallway' | 'Garage' | 'DiningRoom';

export const RoomCategory = {
    Undefined: 'Undefined' as RoomCategory,
    Kitchen: 'Kitchen' as RoomCategory,
    LivingRoom: 'LivingRoom' as RoomCategory,
    Bedroom: 'Bedroom' as RoomCategory,
    Bathroom: 'Bathroom' as RoomCategory,
    Hallway: 'Hallway' as RoomCategory,
    Garage: 'Garage' as RoomCategory,
    DiningRoom: 'DiningRoom' as RoomCategory
};

