import { Component, Input } from '@angular/core';
import { PageSliderService } from '@shared/services/page-slider.service';

@Component({
  selector: 'app-page-slider',
  templateUrl: './page-slider.component.html',
  styleUrls: ['./page-slider.component.scss'],
})
export class PageSliderComponent {
  expanded: boolean = false;
  @Input() menuExpanded: boolean = true;

  constructor(private pageSliderService: PageSliderService) {}

  toggle(): void {
    this.pageSliderService.toggle();
    this.expanded = !this.expanded;
  }
}
