/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SectionItemType } from './sectionItemType';
import { QuestionType } from './questionType';
import { ItemStatusTypeDto } from './itemStatusTypeDto';
import { PermissionTypeDto } from './permissionTypeDto';


export interface QuestionDto { 
    id: string;
    questionType: QuestionType;
    label: string;
    properties: any | null;
    value: any | null;
    isRequired: boolean;
    status: ItemStatusTypeDto;
    creationTimestampUtc: Date;
    lastModifiedTimestampUtc: Date;
    permissionType?: PermissionTypeDto;
    type: SectionItemType;
    workspaceId: string;
    parentId?: string | null;
    copiedFrom?: string | null;
}
export namespace QuestionDto {
}


