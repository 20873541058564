<map-2d [currentPose]="currentPose$"
        [map3dLayer]="map3dLayer"
        [ownedBuildings$]="buildingOverviewService.ownedBuildings$"
        [selectedBuilding$]="selectedBuilding$"
        (newLatLngZoom)="changeLatLngZoom($event)"
        (dossierAtLatLngRequested)="addLatLngToOwnedBuildings($event)"
        (selectBuildingRequested)="selectPand({realEstateId: $event})"
></map-2d>
<map-3d-layer #map3dLayer (buildingClicked)="selectPand($event)" [selectedBuilding$]="selectedBuilding$"
              [ownedBuildings$]="buildingOverviewService.ownedBuildings$"></map-3d-layer>

<app-sidebar [sidebarActive]="selectedBuilding.value !== undefined">
  <app-building-info [newSelectedBuilding]="selectedBuilding$" (requestCloseSidebar)="selectPand(undefined)"
                     (requestAddSelectedBuildingToOwnedBuildings)="addSelectedBuildingToOwnedBuildings()"></app-building-info>
</app-sidebar>
<app-search-bar (addressSelected$)="selectAndGoToPand($event.id)"
                [class.sidebar-active]="selectedBuilding.value !== undefined"></app-search-bar>
