<div class="products-add-popup" [class.active]="active">
  <div class="products-add-popup__header">
    <h5>{{ 'add-products' | translate }}</h5>

    <button (click)="closePopup()" (keydown)="closePopup()" class="material-symbols-rounded">
      close
    </button>
  </div>
  <div class="products-add-popup__content">
    <form class="search-input" (submit)="doSearch()">
      <input
        #input
        type="text"
        placeholder="{{ 'search' | translate }}"
        id="search"
        [(ngModel)]="search"
        [ngModelOptions]="{ standalone: true }" />
      <button
        type="submit"
        class="send-button"
        [disabled]="isBusy"
        [class.visible]="input.value.length > 0">
        <span class="material-symbols-rounded">search</span>
      </button>
    </form>

    <div
      class="products-add-popup__content__results__header"
      *ngIf="productsResult !== undefined && productsResult.products.length">
      <span class="description">{{ 'article-name' | translate }}</span>
      <span class="id">{{ 'gtin' | translate }}</span>
    </div>
    <ul
      class="products-add-popup__content__results"
      *ngIf="productsResult !== undefined && productsResult.products.length">
      <li
        *ngFor="let product of productsResult.products"
        (click)="toggleProduct(product)"
        (keydown)="keyDown()"
        tabindex="0"
        [class.added]="productAlreadyAdded(product)">
        <div class="checkbox"></div>

        <img [imgFallback]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/klEQVR4nO2ZyWsUQRSHPzGIC2rUg1k0auIexIMQ0YMiHgRxQfCgh7ggCJ686CT+AQqCN0EPHjwIXrwpYlQ04h71EAUxiqBxwQXEBZSIxpYHr6Boqme6p3t6UiE/KGamqt6b+ujqqveqYER+6Q4QVKDcBqbnCRJUsPQBDb6DPNbPZ0C9zyBTgIf6/QXQ6CuIqBZ4oL9fAbPxFMTA9Gjda2COryCiycA9re8Hmn0FMTB3LZgWX0FEE4BubX8DzPUVxMBc1z4fgMW+gojGA9e038esYKoBYmCuWjCteAoiGgdc0f6fgCWkUKkBDQAHNcyQUgB+ZwRiYLqs1axslRqQQITVUeUn6VQpx3UOmzofQeodNo0+ghQcNp0+ggwoTIOWjpgv+5ADCXIuZSsYQuVP+RjVH3xgla7hArJtOIB8013ee5ATaSCyAjmupVz7H2kDxixALgE1wGjgfELbR8B+YFpaiLQgT4BJlq+JWlfKrhtYmMXgswCRNLXJ4a9J24rZrnPYSf4+K2+QX0BbEZ9t2sdl+1anoVG7HnhL2+c8Qf4BW2P4lT6DDvvDVp+1jvbcQA7F9LsA6A3Z/gydZ52tFsjpmD4XadwUWCfynaErhtqI6VdxkBvAmJg+j6mNnF+tiOizN+J/KgryHJga01+NtWotj0iTDwDv8gb5AsxP4G+j2j216kYBm4ELoSmXG4hkgqsT+FoJ3HecvmxJMIXLVjGnu2LYz9CVrC+0OtmHFhetwLA3b5AjMWyXhfL398BRYJ7VR1aqv5r7S0wl78jLvEDO6bwupVPWirY+tGMbFSyfRi1FwpjMQHpiJjhyCP1dbVwBoASTe6zVaUOofakmUxUB6Y84WXSpXW3kFsrWGuCMvieBdasry3JYqxybYmqQJMlNq+YSgW5sRjssf4O6Ie7Uy50obQotyalBZI4Xk9yb77NuaA283A0amSj2ZMKr6N157ezh8lUHaz/BZo2My/EXZAFyK+YfyOO/DGwHxjr8zNTLmjQQN9OAjIic9R+SPYjgkpLwMQAAAABJRU5ErkJggg=='" [src]="product.cdn" [alt]="product.gtin" />
        <span class="description">
          {{ product.description }}
        </span>

        <span class="id">
          {{ product.gtin }}
        </span>
      </li>
    </ul>

    <div
      class="no-result"
      *ngIf="productsResult !== undefined && productsResult.products.length === 0">
      {{ 'no-result-product-search' | translate }}
    </div>

    <button
      (click)="incrementOffset()"
      class="next-page"
      [disabled]="isBusy"
      *ngIf="(productsResult?.total_count ?? 0) > 10">
      {{ 'next-page' | translate }}
    </button>

    <button
      (click)="addProducts()"
      class="button-primary"
      [disabled]="isBusy || activatedProducts.length === 0">
      ({{ activatedProducts.length }})
      {{ 'add' | translate }}
    </button>
  </div>
</div>
<div class="products-add-popup__background" [class.active]="active"></div>
