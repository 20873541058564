<div class="model-viewer">
  <div class="big-spinner" *ngIf="loading || isSaving"></div>
  <div class="model-viewer-no-model" *ngIf="!loading && bimId === undefined">
    {{ 'no-3d-model-available' | translate }}
  </div>

  <app-threed #threed (meshClicked)="meshClicked($event)"></app-threed>
  <div class="sidebar" *ngIf="modelLoaded">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

    <div class="sidebar__content" [class.hide]="visibleMenu !== 'overview'" *ngIf="buildingModel">
      <app-residence-sidebar
        (toggleCategoryVisibility)="toggleCategoryVisibility($event)"
        [categories]="buildingModel.categories"
        (toggleLevelVisibility)="toggleLevelVisibility($event)"
        [levels]="buildingModel.levels" />
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'details'">
      <app-element-popup></app-element-popup>
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'changes'">
      <app-changes-summary></app-changes-summary>
    </div>
  </div>

  <button
    class="button-primary cta-button"
    *ngIf="visibleMenu !== 'changes'"
    [disabled]="shoppingCartCount === 0"
    [class.animate]="bounceShoppingCartButton"
    (click)="showShoppingCart()">
    ({{ shoppingCartCount }}) {{ 'changes' | translate }}
  </button>
  <button
    class="button-primary cta-button"
    *ngIf="visibleMenu === 'changes'"
    [disabled]="shoppingCartCount === 0 || isSaving"
    (click)="saveShoppingCart()">
    <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
    {{ 'save' | translate }}
  </button>
</div>
