<div
  class="section"
  cdkDrag
  [cdkDragDisabled]="!edit"
  [class.deactive]="!expanded"
  [class.edit]="edit"
  [contextMenu]="menu">
  <button (click)="sectionClick($event, false)" class="section__top">
    <span class="section__top__drag" cdkDragHandle>
      <span></span>
      <span></span>
    </span>

    <span class="section__top__title" *ngIf="!edit">{{ section?.name }}</span>
    <input
      type="text"
      class="section__top__input"
      [(ngModel)]="section!.name"
      *ngIf="edit"
      placeholder="{{ 'section-name' | translate }}" />

    <button class="section__top__arrow" (click)="sectionClick($event, true)">
      <span class="material-symbols-rounded">expand_more</span>
    </button>

    <app-three-dot [menu]="menu"></app-three-dot>
  </button>

  <div [@slideInOut]="animationState" class="section__content">
    <div cdkDropList class="item-list" (cdkDropListDropped)="itemDropped($event)">
      <ng-container *ngIf="this.section">
        <app-inspection-section-item
          *ngFor="let item of this.questions; let i = index"
          cdkDrag
          [cdkDragData]="this.section"
          [cdkDragDisabled]="!edit"
          class="section__content__item"
          [edit]="edit"
          [disabled]="disasbled"
          [inspection]="inspection"
          [item]="item"
          (deleteChild)="deleteChild($event)"
          (move)="moveItem($event, i)">
          <div class="section__content__drag" cdkDragHandle>
            <span></span>
            <span></span>
          </div>
        </app-inspection-section-item>
      </ng-container>
    </div>
  </div>
</div>
