import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Product, ProductsResult, ProductsService } from '@api-clients/product';

@Component({
  selector: 'app-products-add-popup',
  templateUrl: './products-add-popup.component.html',
  styleUrl: './products-add-popup.component.scss',
})
export class ProductsAddPopupComponent {
  @ViewChild('input') input!: ElementRef;

  @Input() active: boolean = false;
  @Input() currentProducts?: readonly Product[];

  @Output() productsPopupChange = new EventEmitter();
  @Output() requestClose = new EventEmitter();

  protected activatedProducts: Product[] = [];

  constructor(private readonly productService: ProductsService) {}

  protected search: string = '';
  protected productsResult?: ProductsResult = undefined;
  protected previousOffset: number = 0;
  protected isBusy: boolean = false;

  doSearch(): void {
    this.previousOffset = 0;
    this.doRequest();
  }

  doRequest(): void {
    this.isBusy = true;
    this.productService.productsGet(this.search, this.previousOffset).subscribe((next) => {
      this.productsResult = next;
      this.isBusy = false;
    });
  }

  incrementOffset(): void {
    this.previousOffset += this.productsResult?.products.length ?? 0;
    this.doRequest();
  }

  toggleProduct(product: Product): void {
    if (!this.productAlreadyAdded(product)) {
      this.activatedProducts.push(product);
    } else {
      this.activatedProducts = this.activatedProducts.filter((item) => item !== product);
    }
  }

  closePopup(): void {
    this.requestClose.emit();

    //wait for hide then empty everything
    setTimeout(() => {
      this.productsResult = undefined;
      this.search = '';
      this.activatedProducts = [];
    }, 250);
  }

  addProducts(): void {
    this.closePopup();
    this.productsPopupChange.emit(this.activatedProducts);
  }

  productAlreadyAdded(product: Product): boolean {
    const sameProduct = (p: Product): boolean =>
      p.manufacturer_gln === product.manufacturer_gln && p.product_code == product.product_code;
    return this.currentProducts?.some(sameProduct) || this.activatedProducts.some(sameProduct);
  }

  keyDown(): void {}
}
