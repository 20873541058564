<div class="information-item">
  <button
    (click)="openContent()"
    *ngIf="title"
    [class.link]="collaps"
    class="information-item__header">
    <h2>{{ title }}</h2>
    <span *ngIf="collaps" [class.rotate]="open" class="material-symbols-rounded">expand_more</span>
  </button>
  <div [@slideInOut]="animationState" class="information-item__content">
    <ng-content></ng-content>
  </div>
</div>
