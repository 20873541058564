import {
  ItemStatusTypeDto,
  RequestContentItemDto,
  RequestContentItemMessageDto,
  SectionItemType,
} from '@api-clients/workspace';
import { ContentItem } from './content.item.model';
import { SectionItem, SectionItemBase } from './section-item.model';
import { WorkspaceService } from '../services/workspace.service';

export interface UpdateSectionItemRequest {
  isRequired?: boolean;
  name?: string;
  description?: string;
  contentItemId?: string;
  title?: string;
  status?: ItemStatusTypeDto;
  allowedFileTypes?: string[];
  workspaceId?: string;
  parentId?: string;
}

export class RequestContentItem extends SectionItemBase implements RequestContentItemDto {
  contentItems!: ContentItem[];
  allowedFileTypes!: string[];
  lastModifiedTimestampUtc!: Date;
  isRequired!: boolean;
  name!: string;
  messages!: RequestContentItemMessageDto[];
  creationTimestampUtc!: Date;

  get isVisible(): boolean {
    return !!this.name.length;
  }

  constructor(
    fileRequest: RequestContentItemDto,
    private workspaceService: WorkspaceService
  ) {
    super();
    const { contentItems, status, ...rest } = fileRequest;
    Object.assign(this, rest);
    this.contentItems = (contentItems || [])
      .map((ci) => new ContentItem(ci))
      .filter((ci) => ci.isUploadComplete);
    this.status =
      (status === ItemStatusTypeDto.Pending || status === ItemStatusTypeDto.Unknown) &&
      this.contentItems.length
        ? ItemStatusTypeDto.Received
        : status;
  }

  getContentItem(id: string): ContentItem | undefined {
    return this.contentItems.find((ci) => ci.id === id);
  }

  async insertContentItem(file: File): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const returned = await this.workspaceService.addFileToFileRequest(
      this.workspaceId,
      this.parentId!,
      this.id,
      file
    );
    // Uploading us currently broken. This can probably fixed with Wouters help.
    // this.contentItems.push(returned);
  }

  insertSectionItem(sectionItem: SectionItem, index = -1): void {
    if (sectionItem.type !== SectionItemType.ContentItem)
      throw new Error('Only content items can be inserted into a file request');
    const contentItem = sectionItem as ContentItem;
    if (index !== -1) {
      this.contentItems.splice(index, 0, contentItem);
    } else {
      this.contentItems.push(contentItem);
    }
    this.status = ItemStatusTypeDto.Received;
  }

  removeContentItem(contentItemId: string): void {
    const index = this.contentItems.findIndex((ci) => ci.id === contentItemId);
    this.contentItems.splice(index, 1);
    this.status = this.contentItems.length === 0 ? ItemStatusTypeDto.Pending : this.status;
  }

  getFilename(item: SectionItem, useFileRequestNames: boolean): string | undefined {
    if (useFileRequestNames) {
      if (this.contentItems.length === 1) {
        const fileName = this.contentItems[0].name;
        const [, , extension] = /(.*)\.([^.]*)/.exec(fileName)!;
        return `${this.name}.${extension}`;
      } else {
        return `${this.name}/${item.name}`;
      }
    } else {
      return item.name;
    }
  }

  isFileAllowed(file: File): boolean {
    const extension = '.' + file.name.split('.').pop();
    const allowedTypes = (this.allowedFileTypes || []).filter((d) => d.startsWith('.'));
    return !allowedTypes.length || allowedTypes.includes(extension);
  }

  get hasContentItems(): boolean {
    return this.contentItems.length > 0;
  }

  get isCompleted(): boolean {
    return !!this.getFilename(this, true);
  }
}

export interface FileRequestUpdateRequest {
  contentItemId: string;
  description: string;
  title: string;
  requestContentItemId: string;
  isRequired: boolean;
}
