<div class="map-wrap">
  <div #map class="map"></div>
  <div class="layers">
    <mat-checkbox [checked]="showSateliteLayer" (change)="changeSatelliteLayer($event)">{{ 'satellite' | translate }}
    </mat-checkbox>
    <br/>
    <mat-checkbox [checked]="showCadastralLayer"
                  (change)="changeCadastralLayer($event)">{{ 'cadastral_borders' | translate }}
    </mat-checkbox>
    <br/>
    <mat-checkbox [checked]="showAHN4Layer" (change)="changeAHN4Layer($event)">{{ 'AHN4' | translate }}</mat-checkbox>
    <br/>
    <mat-checkbox [checked]="showBag3DLayer" (change)="change3DLayer($event)">{{ '3d_buildings' | translate }}
    </mat-checkbox>
  </div>
</div>
