/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ItemStatusTypeDto = 'Unknown' | 'Pending' | 'Received' | 'Approved' | 'Rejected' | 'Archived' | 'NotApplicable';

export const ItemStatusTypeDto = {
    Unknown: 'Unknown' as ItemStatusTypeDto,
    Pending: 'Pending' as ItemStatusTypeDto,
    Received: 'Received' as ItemStatusTypeDto,
    Approved: 'Approved' as ItemStatusTypeDto,
    Rejected: 'Rejected' as ItemStatusTypeDto,
    Archived: 'Archived' as ItemStatusTypeDto,
    NotApplicable: 'NotApplicable' as ItemStatusTypeDto
};

