import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { BuildingMenuService } from '@shared/services/building-menu.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  buildingId?: string;

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly buildingMenuService: BuildingMenuService
  ) {
    this.translateService.use('nl');

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showMenu();
      }
    });

    this.showMenu();
  }

  showMenu(): void {
    this.buildingId = this.router.url.includes('buildings')
      ? this.router.url.split('/')[2]
      : undefined;
    if (this.buildingId) {
      this.buildingMenuService.show();
    } else {
      this.buildingMenuService.hide();
    }
  }
}
