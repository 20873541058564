/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Bij het controleren of een property in onderzoek is kan het zijn  dat er meerdere indicaties voor een property opgenomen zijn.  In dat geval zijn er meerdere indicaties waarvan de naam begint  met de property-naam. 
 */
export interface AdresUitgebreidInOnderzoek { 
    openbareRuimteNaam?: boolean;
    korteNaam?: boolean;
    huisnummer?: boolean;
    huisletter?: boolean;
    huisnummertoevoeging?: boolean;
    postcode?: boolean;
    woonplaatsNaam?: boolean;
    /**
     * \"Als de nummeraanduidingLigtIn false is (deze is niet in onderzoek) en de openbareRuimteLigtIn is true (deze is wel in onderzoek) maar is gerelateerd aan een andere woonplaats dan de nummeraanduiding,  dan wordt de boolean hier niet gezet. Deze is dan niet relevant omdat dan de woonplaats wordt gebruikt die gerelateerd is aan de nummeraanduiding.\" 
     */
    openbareRuimteLigtIn?: boolean;
    openbareRuimteStatus?: boolean;
    nummeraanduidingLigtIn?: boolean;
    nummeraanduidingligtAan?: boolean;
    nummeraanduidingStatus?: boolean;
    toelichting?: Array<string>;
    adresregel5?: boolean;
    adresregel6?: boolean;
    typeAdresseerbaarObject?: boolean;
    /**
     * Een indicatie dat de geometrie van het aan een adres gerelateerde  adresseerbare object in onderzoek is. 
     */
    adresseerbaarObjectGeometrie?: boolean;
    /**
     * Een indicatie dat de status van het aan een adres gerelateerde  adresseerbare object in onderzoek is. 
     */
    adresseerbaarObjectStatus?: boolean;
    adresseerbaarObjectheeftAlsHoofdadres?: boolean;
    adresseerbaarObjectheeftAlsNevenadres?: boolean;
    gebruiksdoelen?: boolean;
    oppervlakte?: boolean;
    maaktDeelUitVan?: boolean;
    oorspronkelijkBouwjaar?: boolean;
}

