import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { Subject, lastValueFrom } from 'rxjs';
import { PermissionService } from './permission-service';

@Injectable()
export class RptInterceptor {
  rpt: string | null = null;
  rpt$ = new Subject<string>();
  authority?: string;

  constructor(
    private http: HttpClient,
    private readonly permissionService: PermissionService
  ) {}

  async setRpt(token: string, config: OpenIdConfiguration): Promise<void> {
    this.authority = config.authority;
    const body = new HttpParams()
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
      .set('audience', 'twintopics-portal');
    const result: object = await lastValueFrom(
      this.http.post(`${config.authority}/protocol/openid-connect/token`, body.toString(), {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Bearer ${token}`),
      })
    ).catch((err) => {
      throw new Error(err);
    });
    if (result['access_token']) {
      this.rpt = result['access_token'];
      if (this.rpt !== null) {
        this.permissionService.setToken(this.rpt);
      }
      this.rpt$.next(result['access_token']);
    }
  }
}
