/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ObjectCategory } from './objectCategory';


/**
 * Graph DTOs don\'t contain the properties, but only the ids of the related objects to construct their relationships in frontend.
 */
export interface BimObjectGraphDto { 
    id: string;
    category: ObjectCategory;
    levels: Array<string>;
    rooms: Array<string>;
}
export namespace BimObjectGraphDto {
}


