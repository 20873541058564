import { BimElement, ObjectCategory, RoomCategory } from '@api-clients/bim';
import { Product } from '@api-clients/product';
import { Property } from '../../views/model-viewer/property';
import { symmetricDifference } from 'ramda';
import { generateUUID } from 'three/src/math/MathUtils';

export interface UnsavedElement {
  element: BimElement;
  id: string;
  propertyDefinition?: Map<string, Property>;

  getProducts(): Product[];

  changeCategory(category: RoomCategory | ObjectCategory): void;

  changeProperty(property: string, value: string | number | boolean | Product[] | undefined): void;

  addProducts(products: Product[]): void;

  removeProduct(product: Product): void;

  deepCopy(): UnsavedElement;

  getChangeCount(): number;
}

export class NewElement implements UnsavedElement {
  id: string;
  element: BimElement;
  propertyDefinition?: Map<string, Property>;

  constructor(element: BimElement, propertyDefinition?: Map<string, Property>) {
    this.id = generateUUID();
    this.element = element;
    this.propertyDefinition = propertyDefinition;
  }

  getProducts(): Product[] {
    return this.element.properties['products'] || [];
  }

  changeCategory(category: RoomCategory | ObjectCategory): void {
    this.element['category'] = category;
  }

  changeProperty(property: string, value: string | number | boolean | Product[] | undefined): void {
    this.element.properties[property] = value;
  }

  addProducts(products: Product[]): void {
    this.element.properties['products'] = [...this.getProducts(), ...products];
  }

  removeProduct(product: Product): void {
    this.element.properties['products'] = this.getProducts().filter(
      (p) => !equalToProduct(p)(product)
    );
  }

  getChangeCount(): number {
    return 1;
  }

  deepCopy(): NewElement {
    const element = new NewElement(structuredClone(this.element));
    element.id = structuredClone(this.id);
    return element;
  }
}

export class ChangedElement implements UnsavedElement {
  element: BimElement;
  id: string;
  originalValues: Map<string, string | number | boolean | Product[] | undefined>;
  originalCategory?: RoomCategory | ObjectCategory;
  // Defines the properties that this element can have
  propertyDefinition?: Map<string, Property>;

  constructor(
    id: string,
    element: BimElement,
    originalValues: Map<string, string | number | boolean | Product[] | undefined>,
    originalCategory?: RoomCategory | ObjectCategory,
    propertyDefinition?: Map<string, Property>
  ) {
    this.id = id;
    this.element = element;
    this.originalValues = originalValues;
    this.originalCategory = originalCategory;
    this.propertyDefinition = propertyDefinition;
  }

  changeCategory(category: RoomCategory | ObjectCategory): void {
    if (this.originalCategory == category) {
      this.originalCategory = undefined;
    } else if (this.originalCategory === undefined) {
      this.originalCategory = this.element['category'];
    }
    // Todo: make this typed for Room/Object
    this.element['category'] = category;
  }

  removeCategoryChange(): void {
    this.element['category'] = this.originalCategory;
    this.originalCategory = undefined;
  }

  changeProperty(property: string, value: string | number | boolean | Product[] | undefined): void {
    if (this.originalValues.get(property) === value) {
      // If we're back to the original configuration, remove the original values
      this.originalValues.delete(property);
    } else if (this.originalValues.get(property) === undefined) {
      // If we don't have an original value, set it
      this.originalValues.set(property, this.element.properties[property]);
    }
    this.element.properties[property] = value;
  }

  addProducts(products: Product[]): void {
    // Potentially init original Products
    if (this.originalValues.get('products') === undefined) {
      this.originalValues.set('products', this.getProducts());
    }

    this.element.properties['products'] = [...this.getProducts(), ...products];

    // If we're back to the original configuration, remove the original values
    this.removeProductChangesIfBackToOriginal();
  }

  removeProduct(product: Product): void {
    // Potentially init original Products
    if (this.originalValues.get('products') === undefined) {
      this.originalValues.set('products', this.getProducts());
    }

    this.element.properties['products'] = this.getProducts().filter(
      (p) => !equalToProduct(p)(product)
    );

    // If we're back to the original configuration, remove the original values
    this.removeProductChangesIfBackToOriginal();
  }

  removePropertyChange(property: string): void {
    this.element.properties[property] = this.originalValues.get(property);
    this.originalValues.delete(property);
  }

  // Todo: make this typed for Room/Object
  getProducts(): Product[] {
    return this.element.properties['products'] || [];
  }

  getChangeCount(): number {
    const originalProducts = this.originalValues.get('products') as Product[] | undefined;
    const productDifference =
      originalProducts !== undefined
        ? symmetricDifference(this.getProducts(), originalProducts).length - 1 // -1 to avoid counting twice in originalValues array
        : 0;

    return (
      this.originalValues.size + // Changed properties
      (this.originalCategory === undefined ? 0 : 1) + // Changed category
      productDifference // Changed products
    );
  }

  deepCopy(): ChangedElement {
    return new ChangedElement(
      structuredClone(this.id),
      structuredClone(this.element),
      structuredClone(this.originalValues),
      structuredClone(this.originalCategory),
      structuredClone(this.propertyDefinition)
    );
  }

  removeProductChangesIfBackToOriginal(): void {
    if (
      symmetricDifference(
        (this.originalValues.get('products') as Product[]) || [],
        this.getProducts()
      ).length === 0
    ) {
      this.originalValues.delete('products');
    }
  }
}

function equalToProduct(a: Product): (b: Product) => boolean {
  return (b: Product) =>
    a.manufacturer_gln === b.manufacturer_gln && a.product_code === b.product_code;
}
