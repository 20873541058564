/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdresseerbaarObject } from './adresseerbaarObject';
import { VoorkomenLV } from './voorkomenLV';
import { StatusPlaats } from './statusPlaats';
import { Indicatie } from './indicatie';
import { TypeAdresseerbaarObject } from './typeAdresseerbaarObject';
import { Surface } from './surface';


/**
 * Een Standplaats is een door het bevoegde gemeentelijke orgaan als zodanig aangewezen terrein of gedeelte daarvan dat bestemd is voor het permanent plaatsen van een niet direct en niet duurzaam met de aarde verbonden en voor woon-, bedrijfsmatige, of recreatieve doeleinden geschikte ruimte.  **heeftAlsHoofdAdres**: Een adresseerbaar object heeft als hoofadres een  nummeraanduiding.  Deze property bevat de identificatie van de nummeraanduiding die het  hoofdadres is van het adresseerbare object.  **status**: De fase van de levenscyclus van een standplaats, waarin  de betreffende standplaats zich bevindt.  **geometrie**: De tweedimensionale geometrische representatie van de  omtrekken van een standplaats.  **geconstateerd**: Een aanduiding waarmee kan worden aangegeven dat een  object in de registratie is opgenomen als gevolg van een feitelijke  constatering, zonder dat er op het moment van opname sprake was van een  regulier brondocument voor deze opname.  **voorkomen**: De toestand van een object in de administratieve  werkelijkheid, zoals beschreven met de eigenschappen van dit object  waarover gegevens geregistreerd worden, waarbij de gegevens gedurende een  bepaalde periode gelijk zijn in de (basis) registratie. 
 */
export interface Standplaats extends AdresseerbaarObject { 
    /**
     * De unieke aanduiding van een standplaats.
     */
    identificatie: string;
    /**
     * NL.IMBAG.Standplaats
     */
    domein: string;
    status: StatusPlaats;
    geometrie: Surface;
    geconstateerd: Indicatie;
    /**
     * De datum waarop het brondocument is vastgesteld, op basis waarvan een opname,  mutatie of een verwijdering van gegevens ten aanzien van een object heeft  plaatsgevonden. 
     */
    documentdatum: string;
    /**
     * De unieke aanduiding van het brondocument op basis waarvan een opname,  mutatie of een verwijdering van gegevens ten aanzien van een woonplaats  heeft plaatsgevonden, binnen een gemeente.  Alle karakters uit de MES-1 karakterset zijn toegestaan. 
     */
    documentnummer: string;
    voorkomen: VoorkomenLV;
}
export namespace Standplaats {
}


