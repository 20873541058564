/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionTypeDto } from './permissionTypeDto';


export interface CreateItemShareRequest { 
    readonly id: string;
    workspaceId?: string | null;
    userId?: string | null;
    email?: string | null;
    membershipId?: string | null;
    organizationId?: string | null;
    contactId?: string | null;
    sectionItemId?: string | null;
    personalMessage?: string | null;
    readonly isPartlyShare: boolean;
    permissionType: PermissionTypeDto;
    relationId?: string | null;
    creatorId: string;
    name?: string | null;
    isDefaultShare: boolean;
    isLink: boolean;
    resendExistingShare?: boolean | null;
    readonly canEdit: boolean;
}
export namespace CreateItemShareRequest {
}


