import { Injectable } from '@angular/core';
import { ToastrService as NgxToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(
    private readonly toastrService: NgxToastrService,
    private readonly translateService: TranslateService
  ) {}

  showSuccess(message: string, title: string): void {
    this.toastrService.success(
      this.translateService.instant(message),
      this.translateService.instant(title)
    );
  }

  showFailure(message: string, title: string): void {
    this.toastrService.error(
      this.translateService.instant(message),
      this.translateService.instant(title)
    );
  }
}
