import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Camera, Vector3 } from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';

@Component({
  selector: 'app-point-lock-controls',
  templateUrl: './point-lock-controls.component.html',
  styleUrls: ['./point-lock-controls.component.scss'],
})
export class PointLockControlsComponent implements OnInit {
  private isMovingForward = false;
  private isMovingBackward = false;
  private isMovingLeft = false;
  private isMovingRight = false;
  private isMovingUp = false;
  private isMovingDown = false;

  private prevTime = performance.now();
  private velocity = new Vector3();
  private direction = new Vector3();

  @Input() camera!: Camera;
  @Input() canvas!: HTMLCanvasElement;
  @ViewChild('instructions') instructionsRef!: ElementRef<HTMLElement>;

  lockControls!: PointerLockControls;

  constructor() {}

  get isLocked(): boolean {
    return this.lockControls.isLocked;
  }

  ngOnInit(): void {
    this.lockControls = new PointerLockControls(this.camera, this.canvas);
    this.lockControls.pointerSpeed = 0.5;
    this.canvas.addEventListener('keydown', this.onKeyDown.bind(this));
    this.canvas.addEventListener('keyup', this.onKeyUp.bind(this));
  }

  onKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case 'ArrowUp':
      case 'KeyW':
        this.isMovingForward = true;
        break;

      case 'ArrowLeft':
      case 'KeyA':
        this.isMovingLeft = true;
        break;

      case 'ArrowDown':
      case 'KeyS':
        this.isMovingBackward = true;
        break;

      case 'ArrowRight':
      case 'KeyD':
        this.isMovingRight = true;
        break;

      case 'KeyQ':
        this.isMovingDown = true;
        break;

      case 'KeyE':
        this.isMovingUp = true;
        break;
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    switch (event.code) {
      case 'Backquote':
        if (event.altKey) return;
        this.lockControls.isLocked = !this.lockControls.isLocked;
        this.lockControls.isLocked ? this.lock() : this.unlock();
        break;

      case 'ArrowUp':
      case 'KeyW':
        this.isMovingForward = false;

        break;

      case 'ArrowLeft':
      case 'KeyA':
        this.isMovingLeft = false;
        break;

      case 'ArrowDown':
      case 'KeyS':
        this.isMovingBackward = false;
        break;

      case 'ArrowRight':
      case 'KeyD':
        this.isMovingRight = false;
        break;

      case 'KeyQ':
        this.isMovingDown = false;
        break;

      case 'KeyE':
        this.isMovingUp = false;
        break;
    }
  }

  lock(): void {
    this.lockControls.lock();
    this.canvas.focus();
  }

  unlock(): void {
    this.lockControls.unlock();
  }

  update(): void {
    const time = performance.now();
    if (this.lockControls.isLocked) {
      const delta = (time - this.prevTime) / 2000;

      this.velocity.x -= this.velocity.x * 10.0 * delta;
      this.velocity.z -= this.velocity.z * 10.0 * delta;
      this.velocity.y -= this.velocity.y * 5.0 * delta;

      this.direction.z = Number(this.isMovingForward) - Number(this.isMovingBackward);
      this.direction.x = Number(this.isMovingRight) - Number(this.isMovingLeft);
      this.direction.y = Number(this.isMovingDown) - Number(this.isMovingUp);
      this.direction.normalize(); // this ensures consistent this.isMovingments in all directions

      if (this.isMovingForward || this.isMovingBackward)
        this.velocity.z -= this.direction.z * 400.0 * delta;
      if (this.isMovingLeft || this.isMovingRight)
        this.velocity.x -= this.direction.x * 400.0 * delta;
      if (this.isMovingUp || this.isMovingDown) {
        this.velocity.y -= this.direction.y * 400.0 * delta;
        this.camera.position.y += this.velocity.y * delta; // new position
      }

      this.lockControls.moveRight(-this.velocity.x * delta);
      this.lockControls.moveForward(-this.velocity.z * delta);
    }
    this.prevTime = time;
  }
}
