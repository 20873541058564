<div class="top-bar">
  <app-lightswitch
    [label]="'edit' | translate"
    (changedState)="changeSwitch($event)"></app-lightswitch>

  <div class="top-bar__right">
    <div class="select">
      <select>
        <option value="Option 1">Option 1</option>
        <option value="Option 2">Option 2</option>
        <option value="Option 3">Option 3</option>
        <option value="Option 4">Option 4</option>
        <option value="Option 5">Option 5</option>
        <option value="Option length">Option that has too long of a value to fit</option>
      </select>
    </div>

    <div class="search">
      <input type="search" placeholder="Search" (input)="onSearchChange($event)" />
      <span class="material-symbols-rounded search__icon">search</span>
    </div>

    <button class="expand" (click)="toggle()" [class.expanded]="expanded">
      <span class="material-symbols-rounded">expand_more</span>
    </button>
  </div>
</div>
