/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VoorkomenLV } from './voorkomenLV';
import { StatusNaamgeving } from './statusNaamgeving';
import { Indicatie } from './indicatie';
import { TypeAdresseerbaarObject } from './typeAdresseerbaarObject';


/**
 * Een Nummeraanduiding is een door het bevoegde gemeentelijke orgaan als  zodanig toegekende aanduiding van een verblijfsobject, een standplaats of  een ligplaats.  **status**: De fase van de levenscyclus van een nummeraanduiding, waarin de  betreffende nummeraanduiding zich bevindt.  **geconstateerd**: Een aanduiding waarmee kan worden aangegeven dat een  object in de registratie is opgenomen als gevolg van een feitelijke  constatering, zonder dat er op het moment van opname sprake was van een  regulier brondocument voor deze opname.  **voorkomen**: De toestand van een object in de administratieve  werkelijkheid, zoals beschreven met de eigenschappen van dit object waarover  gegevens geregistreerd worden, waarbij de gegevens gedurende een bepaalde  periode gelijk zijn in de (basis) registratie.  **ligtIn**: Een nummeraanduiding ligt in een woonplaats. Als een object in een andere woonplaats ligt dan de openbare ruimte waaraan  de nummeraanduiding ligt, dan wordt hier de identificatie van de woonplaats  ingevuld waar de nummeraanduiding in ligt. In alle andere gevallen wordt de  identificatie van de woonplaats ingevuld waar de openbare ruimte in ligt.  **ligtAan**: Een nummeraanduiding ligt aan een openbare ruimte. Deze property bevat de identificatie van de openbare ruimte waar de  nummeraanduiding aan ligt. 
 */
export interface Nummeraanduiding { 
    /**
     * De unieke aanduiding van een nummeraanduiding.
     */
    identificatie: string;
    /**
     * NL.IMBAG.Nummeraanduiding
     */
    domein: string;
    /**
     * Een door of namens het gemeentebestuur ten aanzien van een adresseerbaar object toegekende nummering.
     */
    huisnummer: number;
    /**
     * Een door of namens het gemeentebestuur ten aanzien van een adresseerbaar object toegekende toevoeging aan een huisnummer in de vorm van een alfanumeriek teken.
     */
    huisletter?: string;
    /**
     * Een door of namens het gemeentebestuur ten aanzien van een adresseerbaar object toegekende nadere toevoeging aan een huisnummer of een combinatie van huisnummer en huisletter.
     */
    huisnummertoevoeging?: string;
    /**
     * De door PostNL vastgestelde code behorende bij een bepaalde combinatie van een straatnaam en een huisnummer.
     */
    postcode?: string;
    typeAdresseerbaarObject: TypeAdresseerbaarObject;
    status: StatusNaamgeving;
    geconstateerd: Indicatie;
    /**
     * De datum waarop het brondocument is vastgesteld, op basis waarvan een opname, mutatie of een verwijdering van gegevens ten aanzien van een object heeft plaatsgevonden.
     */
    documentdatum: string;
    /**
     * De unieke aanduiding van het brondocument op basis waarvan een opname, mutatie of een verwijdering van gegevens ten aanzien van een woonplaats heeft plaatsgevonden binnen een gemeente. Alle karakters uit de MES-1 karakterset zijn toegestaan.
     */
    documentnummer: string;
    voorkomen: VoorkomenLV;
    /**
     * De unieke aanduiding van een woonplaats, zoals opgenomen in de landelijke  woonplaatsentabel. Een natuurlijk getal tussen 0001 en 9999.  
     */
    ligtIn?: string;
    /**
     * De unieke aanduiding van een openbare ruimte.
     */
    ligtAan: string;
}
export namespace Nummeraanduiding {
}


