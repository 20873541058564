import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import {
  InspectionFinding,
  InspectionTemplateQuestion,
  InspectionValueValueBoolean,
  InspectionValueValueNumber,
  InspectionValueValueText,
} from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-open-question',
  templateUrl: './open-question.component.html',
  styleUrl: './open-question.component.scss',
})
export class OpenQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion | InspectionFinding;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: () => !this.first,
        action: () => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: () => !this.last,
        action: () => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.delete.emit(),
      },
      {
        name: 'show-units',
        checked: () =>
          this.question.property.unit !== undefined && this.question.property.unit !== null,
        action: () => this.toggleUnits(),
      },
    ];
  }

  toggleUnits(): void {
    if (this.question.property.unit === undefined || this.question.property.unit === null) {
      this.question.property.unit = '';
    } else {
      this.question.property.unit = undefined;
    }
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  isFinding(): boolean {
    return 'value' in this.question;
  }

  get value(): string | boolean {
    const finding = this.question as InspectionFinding;
    if (finding.value === null || finding.value === undefined) {
      return '';
    }
    switch (this.question.property.property_type) {
      case 'Boolean':
        return (finding.value as InspectionValueValueBoolean).boolean;
      case 'Text':
        return (finding.value as InspectionValueValueText).text;
      case 'Number':
        return (finding.value as InspectionValueValueNumber).number.toString();
    }

    return '';
  }

  set value(value: string | boolean) {
    const finding = this.question as InspectionFinding;
    switch (this.question.property.property_type) {
      case 'Boolean':
        finding.value = { boolean: value === 'true', type: 'Boolean' };
        break;
      case 'Text':
        finding.value = { text: value.toString(), type: 'Text' };
        break;
      case 'Number':
        finding.value = { number: parseFloat(value.toString()), type: 'Number' };
        break;
    }
  }

  change(e: Event): void {
    // this is unfortunately necessary because ngModelChange does not work with boolean values
    if (this.question.property.property_type !== 'Boolean') return;
    this.value = e.target?.['checked'].toString();
  }
}
