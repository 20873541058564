/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ObjectCategory } from './objectCategory';


export interface BimElementBimObjectDto { 
    category: ObjectCategory;
    levels: Array<string>;
    rooms: Array<string>;
    properties: any | null;
    type: BimElementBimObjectDto.TypeEnum;
}
export namespace BimElementBimObjectDto {
    export type TypeEnum = 'Object' | 'Level' | 'Room';
    export const TypeEnum = {
        Object: 'Object' as TypeEnum,
        Level: 'Level' as TypeEnum,
        Room: 'Room' as TypeEnum
    };
}


