import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private rptDecoded: JSON = JSON.parse('{}');
  public tokenUpdated$ = new Subject<string>();

  private parseJwt(token: string): JSON {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public setToken(token: string): void {
    this.rptDecoded = this.parseJwt(token);
    this.tokenUpdated$.next(token);
  }

  /***
   ** Checks whether the current user / rpt token has a permission with a scope
   ***/
  public userHasPermissionWithScope(permission: string, scope: string): boolean {
    const scopeCheck = (element): boolean => element === scope;
    const permissionCheck = (element): boolean =>
      element.rsname === permission && element.scopes.some(scopeCheck);
    const authorization = this.rptDecoded['authorization'];
    if (authorization === undefined) return false;
    return authorization.permissions.some(permissionCheck);
  }
}
