<div class="add-note">
  <p>{{ 'Note' | translate }}</p>
  <div class="add-note__container">
    <textarea class="textarea" #noteInput></textarea>
    <input
      type="file"
      #imageInput
      accept="image/png, image/gif, image/jpeg"
      style="display: none"
      (change)="imageInputChanged()" />
    <button (click)="uploadImage()" class="button-primary button--has-icon">
      <span class="material-symbols-rounded">add_a_photo</span>
      <span *ngIf="image_ids.length">({{ image_ids.length }})</span>
    </button>
  </div>
  <div class="image-list">
    <div *ngFor="let image of filesPreview; let i = index" class="image-list__item">
      <img [src]="image" alt="note-image" />
      <button (click)="removeImage(i)" class="image-list__item__remove">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
  </div>

  <div class="big-input">
    <label for="location_id" class="title">{{ 'location' | translate }}</label>
    <select [(ngModel)]="location_id" id="location_id">
      <option [ngValue]="undefined">{{ 'no-location' | translate }}</option>
      <option [ngValue]="location.id" *ngFor="let location of locations">
        {{ location['name_' + translateService.currentLang] || location.name_en }}
      </option>
    </select>
  </div>

  <div class="add-note__buttons">
    <button (click)="close()" class="cancel-button">{{ 'cancel' | translate }}</button>
    <button (click)="submit()" class="button-primary">{{ 'place' | translate }}</button>
  </div>
</div>
