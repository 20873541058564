import { Object3D } from 'three';
import { CadastralId } from '@services/building-overview.service';

export function cadastralIdsToBatchIds(model: Object3D, castralIds: CadastralId[]): number[] {
  return model['batchTable'].header.attributes
    .map((attributesString, index) => [JSON.parse(attributesString), index])
    .filter(([attributesJson]) =>
      castralIds.includes(attributesJson.identificatie.split('.').pop())
    )
    .map(([, index]) => index);
}

export function batchIdsToCadastralIds(model: Object3D, batchIds: number[]): string[] {
  const attributes: [] = model['batchTable'].header.attributes;
  return batchIds.map((batchId) =>
    JSON.parse(attributes[batchId])['identificatie'].split('.').pop()
  );
}

export function tileContainsCadastralId(model: Object3D, cadastralId: string): boolean {
  const attributes: [] = model['batchTable'].header.attributes;
  return attributes.some(
    (attribute) => JSON.parse(attribute)['identificatie'].split('.').pop() === cadastralId
  );
}
