/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type QuestionType = 'Boolean' | 'MultipleChoice' | 'OpenQuestion' | 'Datetime' | 'Number' | 'Currency' | 'Text';

export const QuestionType = {
    Boolean: 'Boolean' as QuestionType,
    MultipleChoice: 'MultipleChoice' as QuestionType,
    OpenQuestion: 'OpenQuestion' as QuestionType,
    Datetime: 'Datetime' as QuestionType,
    Number: 'Number' as QuestionType,
    Currency: 'Currency' as QuestionType,
    Text: 'Text' as QuestionType
};

