import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildingMenuService {
  private buildingMenuStatus = new BehaviorSubject<{ active: boolean }>({
    active: false,
  });

  public buildingMenuStatus$ = this.buildingMenuStatus.asObservable();

  show(): void {
    this.buildingMenuStatus.next({ active: true });
  }

  hide(): void {
    this.buildingMenuStatus.next({ active: false });
  }

  toggleExpand(): void {
    this.buildingMenuStatus.next({
      active: this.buildingMenuStatus.value.active,
    });

    // if there is no localStorage item or is true, set it to false
    if (localStorage.getItem('buildingMenuExpand') !== 'false') {
      localStorage.setItem('buildingMenuExpand', 'false');
    } else {
      localStorage.setItem('buildingMenuExpand', 'true');
    }
  }

  status(): boolean {
    return this.buildingMenuStatus.value.active;
  }
}
