<svg
  #svg
  [attr.viewBox]="viewBox"
  width="100%"
  height="100%"
  xmlns="http://www.w3.org/2000/svg"
  (click)="featureClicked(undefined)"
  (mousedown)="clickDisabled = false">
  <defs>
    <marker
      id="arrow"
      viewBox="0 0 15 15"
      refX="14"
      refY="7.5"
      markerWidth="9"
      markerHeight="9"
      orient="auto-start-reverse">
      <path d="M 0 0 L 15 7.5 L 0 15 z" />
    </marker>
  </defs>

  <g (transitionend)="endRotate()" *ngIf="(floorPlan?.levels?.[currentLevel]) as level"
     [attr.style]="'transform:rotate(' + (isRotated ?'-90':'0') + 'deg); transition: transform 0.3s linear; transform-origin: '+ this.floorPlan.boundingBox.center.x +'px '+ (this.floorPlan.boundingBox.center.y - 100) + 'px;'">
    <g class="lower-stairs">
      <g class="stair" *ngFor="let stair of (floorPlan.levels[currentLevel - 1]?.stairs || [])"
         (click)="featureClicked(stair); $event.stopPropagation();">
        <polygon
          [attr.points]="stair.points"
          fill="#ffffff"
          stroke="#aaaaaa"
          stroke-width="10"
          fill-rule="nonzero"></polygon>
        <polygon
          [attr.points]="stair.points"
          fill="#a8c0c3"
          *ngIf="selectedElement?.bimId === stair.bimId"></polygon>
      </g>
    </g>

    <g class="floors">
      <g
        class="floor"
        *ngFor="let floor of level.floors">
        <polygon [attr.points]="floor.points" fill="#ffffff"></polygon>
      </g>
    </g>

    <g class="rooms">
      <g
        class="room"
        *ngFor="let room of level.rooms"
        (click)="featureClicked(room); $event.stopPropagation()">
        <polygon
          [attr.points]="room.points"
          fill="#e8ddac"
          fill-opacity="0.5"
          stroke-width="10"
          stroke="#000000"></polygon>
        <polygon
          [attr.points]="room.points"
          fill="#a8c0c3"
          fill-opacity="0.5"
          stroke-width="0"
          *ngIf="selectedElement === room"></polygon>
      </g>
      <g class="room-texts" *ngFor="let room of level.rooms">
        <text
          [style]="'transform:rotate(' + (isRotated ?'90':'0') + 'deg); transform-origin: center; transform-box: fill-box'"
          font-size="100pt"
          [attr.x]="room.bbox.x1 + (room.bbox.x2 - room.bbox.x1) / 2"
          [attr.y]="room.bbox.y1 + (room.bbox.y2 - room.bbox.y1) / 2"
          dominant-baseline="middle"
          text-anchor="middle">
          {{ room.category | translate }}
        </text>
      </g>
    </g>

    <g class="walls">
      <g
        class="wall"
        *ngFor="let wall of level.walls"
        (click)="featureClicked(wall); $event.stopPropagation()">
        <polygon [attr.points]="wall.points" fill="#000000"></polygon>
        <polygon
          [attr.points]="wall.points"
          fill="#68888c"
          *ngIf="selectedElement === wall"></polygon>
      </g>
    </g>

    <g class="stairs">
      <g
        class="stair"
        *ngFor="let stair of level.stairs"
        (click)="featureClicked(stair); $event.stopPropagation()">
        <polygon
          [attr.points]="stair.points"
          fill="#ffffff"
          stroke="#aaaaaa"
          stroke-width="10"
          fill-rule="nonzero"></polygon>
        <polygon
          [attr.points]="stair.points"
          fill="#a8c0c3"
          *ngIf="selectedElement?.bimId === stair.bimId"></polygon>
      </g>
    </g>

    <g class="doors">
      <g
        class="door"
        *ngFor="let door of level.doors"
        (click)="featureClicked(door); $event.stopPropagation()">
        <polygon [attr.points]="door.points" fill="#adaca6"></polygon>
        <polygon
          [attr.points]="door.points"
          fill="#a8c0c3"
          *ngIf="selectedElement === door"></polygon>
        <g [attr.style]="'transform-box: fill-box; transform-origin: center;'">
        </g>
      </g>
    </g>

    <g class="windows">
      <g
        class="window"
        *ngFor="let window of level.windows"
        (click)="featureClicked(window); $event.stopPropagation()">
        <polygon [attr.points]="window.points" fill="#84C2E2"></polygon>
        <polygon
          [attr.points]="window.points"
          fill="#68888c"
          *ngIf="selectedElement === window"></polygon>
        <rect
          fill="#000000"
          [attr.width]="window.width"
          [attr.height]="10"
          [attr.x]="window.bbox.center.x"
          [attr.y]="window.bbox.center.y"
          [attr.style]="
            'transform: translate(-50%, -50%) rotate(' +
            window.angle +
            'deg); transform-origin:center; transform-box:fill-box'
          "></rect>
      </g>
    </g>

    <g
      class="dimensions-width"
      [attr.style]="'transform: translateY(' + (floorPlan.boundingBox.y2 + 100) + 'px)'">
      <g *ngFor="let grp of level.dimensions.widths; let i = index">
        <g class="dimension" *ngFor="let d of grp">
          <line
            marker-end="url(#arrow)"
            marker-start="url(#arrow)"
            stroke-width="10"
            fill="none"
            stroke="black"
            [attr.x1]="d.p1"
            [attr.y1]="i * 200"
            [attr.x2]="d.p2"
            [attr.y2]="i * 200"></line>
          <text
            [attr.x]="d.p1 + d.length / 2"
            [attr.y]="i * 200 + 100"
            dominant-baseline="middle"
            text-anchor="middle"
            font-size="100pt">
            {{ d.length / 1000 | number: '0.2-2' }}m
          </text>
        </g>
      </g>
    </g>

    <g
      class="dimensions-height"
      [attr.style]="'transform: translateX(' + (isRotated ? (floorPlan.boundingBox.x1 - ((level.dimensions.heights.length) * 200) + 100) : (floorPlan.boundingBox.x2 + 100)) + 'px)'">
      <g *ngFor="let grp of isRotated ? level.dimensions.heights.reverse() : level.dimensions.heights; let i = index">
        <g class="dimension" *ngFor="let d of grp">
          <line
            marker-end="url(#arrow)"
            marker-start="url(#arrow)"
            stroke-width="10"
            fill="none"
            stroke="black"
            [attr.x1]="i * 200"
            [attr.y1]="d.p1"
            [attr.x2]="i * 200"
            [attr.y2]="d.p2"></line>
          <text
            [attr.x]="i * 200 + (isRotated ? -80 : 80)"
            [attr.y]="d.p1 + d.length / 2"
            dominant-baseline="middle"
            text-anchor="middle"
            font-size="100pt"
            [style]="'transform:rotate(' + (isRotated ?'90':'270') + 'deg); transform-origin: center; transform-box: fill-box'">
            {{ d.length / 1000 | number: '0.2-2' }}m
          </text>
        </g>
      </g>
    </g>
  </g>
</svg>
