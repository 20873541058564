/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BimStatus = 'Uploaded' | 'Processing' | 'Completed' | 'Error';

export const BimStatus = {
    Uploaded: 'Uploaded' as BimStatus,
    Processing: 'Processing' as BimStatus,
    Completed: 'Completed' as BimStatus,
    Error: 'Error' as BimStatus
};

