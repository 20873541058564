/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateSectionRequest { 
    items?: Array<string> | null;
    name?: string | null;
    description?: string | null;
    parentSection?: string | null;
    insertAt?: number | null;
    metadata?: any | null;
}

