import { QuestionDto, QuestionType } from '@api-clients/workspace';
import { SectionItem, SectionItemBase } from './section-item.model';
import { WorkspaceService } from '../services/workspace.service';

export interface UpdateQuestionRequest {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  isRequired?: boolean;
  parentId?: string;
}

export interface MultipleChoiceItem {
  name: string;
  checked: boolean;
  isOtherOption: boolean;
  workflowId?: string;
}

export class Question extends SectionItemBase implements QuestionDto {
  label!: string;
  questionType!: QuestionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties!: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value!: any | MultipleChoiceItem[];
  isRequired!: boolean;
  creationTimestampUtc!: Date;
  lastModifiedTimestampUtc!: Date;

  constructor(
    question: QuestionDto,
    private workspaceService: WorkspaceService
  ) {
    super();
    Object.assign(this, question);
  }

  get isVisible(): boolean {
    if (this.questionType === QuestionType.OpenQuestion) return this.value.length;
    else
      return (
        !!this.label.length &&
        (this.questionType !== QuestionType.MultipleChoice || this.value.length)
      );
  }

  override get name(): string {
    return this.label;
  }

  update(): void {
    const { label, questionType, properties, value, isRequired, status } = this;
    void this.workspaceService.updateQuestion(this.workspaceId, this.parentId!, this.id, {
      label,
      questionType,
      properties,
      value,
      isRequired,
      status,
    });
  }

  insertSectionItem(sectionItem: SectionItem, index: number): void {
    console.error('Cannot add a section item to a question. ', sectionItem, index);
  }

  get isCompleted(): boolean {
    // if (!this.isVisible) return false;
    if (
      this.questionType === QuestionType.MultipleChoice &&
      !this.value?.some((item) => item.checked)
    ) {
      return false;
    } else if (this.questionType === QuestionType.OpenQuestion && !this.value.length) {
      return false;
    }
    return true;
  }
}
