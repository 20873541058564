/**
 * s3wrapper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Dto representing an object retrieved from the google storage service
 */
export interface ContentItemDto { 
    /**
     * The BucketName the content item was uploaded to
     */
    bucketName?: string | null;
    /**
     * The unique key of the object within the storage bucket.
     */
    name?: string | null;
    /**
     * Gets or sets the Id of the content item.  The ID of the object consists of the bucket name, object name, and generation number
     */
    id?: string | null;
    /**
     * Gets or sets the creation time stamp for this content item.
     */
    creationTimestampUtc?: string;
    /**
     * Gets or sets the last modified time stamp for this content item.
     */
    lastModifiedTimestampUtc?: string;
    /**
     * Gets or sets the size of the s3wrapper.Dto.ContentItemDto in bytes.
     */
    size?: number;
    /**
     * Gets or sets the custom metadata for this content-item.
     */
    metadata?: { [key: string]: string; } | null;
    /**
     * Gets or sets the file hash.
     */
    md5Hash?: string | null;
    /**
     * Gets or sets the identifier of the user that uploaded the content-item.
     */
    owner?: string | null;
}

