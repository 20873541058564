import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Box3,
  Material,
  Mesh,
  MeshDistanceMaterial,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
  addLightsToScene,
  configDirectionalLight,
  createShadowCatcher,
  vertexAlphaDepthMaterial,
} from '../../../building-module/views/model-viewer/utils/lighting';
import { initRenderer } from '../../../building-module/views/model-viewer/utils/renderer';
import { createOrbitControls } from '../../../building-module/views/model-viewer/utils/controls';
import { PointLockControlsComponent } from '../../../building-module/views/model-viewer/point-lock-controls/point-lock-controls.component';
import { BuildingModel } from '@shared/services/assets/building-model';

@Component({
  selector: 'app-threed',
  templateUrl: './threed.component.html',
  styleUrl: './threed.component.scss',
})
export class ThreedComponent implements OnDestroy, OnInit {
  @Output() public meshClicked = new EventEmitter<string | undefined>();

  @ViewChild('canvasRef') canvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('controls') private lockControls!: PointLockControlsComponent;
  @ViewChild('viewport', { static: true }) viewportRef!: ElementRef;

  private resizeObserver: ResizeObserver | undefined;
  private renderer!: WebGLRenderer;
  protected boundingBox: Box3 | null = null;
  protected camera: PerspectiveCamera = new PerspectiveCamera();
  protected scene!: Scene;
  private orbitControls!: OrbitControls;

  private model: BuildingModel | undefined = undefined;

  private beingDragged = false;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private get viewport(): HTMLCanvasElement {
    return this.viewportRef.nativeElement;
  }

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.onDivResized();
    });

    this.resizeObserver.observe(this.viewportRef!.nativeElement);
  }

  onDivResized(): void {
    if (this.renderer === undefined) return;
    this.camera.aspect = this.getAspectRatio();
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.viewport.clientWidth, this.viewport.clientHeight);
  }

  public needsUpdate(): void {
    this.renderer.shadowMap.needsUpdate = true;
  }

  public setModel(model: BuildingModel): void {
    const customDepthMaterial = vertexAlphaDepthMaterial();
    const customDistanceMaterial = new MeshDistanceMaterial({
      vertexColors: true,
      alphaTest: 0.3,
    });

    this.model = model;
    const transparentMesh = model.getObjectByName('transparent_mesh') as Mesh | undefined;
    if (transparentMesh) {
      transparentMesh.renderOrder = 1;
      const transparentMaterial = transparentMesh.material as Material;
      transparentMaterial.transparent = true;
      transparentMaterial.alphaTest = 0.05;
    }
    const opaqueMesh: Mesh | undefined = model.getObjectByName('opaque_mesh') as Mesh | undefined;
    if (opaqueMesh) {
      opaqueMesh.castShadow = true;
      opaqueMesh.receiveShadow = true;
      const opaqueMaterial = opaqueMesh.material as Material;
      opaqueMaterial.transparent = true;
      opaqueMaterial.alphaTest = 0.05;
      opaqueMesh.customDepthMaterial = customDepthMaterial;
      opaqueMesh.customDistanceMaterial = customDistanceMaterial;
    }
  }

  private render(): void {
    this.keepCameraInBounds();

    this.lockControls?.update();
    if (!this.lockControls?.isLocked) this.orbitControls?.update();

    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.render.bind(this));
  }

  private initCam(): void {
    if (!this.boundingBox) return;
    const center = this.boundingBox.getCenter(new Vector3());
    const max = this.boundingBox.max;
    const cameraPosition = max.clone().multiplyScalar(2).sub(center);
    this.camera.position.set(...cameraPosition.toArray());
    this.camera.lookAt(center);
  }

  private createOrbitControls = (): void => {
    this.orbitControls = createOrbitControls(this.camera, this.canvas);
    this.orbitControls.listenToKeyEvents(this.canvas);
    this.orbitControls.keyPanSpeed = 100;
    this.orbitControls.target = this.boundingBox!.getCenter(new Vector3());
    this.canvas.addEventListener('mousedown', () => (this.beingDragged = false));
    this.canvas.addEventListener('mousemove', () => (this.beingDragged = true));
    this.canvas.addEventListener('mouseup', (event) => {
      if (!this.beingDragged) this.onClick.call(this, event);
    });
  };

  private onClick(event: object): void {
    this.deselect();
    const firstVisibleIntersect = this.model?.getFirstVisibleBuildingElementId(
      this.canvas,
      this.camera,
      event['layerX'],
      event['layerY']
    );
    this.meshClicked.emit(firstVisibleIntersect);
  }

  public selectId(id: string): void {
    if (this.model === undefined) return;
    this.model.highlightElement(id);
  }

  public deselect(): void {
    this.model?.unHighlightAllElements();
  }

  private async createScene(): Promise<void> {
    if (this.model === undefined) return;
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(30, this.getAspectRatio(), 1, 10000);
    this.scene.add(this.camera);
    this.scene.add(this.model);
    this.boundingBox = new Box3().setFromObject(this.model).applyMatrix4(this.model.matrixWorld);
    const shadowCatcher = createShadowCatcher(this.boundingBox);
    this.scene.add(shadowCatcher);
    this.initCam();

    this.renderer.shadowMap.needsUpdate = true;
    addLightsToScene(this.scene);
    const directionalLight = configDirectionalLight(this.boundingBox);
    this.scene.add(directionalLight);
  }

  private getAspectRatio(): number {
    return this.viewport.clientWidth / this.viewport.clientHeight;
  }

  private keepCameraInBounds(): void {
    if (!this.boundingBox) return;
    const groundHeight = this.boundingBox.min.y;
    if (this.orbitControls.target.y > groundHeight) return; // If not below ground, don't do anything
    this.camera.position.y += groundHeight - this.orbitControls.target.y;
    this.orbitControls.target.y = groundHeight;
  }

  public async initRender(): Promise<void> {
    this.renderer = initRenderer(this.canvas, this.viewport);
    await this.createScene();
    this.createOrbitControls();
    this.canvas.focus();
    this.render();
  }

  ngOnDestroy(): void {
    this.scene?.clear();
    this.lockControls?.camera?.clear();
    this.camera?.clear();
    this.viewport?.remove();
    this.renderer?.dispose();
    this.orbitControls?.dispose();
    this.resizeObserver?.disconnect();
    this.canvas?.remove();
    this.canvas?.removeEventListener('mousedown', () => (this.beingDragged = false));
    this.canvas?.removeEventListener('mousemove', () => (this.beingDragged = true));
    this.canvas?.removeEventListener('mouseup', (event) => {
      if (!this.beingDragged) this.onClick.call(this, event);
    });
  }
}
