import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageSliderService {
  private pageSliderStatus = new BehaviorSubject<{ active: boolean }>({
    active: false,
  });

  public pageSliderStatus$ = this.pageSliderStatus.asObservable();

  toggle(): void {
    this.pageSliderStatus.next({
      active: !this.pageSliderStatus.value.active,
    });
  }

  status(): boolean {
    return this.pageSliderStatus.value.active;
  }
}
