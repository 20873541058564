import { Component, Input } from '@angular/core';
import { InspectionTemplate, InspectionTemplateSection } from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrl: './toolbox.component.scss',
})
export class ToolboxComponent {
  @Input() edit = false;
  @Input() inspectionTemplate!: InspectionTemplate;
  @Input() currentSection: InspectionTemplateSection | undefined;

  async insertSection(): Promise<void> {
    this.inspectionTemplate.definition.sections.push({
      name: '',
      questions: [],
    });
  }

  insertCheckList(): void {
    if (!this.currentSection) return;
    this.currentSection.questions.push({
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'CheckList',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
    });
  }

  insertRadioList(): void {
    if (!this.currentSection) return;
    this.currentSection.questions.push({
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'RadioList',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
    });
  }

  insertOpenQuestion(): void {
    if (!this.currentSection) return;
    this.currentSection.questions.push({
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'Text',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
    });
  }

  insertImageListQuestion(): void {
    if (!this.currentSection) return;
    this.currentSection.questions.push({
      subject: {
        title: '',
        type: 'Simple',
      },
      property: {
        property_type: 'ImageList',
        name: 'name',
        possible_values: [],
        unit: undefined,
      },
      description: '',
    });
  }
}
