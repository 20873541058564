/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkspaceSettingsDto } from './workspaceSettingsDto';
import { ItemShareDto } from './itemShareDto';
import { SectionDto } from './sectionDto';
import { ItemStatusTypeDto } from './itemStatusTypeDto';
import { PermissionTypeDto } from './permissionTypeDto';


export interface WorkspaceDto { 
    id: string;
    name: string;
    description: string;
    sharedWith: Array<string>;
    permissionType?: PermissionTypeDto;
    workspaceSettings: WorkspaceSettingsDto;
    requestContentItemStatus: ItemStatusTypeDto;
    creatorId: string;
    creationTimestampUtc: Date;
    lastModifiedTimestampUtc: Date;
    isTemplate: boolean;
    sections: Array<SectionDto>;
    shares: Array<ItemShareDto>;
    readonly accessible: boolean;
}
export namespace WorkspaceDto {
}


