import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
})
export class SelectItemComponent {
  @Output() response: EventEmitter<void> = new EventEmitter();

  @Input() label = '';
  @Input() icon = '';
  @Input() iconStyle = 'material-symbols-rounded';

  constructor() {}

  emitOnResponse(): void {
    this.response.emit();
  }
}
