<div class="workspace-select-dialog" [class.active]="active">
  <div class="workspace-select-dialog__header">
    <div class="workspace-select-dialog__header__icon">
      <img src="assets/images/link.png" alt="link" />
    </div>
  </div>

  <div class="workspace-select-dialog__content">
    <h4 class="title">{{ 'workspace-select-title' | translate }}</h4>
    <p class="description">{{ 'workspace-select-description' | translate }}</p>

    <input
      class="workspace-search"
      type="search"
      [(ngModel)]="searchText"
      (input)="search()"
      placeholder="{{ 'search' | translate }}" />

    <ul *ngIf="!noResults">
      <li
        *ngFor="let building of filteredBuildings"
        (click)="selectWorkspace(building.external_id)"
        (keydown)="selectWorkspace(building.external_id)"
        tabindex="-1"
        aria-hidden="true"
        [class.active]="createFromWorkspace === building.external_id">
        {{ building.buildingMetadata.name || building.buildingMetadata.address }}
      </li>
    </ul>
    <p *ngIf="noResults" class="no-result">{{ 'no-result' | translate }}</p>

    <button
      class="button-primary"
      [class.disabled]="!createFromWorkspace"
      [disabled]="!createFromWorkspace"
      (click)="confirm(createFromWorkspace)">
      {{ 'create-with-building' | translate }}
    </button>
    <button class="cancel-button" (click)="confirm('')">
      {{ 'create-without-building' | translate }}
    </button>
  </div>
</div>

<div
  class="workspace-select-dialog__background"
  [class.active]="active"
  (click)="cancel()"
  (keydown)="cancel()"
  tabindex="-1"
  aria-hidden="true"></div>
