import { Component } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
})
export class ColorPickerComponent {
  primaryHalfColor = '#ff8800'; // In final version, fetch colors from api
  secondaryHalfColor = '#1e107f';
  primaryContrast; // The text on the color picking area
  secondaryContrast;

  constructor() {
    this.updateContrastColors();
  }

  updateContrastColors(): void {
    this.primaryContrast = this.calculateContrastColor(this.primaryHalfColor);
    this.secondaryContrast = this.calculateContrastColor(this.secondaryHalfColor);
  }

  calculateContrastColor(hexColor: string): string {
    const rgb = this.hexToRgb(hexColor);
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255; // https://www.w3.org/TR/AERT/#color-contrast

    return luminance > 0.5 ? '#000000' : '#ffffff';
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } {
    const num: number = parseInt(hex.slice(1), 16); // converts color hex to int
    const r = (num >> 16) & 255;
    const g = (num >> 8) & 255;
    const b = num & 255;
    return { r, g, b };
  }
}
