/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WorkspaceCreationRequest { 
    /**
     * Gets or sets the name of the workspace.
     */
    name: string;
    /**
     * Gets or sets the workspace description.
     */
    description: string;
    /**
     * Gets or sets unique identifier for the template on which the newly created workspace should be based.
     */
    sourceTemplateId?: string | null;
    /**
     * Id of workspace that should be used to create template from
     */
    sourceWorkspaceId?: string | null;
    /**
     * Whether we want to create a template or not
     */
    isTemplate: boolean;
}

