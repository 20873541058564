<div class="table-filter">
  <div class="table-filter__active">
    <!--    Dummy filtering uncomment to see-->
    <!--    <button (click)="removeFilter($event)">-->
    <!--      Appartement-->
    <!--      <span class="material-symbols-rounded">close</span>-->
    <!--    </button>-->
    <!--    <button (click)="removeFilter($event)">-->
    <!--      Boswinkel-->
    <!--      <span class="material-symbols-rounded">close</span>-->
    <!--    </button>-->
    <!--    <button (click)="removeFilter($event)">-->
    <!--      Enschede-->
    <!--      <span class="material-symbols-rounded">close</span>-->
    <!--    </button>-->
  </div>
  <!--    Ghost feature -->
  <!--  <div class="select">-->
  <!--    <select>-->
  <!--      <option value="Option 1">Filter on status</option>-->
  <!--      <option value="Option 2">Option 2</option>-->
  <!--      <option value="Option 3">Option 3</option>-->
  <!--      <option value="Option 4">Option 4</option>-->
  <!--      <option value="Option 5">Option 5</option>-->
  <!--      <option value="Option length">Option that has too long of a value to fit</option>-->
  <!--    </select>-->
  <!--  </div>-->
</div>
