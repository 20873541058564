import { Component, HostListener } from '@angular/core';
import { Context, ContextMenuItem, ContextService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
  context: Context;

  constructor(
    public contextService: ContextService,
    private TranslateService: TranslateService
  ) {
    this.context = contextService.context;
    contextService.contextChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.context = value;
    });
  }

  public close(): void {
    this.contextService.hide();
  }

  params(item: ContextMenuItem): ContextMenuItem['interpolationParams'] {
    // Use bracket notation to access 'toLowerCase' due to index signature
    const toLower = item.interpolationParams?.['toLowerCase'] ?? false;

    // Translation function with optional lowercase conversion
    const translate = (str: string): string => {
      const result = str ? this.TranslateService.instant(str) : '';
      return toLower ? result.toLowerCase() : result;
    };

    // Transforming interpolationParams
    const transformedParams = Object.entries(item.interpolationParams || {})
      .filter(([key]): boolean => key !== 'toLowerCase') // Ignore 'toLowerCase' during processing
      .reduce((acc: Record<string, string>, [key, value]: [string, string | undefined]) => {
        // Only proceed if value is defined
        if (value !== undefined) {
          acc[key] = translate(value);
        }
        return acc;
      }, {});

    return transformedParams;
  }

  get list(): ContextMenuItem[] {
    return this.context.list.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent(): void {
    this.contextService.hide();
  }

  keyDown(e: KeyboardEvent): void {
    // TODO : Implement
    console.warn(e);
  }
}
