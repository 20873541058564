<div class="note-popup" [class.active]="notePopupStatus.active">
  <div class="note-popup__header">
    <h4>{{ 'add-note' | translate }}</h4>
    <button class="close-button" (click)="close()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>

  <div class="note-popup__content">
    <app-add-note (cancel)="close()" (noteAdded)="close()"></app-add-note>
  </div>
</div>

<button class="note-popup__background" (click)="close()" [class.active]="notePopupStatus.active">
  &nbsp;
</button>
