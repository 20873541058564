import { Component, OnInit } from '@angular/core';
import { InspectionsService, InspectionTemplateList } from '@api-clients/dossier';
import { Router } from '@angular/router';
import { ContextMenuItem } from '@services/context.service';
import { ToastrService } from '@shared/services/toastr.service';
import { PermissionService } from '../../../auth/permission-service';

@Component({
  selector: 'app-inspection-templates',
  templateUrl: './inspection-templates.component.html',
  styleUrl: './inspection-templates.component.scss',
})
export class InspectionTemplatesComponent implements OnInit {
  protected defaultPageSize: number = 10;
  protected sortBy: string = '';
  protected sortOrder: number = 1; // 1 = ascending, -1 = descending
  protected searchText: string = '';

  protected inspectionTemplates?: InspectionTemplateList[] = undefined;
  protected filteredTemplates?: InspectionTemplateList[] = undefined;

  protected menu: ContextMenuItem[] = [];
  protected activeTemplateId: string = '';
  protected activePage: number = 1;
  protected dossierId: string = '';

  breadcrumbTree = [
    {
      translate: 'inspection-templates',
      link: '/inspection_templates',
    },
  ];

  columns = [
    { key: 'description', label: 'name' },
    { key: 'timestamp_utc', label: 'created-at' },
    { key: '', label: 'actions' },
  ];

  constructor(
    private readonly inspectionsService: InspectionsService,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly permissionService: PermissionService
  ) {
    this.search();
  }

  ngOnInit(): void {
    this.buildMenu();
  }

  hasPermission(scope: string): boolean {
    return this.permissionService.userHasPermissionWithScope('inspection-templates', scope);
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.inspectionsService.templateGet(searchValue).subscribe((next) => {
        this.inspectionTemplates = next;
        this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
        this.updateTemplates(1, this.defaultPageSize);
      });
    } else {
      this.inspectionsService.templateGet().subscribe((next) => {
        this.inspectionTemplates = next;
        this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
        this.updateTemplates(1, this.defaultPageSize);
      });
    }
  }

  toggleSort(property: string): void {
    if (this.sortBy === property) {
      this.sortOrder = -this.sortOrder;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property;
      this.sortOrder = 1;
    }

    this.inspectionTemplates = (this.inspectionTemplates ?? [])
      .slice()
      .sort((a: InspectionTemplateList, b: InspectionTemplateList) => {
        // the OR case is to catch empty cells
        const nameA = (a?.[property] || '').toLocaleLowerCase();
        const nameB = (b?.[property] || '').toLocaleLowerCase();
        return this.sortOrder === 1 ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      });
    this.updateTemplates(this.activePage, this.defaultPageSize);
  }

  onPageChange(event): void {
    this.activePage = event.page;
    this.updateTemplates(event.page, event.pageSize);
  }

  buildMenu(): void {
    this.menu = [];
    if (this.hasPermission('UPDATE')) {
      this.menu.push({
        name: 'Edit',
        icon: 'edit',
        action: () => this.navigateToTemplate(this.activeTemplateId),
      });
    }
    if (this.hasPermission('DELETE')) {
      this.menu.push({
        name: 'remove',
        icon: 'delete',
        action: () => this.delete(this.activeTemplateId),
      });
    }
  }

  updateTemplates(page, size, templates = this.inspectionTemplates ?? []): void {
    this.filteredTemplates = templates.slice((page - 1) * size, page * size);
  }

  setActive(templateId: string): void {
    this.activeTemplateId = templateId;
    this.buildMenu();
  }

  navigateToTemplate(templateId: string): void {
    if (!this.hasPermission('UPDATE')) return;
    void this.router.navigate(['/inspection_templates/', 'edit', templateId]);
  }

  create(): void {
    if (!this.hasPermission('CREATE')) return;
    void this.router.navigate(['/inspection_templates', 'new']);
  }

  delete(templateId: string): void {
    if (!this.hasPermission('DELETE')) return;
    this.inspectionsService.templateIdDelete(templateId).subscribe(() => {
      this.toastrService.showSuccess(
        'dossier-module.template.delete-success',
        'dossier-module.template.delete-title'
      );
      this.search();
    });
  }
}
