import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { ModelViewerComponent } from './views/model-viewer/model-viewer.component';
import { FloorPlanViewerComponent } from './views/floor-plan-viewer/floor-plan-viewer.component';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';
import { BimPropertyService } from './services';

export const shoppingCartHasUnsavedChanges: CanDeactivateFn<
  ModelViewerComponent | FloorPlanViewerComponent
> = (
  component: ModelViewerComponent | FloorPlanViewerComponent,
  currentRoute: ActivatedRouteSnapshot,
  nextState: RouterStateSnapshot
): boolean => {
  const translateService = inject(TranslateService);
  const bimPropertyService = inject(BimPropertyService);

  if (
    component.shoppingCartCount === 0 ||
    nextState.root.component === currentRoute.root.component
  ) {
    return true;
  }

  const confirmation = window.confirm(translateService.instant('close-are-you-sure'));

  if (confirmation) {
    bimPropertyService.deleteChanges();
    return true;
  } else {
    return false;
  }
};
