/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateNoteRequest } from '../model/createNoteRequest';
// @ts-ignore
import { Note } from '../model/note';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class NotesService {

    protected basePath = 'https://api.dev.twinspot.com/dossiers';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Endpoint to retrieve a specific note within a dossier.
     * # Path Parameters: - &#x60;dossier_id&#x60; (path parameter): The unique identifier of the dossier containing the note. - &#x60;id&#x60; (path parameter): The unique identifier of the note to retrieve.  # Authorization: - **Scope:** &#x60;timeline_events:Create&#x60; - **Permissions Required:** Users must have permission to create timeline events.  # Responses: - **200 OK:** Returns the requested note. - **404 Not Found:** If the specified note is not found within the dossier. - **500 Internal Server Error:** If there is a server error during the retrieval process.  Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;1a82a9ac-354b-4fe2-91c6-fcc8bf4f7d3b\&quot;, \&quot;dossier_id\&quot;: \&quot;fd9c4e3a-95b5-45e8-837d-d2c2bb9d1887\&quot;, \&quot;timestamp_utc\&quot;: \&quot;2024-05-01T12:00:00Z\&quot;, \&quot;user_id\&quot;: \&quot;4e7a1b57-60f4-4d79-a41c-5b33e837c32e\&quot;, \&quot;content\&quot;: \&quot;Detailed content of the note\&quot; } &#x60;&#x60;&#x60;  # Error Responses: - **404 Not Found:** If the specified note is not found within the dossier. - **500 Internal Server Error:** If there is a server error during the retrieval process.
     * @param dossierId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersDossierIdNoteIdGet(dossierId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Note>;
    public dossiersDossierIdNoteIdGet(dossierId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Note>>;
    public dossiersDossierIdNoteIdGet(dossierId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Note>>;
    public dossiersDossierIdNoteIdGet(dossierId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossiersDossierIdNoteIdGet.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersDossierIdNoteIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/note/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Note>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to create a new note within a dossier.
     * # Arguments  - &#x60;dossier_id&#x60;: Unique identifier of the dossier where the note will be added. - &#x60;note&#x60;: JSON payload containing the details of the note to be created. - &#x60;auth&#x60;: Authentication information to ensure the user has the necessary permissions.  # Returns  Returns a JSON response containing the newly created note.  # Errors  - 404 Not Found: If the specified dossier, location, or images do not exist. - 500 Internal Server Error: If an unexpected error occurs during the process.  # Example  &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;rich_text\&quot;: \&quot;This is a new note added to the dossier.\&quot;, \&quot;dossier_id\&quot;: \&quot;c5316eb5-8872-4d8a-b7c8-0b08efbf0c07\&quot;, \&quot;user_id\&quot;: \&quot;f5d69c3d-4c1c-4ae5-80b2-279628448b71\&quot;, \&quot;image_ids\&quot;: [\&quot;a1b2c3d4-e5f6-7g8h-9i10-jk11l12m13n14\&quot;, \&quot;o15p16q17-r18s19t20-u21v22w23-x24y25z26\&quot;], \&quot;created_at\&quot;: \&quot;2024-04-29T12:00:00Z\&quot; } &#x60;&#x60;&#x60;
     * @param dossierId 
     * @param createNoteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersDossierIdNotePost(dossierId: string, createNoteRequest: CreateNoteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Note>;
    public dossiersDossierIdNotePost(dossierId: string, createNoteRequest: CreateNoteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Note>>;
    public dossiersDossierIdNotePost(dossierId: string, createNoteRequest: CreateNoteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Note>>;
    public dossiersDossierIdNotePost(dossierId: string, createNoteRequest: CreateNoteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossiersDossierIdNotePost.');
        }
        if (createNoteRequest === null || createNoteRequest === undefined) {
            throw new Error('Required parameter createNoteRequest was null or undefined when calling dossiersDossierIdNotePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/note`;
        return this.httpClient.request<Note>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createNoteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
