/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimsIdentity } from './claimsIdentity';
import { IIdentity } from './iIdentity';
import { Claim } from './claim';


export interface ClaimsPrincipal { 
    readonly claims: Array<Claim>;
    readonly identities: Array<ClaimsIdentity>;
    identity?: IIdentity;
}

