<div class="main-body">
  <app-main-menu></app-main-menu>
  <app-building-menu [buildingId]="buildingId"></app-building-menu>

  <div class="router-wrapper" [class.building-page]="buildingId">
    <router-outlet></router-outlet>
  </div>
</div>

<app-context-menu></app-context-menu>
