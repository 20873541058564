import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import {
  CircleStyleLayer,
  FillExtrusionStyleLayer,
  FillStyleLayer,
  HeatmapStyleLayer,
  HillshadeStyleLayer,
  LayerSpecification,
  LineStyleLayer,
  LngLat,
  Map as MaplibreMap,
  MapMouseEvent,
  Marker,
  SymbolStyleLayer,
  VectorSourceSpecification,
} from 'maplibre-gl';

import { environment } from 'environments/environment';
import { Map3DLayerComponent } from '../map-3d/map-3d-layer';

import { colorRuleMap } from './color-map';
import { layers } from './layers';
import { Observable, skip, take } from 'rxjs';
import { LatLngZoom } from '@shared/components/viewer/viewer.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ContextService } from '@services/context.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { RealEstateId } from '@shared/components/viewer/map-3d/assets/hd-building-manager';
import { TranslateService } from '@ngx-translate/core';

type StyleLayer =
  | FillStyleLayer
  | LineStyleLayer
  | SymbolStyleLayer
  | HeatmapStyleLayer
  | CircleStyleLayer
  | FillExtrusionStyleLayer
  | HillshadeStyleLayer;

@Component({
  selector: 'map-2d',
  templateUrl: './map-2d.component.html',
  styleUrls: ['./map-2d.component.scss'],
})
export class Map2DComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) currentPose!: Observable<LatLngZoom>;
  @Input({ required: true }) map3dLayer!: Map3DLayerComponent;
  @Input({ required: true }) ownedBuildings$!: Observable<BuildingOverviewEntry[]>;
  @Input({ required: true }) selectedBuilding$!: Observable<BuildingOverviewEntry | undefined>;

  @Output() newLatLngZoom = new EventEmitter<LatLngZoom>();
  @Output() dossierAtLatLngRequested = new EventEmitter<LngLat>();
  @Output() selectBuildingRequested = new EventEmitter<RealEstateId>();
  private map!: MaplibreMap;
  private buildingMarkers = new Map<RealEstateId, Marker>();
  protected gisLayers: StyleLayer[] = [];

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  protected showBag3DLayer = true;
  protected showSateliteLayer: boolean = false;
  protected showAHN4Layer: boolean = false;
  protected showCadastralLayer: boolean = false;

  constructor(
    public readonly contextService: ContextService,
    private readonly translateService: TranslateService
  ) {
    this.determineLayers();
  }

  handleOwnedBuildingsChanged(entries: BuildingOverviewEntry[]): void {
    // Remove buildings that are no longer owned
    const toBeRemovedRealEstateIds = [...this.buildingMarkers.keys()].filter(
      (real_estate_id) => !entries.some((building) => building.real_estate_id === real_estate_id)
    );
    toBeRemovedRealEstateIds.forEach((real_estate_id) => {
      this.buildingMarkers.get(real_estate_id)!.remove();
      this.buildingMarkers.delete(real_estate_id);
    });

    // Add buildings that are newly owned
    const toBeAddedBuildings = entries.filter(
      (building) =>
        !building.external_id && // If the b has an external_id, it doesn't need a marker, as it has 3D bag
        !this.buildingMarkers.has(building.real_estate_id || '') // Not yet added
    );
    for (const building of toBeAddedBuildings) {
      if (!building.buildingMetadata?.location) console.info('No location for building', building);
      const marker = new Marker({ className: 'map-building-marker' })
        .setLngLat(building.buildingMetadata!.location!)
        .addTo(this.map);
      marker.getElement().onclick = (event): void => {
        event.stopPropagation();
        this.selectBuildingRequested.emit(building.real_estate_id!);
      };
      this.buildingMarkers.set(building.real_estate_id!, marker);
    }
  }

  determineLayers(): void {
    this.showSateliteLayer = JSON.parse(localStorage.getItem('satellite-layer') || 'false');
    this.showCadastralLayer = JSON.parse(localStorage.getItem('cadastral-layer') || 'false');
    this.showAHN4Layer = JSON.parse(localStorage.getItem('ahn4-layer') || 'false');
    this.showBag3DLayer = JSON.parse(localStorage.getItem('3d-layer') || 'true');
  }

  ngAfterViewInit(): void {
    this.currentPose.pipe(take(1)).subscribe(this.initMap.bind(this));
    this.currentPose.pipe(skip(1)).subscribe(this.navigateToLocation.bind(this));
    this.ownedBuildings$.subscribe((next) => {
      this.handleOwnedBuildingsChanged(next);
    });

    this.selectedBuilding$.subscribe((building) => {
      // Unhighlight all markers
      this.buildingMarkers.forEach((marker) => {
        marker.getElement().classList.remove('active');
      });
      // If the selected building has a marker, highlight it
      if (building?.real_estate_id && this.buildingMarkers.has(building.real_estate_id)) {
        this.buildingMarkers.get(building.real_estate_id)!.getElement().classList.add('active');
      }
    });
  }

  initMap(latLngZoom: LatLngZoom): void {
    const { lat, lng, zoom } = latLngZoom;
    const style = this.getStyle();

    this.map = new MaplibreMap({
      container: this.mapContainer.nativeElement,
      style,
      center: [lng, lat],
      maxPitch: 45,
      pitch: 45,
      zoom,
      boxZoom: false, // we want to use shift for multiselection
    });

    const map = this.map;

    // configuration of the custom layer for a 3D model per the CustomLayerInterface
    const bag3DLayer = this.map3dLayer;

    this.map.on('load', () => {
      layers
        .filter((layer) => !this.mapLayers.includes(layer.id))
        .forEach(({ id, type }) => {
          map.addSource(id, {
            url: `${environment.TREX_TILESERVER}/${id}.json`,
            type: 'vector',
          } as VectorSourceSpecification);

          const colorProp = type === 'fill' ? 'fill-color' : 'line-color';
          const layer = {
            id,
            source: id,
            'source-layer': id,
            paint: {
              [colorProp]: ['case', ...colorRuleMap, 'transparent'],
            },
            type: type || 'line',
            visibility: 'visible',
          } as LayerSpecification;

          map.addLayer(layer);
          const styleLayer = map.getLayer(layer.id);
          this.gisLayers.push(styleLayer as StyleLayer);
        });

      if (this.showCadastralLayer) {
        this.addCadastralLayer();
      }

      if (this.showBag3DLayer) {
        map.addLayer(bag3DLayer);
      }
    });

    this.map.on('resize', () => bag3DLayer.updateProjectionMatrix(map));
    this.map.on('click', (event) => this.handleMouseClick(event));
    this.map.on('contextmenu', (event) => this.handleRightClick(event));
    this.map.on('error', (e) => console.warn('MapLibre error', e));
    this.map.on('dragstart', () => this.contextService.hide());
    this.map.on('zoomend', () => this.updateLatLngZoom());
    this.map.on('zoomstart', () => this.contextService.hide());
    this.map.on('dragend', () => this.updateLatLngZoom());
  }

  get mapLayers(): string[] {
    return layers.filter((layer) => layer.isMapLayer).map((layer) => layer.id);
  }

  handleMouseClick(mapMouseEvent: MapMouseEvent): void {
    this.contextService.hide();
    if (mapMouseEvent.type === 'click' && mapMouseEvent.originalEvent.button === 0)
      // left click
      this.map3dLayer.handleMouseClick(mapMouseEvent);
  }

  handleRightClick(mapMouseEvent: MapMouseEvent): void {
    this.contextService.set(
      mapMouseEvent.originalEvent.clientX,
      mapMouseEvent.originalEvent.clientY,
      [
        {
          name: this.translateService.instant('add-dossier-to-location'),
          icon: 'house',
          action: (): void => {
            this.dossierAtLatLngRequested.emit(mapMouseEvent.lngLat);
          },
        },
      ]
    );
  }

  updateLatLngZoom(): void {
    const { lat, lng } = this.map.getCenter();
    const zoom = this.map.getZoom();
    this.newLatLngZoom.emit({ lat, lng, zoom });
  }

  navigateToLocation(latLngZoom: LatLngZoom): void {
    const { lat, lng, zoom } = latLngZoom;
    const [mapLat, mapLng] = this.map.getCenter().toArray();
    if (lat && lng && (Math.abs(lat - mapLat) > 1e-5 || Math.abs(lng - mapLng) > 1e-5))
      this.map.setCenter([lng, lat]);
    if (zoom && Math.abs(zoom - this.map.getZoom()) > 0.2) this.map.setZoom(zoom);
    this.map.triggerRepaint();
  }

  changeSatelliteLayer = (evt: MatCheckboxChange): void => {
    const isChecked = evt.checked;
    localStorage.setItem('satellite-layer', isChecked.toString());
    this.determineLayers();
    this.map.setStyle(this.getStyle());

    if (this.showCadastralLayer) {
      localStorage.setItem('cadastral-layer', 'false');
      this.map.removeLayer('brk-kadastraal');
      this.map.removeSource('brk-kadastraal');
    }
    if (this.showAHN4Layer) {
      localStorage.setItem('ahn4-layer', 'false');
      this.map.removeLayer('pdok-ahn4');
      this.map.removeSource('pdok-ahn4');
    }
    this.determineLayers();
  };

  changeCadastralLayer = (evt: MatCheckboxChange): void => {
    const isChecked = evt.checked;
    localStorage.setItem('cadastral-layer', isChecked.toString());
    this.determineLayers();

    if (isChecked) {
      this.addCadastralLayer();
    } else {
      this.map.removeLayer('brk-kadastraal');
      this.map.removeSource('brk-kadastraal');
    }
  };

  changeAHN4Layer = (evt: MatCheckboxChange): void => {
    const isChecked = evt.checked;
    localStorage.setItem('ahn4-layer', isChecked.toString());
    this.determineLayers();

    if (isChecked) {
      this.addAHN4Layer();
    } else {
      this.map.removeLayer('pdok-ahn4');
      this.map.removeSource('pdok-ahn4');
    }
  };

  addCadastralLayer(): void {
    this.map.addSource('brk-kadastraal', {
      type: 'raster',
      tiles: [
        'https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0?&service=WMS&request=GetMap&layers=KadastraleGrens%2CLabel%2CBijpijling%2CNummeraanduidingreeks%2COpenbareRuimteNaam&styles=standaard%2Cstandaard%2Cstandaard%2Cstandaard%2Cstandaard&format=image%2Fpng&transparent=true&version=1.3.0&width=512&height=512&maxZoom=24&maxNativeZoom=22&tileSize=512&zIndex=3&minZoom=18&attribution=kadastralekaart1&crs=EPSG%3A3857&bbox={bbox-epsg-3857}',
      ],
      tileSize: 512,
    });

    const is3DLayerVisible = this.map.getLayer(this.map3dLayer.id);

    this.map.addLayer(
      {
        id: 'brk-kadastraal',
        type: 'raster',
        source: 'brk-kadastraal',
        paint: {},
      },
      is3DLayerVisible !== undefined ? is3DLayerVisible.id : undefined
    );
  }

  addAHN4Layer(): void {
    this.map.addSource('pdok-ahn4', {
      type: 'raster',
      tiles: [
        'https://service.pdok.nl/rws/ahn/wms/v1_0?SERVICE=WMS&request=GetMap&layers=dsm_05m&styles=&format=image%2Fpng&transparent=true&version=1.3.0&width=512&height=512&maxZoom=24&maxNativeZoom=22&tileSize=512&zIndex=3&minZoom=18&crs=EPSG%3A3857&bbox={bbox-epsg-3857}',
      ],
      tileSize: 256,
    });

    const is3DLayerVisible = this.map.getLayer(this.map3dLayer.id);

    this.map.addLayer(
      {
        id: 'pdok-ahn4',
        type: 'raster',
        source: 'pdok-ahn4',
        paint: {},
      },
      is3DLayerVisible !== undefined ? is3DLayerVisible.id : undefined
    );
  }

  change3DLayer = (evt: MatCheckboxChange): void => {
    const isChecked = evt.checked;
    localStorage.setItem('3d-layer', isChecked.toString());
    this.determineLayers();

    if (isChecked) {
      this.map.setLayoutProperty(this.map3dLayer.id, 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(this.map3dLayer.id, 'visibility', 'none');
    }
  };

  getStyle(): string {
    if (this.showSateliteLayer) {
      return 'https://api.maptiler.com/maps/hybrid/style.json?key=XCli4HbSVDG3WCLxU24r';
    } else {
      return 'https://api.maptiler.com/maps/57feab4f-e013-4079-b901-0ac7b678f6e9/style.json?key=XCli4HbSVDG3WCLxU24r';
    }
  }

  ngOnDestroy(): void {
    this.map?.remove();
  }
}
