import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StyleguideComponent } from './views/styleguide/styleguide.component';
import { HomeComponent } from './views/home/home.component';
import { ModelViewerExternalComponent } from './views/model-viewer-external/model-viewer-external.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { AuthorizedComponent } from './views/callback/authorized.component';

const routes: Routes = [
  {
    path: 'model-viewer-external/:id',
    component: ModelViewerExternalComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  { path: '', pathMatch: 'full', redirectTo: 'buildings' },
  { path: 'callback', component: AuthorizedComponent },
  {
    path: 'buildings',
    component: HomeComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'styleguide',
    component: StyleguideComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
