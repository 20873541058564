import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-workspace-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class WorkspaceProgressComponent {
  @Input() biggerVersion: boolean = false;
  @Input() menuExpanded: boolean = true;
  @Input() progress: number = 0;
}
