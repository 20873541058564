/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionTypeDto } from './permissionTypeDto';


export interface ItemShareDto { 
    id: string;
    workspaceId?: string | null;
    sectionItemId?: string | null;
    creatorId: string;
    membershipId?: string | null;
    relationId?: string | null;
    contactId?: string | null;
    userId?: string | null;
    email?: string | null;
    organizationId?: string | null;
    permissionType: PermissionTypeDto;
    personalMessage?: string | null;
    lastVisitUtc: Date;
    creationTimestampUtc: Date;
    lastModifiedTimestampUtc: Date;
    isDefaultShare: boolean;
    isLink: boolean;
    name?: string | null;
}
export namespace ItemShareDto {
}


