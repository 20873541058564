/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionTypeDto } from './permissionTypeDto';


export interface ItemShareUpdateRequest { 
    permissionType: PermissionTypeDto;
    isDefaultShare: boolean;
}
export namespace ItemShareUpdateRequest {
}


