<div class="element-popup">
  <div class="element-popup__wrapper" [class.active]="visible">
    <div class="element-popup__header">
      <h5>
        {{ '3d-model-enrichment' | translate }}
      </h5>
      <button
        (click)="bimPropertyService.selectBimElement(undefined)"
        (keydown)="bimPropertyService.selectBimElement(undefined)"
        class="material-symbols-rounded">
        close
      </button>
    </div>
    <div class="element-popup__tabs">
      <button
        (click)="currentTab = elementTab.properties"
        [class.active]="currentTab === elementTab.properties">
        {{ 'add-data' | translate }}
      </button>
      <button
        (click)="dossierTimeline()"
        [class.active]="currentTab === elementTab.dossier || currentTab === elementTab.addNote">
        {{ 'notes' | translate }}
      </button>
      <button
        (click)="currentTab = elementTab.products"
        [class.active]="currentTab === elementTab.products">
        <span *ngIf="amountOfProducts">({{ amountOfProducts }})</span>
        {{ elementTab.products | translate }}
      </button>
    </div>

    <div class="element-popup__content">
      <ng-container *ngIf="selectedElement && currentTab === elementTab.properties">
        <div class="big-input">
          <label class="title" for="category">
            {{ 'category' | translate }}
          </label>

          <select
            (ngModelChange)="selectCategory($event)"
            [disabled]="hasOriginalValue(selectedElement)"
            [ngModel]="selectedElement.element['category']"
            id="category">
            <option *ngFor="let item of categories" [value]="item">
              {{ item | translate }}
            </option>
          </select>
        </div>
        <div *ngIf="selectedElement.propertyDefinition">
          <div *ngFor="let property of selectedElement.propertyDefinition | keyvalue">
            <div *ngIf="isFloatProperty(property.value)" class="big-input">
              <label class="title" for="{{ property.key }}">{{ property.key | translate }}</label>
              <input
                (ngModelChange)="propertyChanged(property.key, $event)"
                [disabled]="categoryIsDisabled(selectedElement)"
                [ngModel]="selectedElement.element.properties[property.key]"
                id="{{ property.key }}"
                type="number"/>

              <label class="units" for="{{ property.key }}">
                {{ property.value.unit }}
              </label>
            </div>
            <label
              *ngIf="isBooleanProperty(property.value)"
              [class.disabled]="categoryIsDisabled(selectedElement)"
              class="checkboxcontainer big-checkbox">
              {{ property.key || '' | translate }}
              <input
                (ngModelChange)="propertyChanged(property.key, $event)"
                [disabled]="categoryIsDisabled(selectedElement)"
                [ngModel]="selectedElement.element.properties[property.key]"
                id="{{ property.key }}"
                type="checkbox"/>
              <span class="checkmark"></span>
            </label>

            <div *ngIf="isEnumProperty(property.value)" class="big-input">
              <label class="title" for="{{ property.key }}">
                {{ property.key | translate }}
              </label>

              <select
                (ngModelChange)="propertyChanged(property.key, $event)"
                [disabled]="categoryIsDisabled(selectedElement)"
                [ngModel]="selectedElement.element.properties[property.key]"
                id="{{ property.key }}">
                <option [value]="undefined">{{ 'unset' | translate }}</option>
                <option *ngFor="let item of property.value.values" [value]="item">
                  {{ item | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="currentTab === elementTab.dossier"
      cdkVirtualScrollingElement
      class="element-popup__content element-popup__content--timeline">
      <app-dossier-timeline
        (imageClick)="console.log('TODO goto dossier page and scroll to event and select image')"
        (timelineEventClick)="console.log('TODO goto dossier page and scroll to event')"
        [smallVersion]="true"></app-dossier-timeline>
    </div>

    <div *ngIf="currentTab === elementTab.addNote" class="element-popup__content">
      <app-add-note
        (cancel)="currentTab = elementTab.dossier"
        (noteAdded)="currentTab = elementTab.dossier"></app-add-note>
    </div>
    <div
      *ngIf="selectedElement && currentTab === elementTab.products"
      class="element-popup__content">
      <div class="element-popup__products">
        <h4 class="element-popup__products__title">
          {{ 'added-products-title' | translate }}
        </h4>
        <span *ngIf="amountOfProducts === 0" class="element-popup__products__no-result">
          {{ 'no-products-added' | translate }}
        </span>
        <ng-container *ngIf="selectedElement.getProducts() as products">
          <ul class="element-popup__products__list">
            <li *ngFor="let product of products; let i = index">
              <a (click)="openProductDetail(product); $event.preventDefault()" href="#">
                <span class="description">{{ product.description }}</span>
              </a>
              <button (click)="removeProduct(product)">
                <span class="material-symbols-rounded">close</span>
              </button>
            </li>
          </ul>
        </ng-container>
        <button (click)="openProductsAdd()" class="add-button">
          {{ 'add-products' | translate }}
          <span class="material-symbols-rounded">chevron_right</span>
        </button>
      </div>
    </div>

    <div *ngIf="currentTab !== elementTab.addNote" class="element-popup__buttons">
      <button
        (click)="currentTab = elementTab.addNote"
        *ngIf="currentTab === elementTab.dossier"
        class="secondary-button">
        {{ 'add-note' | translate }}
      </button>

      <!--        Todo: getChangeCount() should be cached, because it's called at 2x60fps-->
      <button
        (click)="addProperties()"
        *ngIf="currentTab !== elementTab.dossier"
        [disabled]="selectedElement?.getChangeCount() === 0"
        class="button-primary save-button">
        ({{ selectedElement?.getChangeCount() }}) {{ 'add' | translate }}
      </button>
    </div>
    <div [ngClass]="[animateCart ? 'animate' : '']" class="element-popup__added"></div>
  </div>

  <app-products-add-popup
    [active]="productsMenuState === 'add' && selectedElement !== undefined"
    (productsPopupChange)="addProducts($event)"
    [currentProducts]="this.selectedElement?.getProducts()"
    (requestClose)="closeProductPopup()"></app-products-add-popup>
  <app-product-details-popup
    [active]="productsMenuState === 'detail' && selectedElement !== undefined" [showProduct]="detailedProduct"
    (requestClose)="closeProductPopup()"></app-product-details-popup>
</div>
