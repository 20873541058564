<form [class.bar-active]="barActive" class="search-bar">
  <div class="search-input">
    <button (click)="switchBar()" class="search-button">
      <span class="material-symbols-rounded glas">search</span>
      <span class="material-symbols-rounded">arrow_back</span>
    </button>

    <mat-form-field>
      <mat-label>{{ 'search building' | translate }}</mat-label>
      <input
        [formControl]="addressFormControl"
        [matAutocomplete]="auto"
        (keydown)="onSearchKeydown($event)"
        [value]="addressFormControl.value['display_name']"
        matInput
        #addressInput
        type="text"/>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAddress($event)">
        <mat-option
          *ngFor="let option of geocodeResult"
          [value]="option['place']"
          [id]="option['id']">
          <span class="material-symbols-rounded">home</span>
          <div class="text">
            <span class="street">{{ option['street'] }}</span>
            <span class="postal">{{ option['postal'] }}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</form>
