/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SectionTypeDto } from './sectionTypeDto';


export interface AddSectionRequest { 
    name: string;
    sectionType?: SectionTypeDto;
    description: string;
    insertAt?: number | null;
    metadata?: any | null;
}
export namespace AddSectionRequest {
}


