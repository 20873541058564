/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimsIdentity } from './claimsIdentity';


export interface Claim { 
    readonly issuer: string;
    readonly originalIssuer: string;
    readonly properties: { [key: string]: string; };
    subject?: ClaimsIdentity;
    readonly type: string;
    readonly value: string;
    readonly valueType: string;
}

