import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { DossierDetailComponent } from './views/dossier-detail/dossier-detail.component';
import { shoppingCartHasUnsavedChanges } from './building.guards';
import { FloorPlanViewerComponent } from './views/floor-plan-viewer/floor-plan-viewer.component';
import { ModelViewerComponent } from './views/model-viewer/model-viewer.component';
import { BuildingWrapperComponent } from './views/building-wrapper/building-wrapper.component';
import { InspectionOverviewComponent } from '../inspection-module/views/overview-page/inspection-overview.component';

const routes: Routes = [
  //This routing is only for building/id routes for the rest please other routing modules
  {
    path: 'buildings/:id',
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [shoppingCartHasUnsavedChanges],
    component: BuildingWrapperComponent,
    children: [
      {
        path: 'dossier',
        component: DossierDetailComponent,
      },
      {
        path: 'model/:state',
        component: ModelViewerComponent,
      },
      {
        path: 'floor-plan/:state',
        component: FloorPlanViewerComponent,
      },
      {
        path: 'forms',
        component: InspectionOverviewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuildingRoutingModule {}
