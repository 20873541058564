import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Observable, switchMap } from 'rxjs';
import { DossierService } from '@services/dossier.service';
import { ActivatedRoute } from '@angular/router';
import { Dossier } from '@api-clients/dossier';

@Injectable({
  providedIn: 'root',
})
export class DossierDetailService {
  dossier: Observable<Dossier>;

  constructor(activatedRoute: ActivatedRoute, dossierService: DossierService) {
    this.dossier = activatedRoute.paramMap.pipe(
      takeUntilDestroyed(),
      map((paramMap) => paramMap.get('id')),
      switchMap((buildingId) => {
        if (!buildingId) throw new Error('No building_id found in route.');
        return dossierService.getDossier(buildingId); // Return Observable
      })
    );
  }
}
