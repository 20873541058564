<div [class.active]="active">
  <div class="label">
    <button (click)="toggle()">
      {{ label }}
    </button>

    <app-lightswitch [label]="'Voor alle kozijnen'" [preLabel]="true"></app-lightswitch>
  </div>
  <button (click)="toggle()" class="big-select">
    <span *ngIf="icon" class="{{ iconStyle }} icon">
      {{ icon }}
    </span>

    <div class="current">
      <span>{{ current }}</span>
    </div>
    <div class="down">
      <span class="material-symbols-rounded">expand_more</span>
    </div>
  </button>

  <button (click)="close()" class="big-select__background">&nbsp;</button>
  <div (window:wheel)="close()" class="big-select__options">
    <ng-content></ng-content>
  </div>
</div>
