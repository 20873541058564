<div class="product-details-popup" [class.active]="active">
  <div class="product-details-popup__header">
    <h5>{{ 'product-details' | translate }}</h5>

    <button (click)="closePopup()" (keydown)="closePopup()" class="material-symbols-rounded">
      close
    </button>
  </div>
  <div class="product-details-popup__content" *ngIf="productDetails && state === 'loaded'">
    <img
      [imgFallback]="
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/klEQVR4nO2ZyWsUQRSHPzGIC2rUg1k0auIexIMQ0YMiHgRxQfCgh7ggCJ686CT+AQqCN0EPHjwIXrwpYlQ04h71EAUxiqBxwQXEBZSIxpYHr6Boqme6p3t6UiE/KGamqt6b+ujqqveqYER+6Q4QVKDcBqbnCRJUsPQBDb6DPNbPZ0C9zyBTgIf6/QXQ6CuIqBZ4oL9fAbPxFMTA9Gjda2COryCiycA9re8Hmn0FMTB3LZgWX0FEE4BubX8DzPUVxMBc1z4fgMW+gojGA9e038esYKoBYmCuWjCteAoiGgdc0f6fgCWkUKkBDQAHNcyQUgB+ZwRiYLqs1axslRqQQITVUeUn6VQpx3UOmzofQeodNo0+ghQcNp0+ggwoTIOWjpgv+5ADCXIuZSsYQuVP+RjVH3xgla7hArJtOIB8013ee5ATaSCyAjmupVz7H2kDxixALgE1wGjgfELbR8B+YFpaiLQgT4BJlq+JWlfKrhtYmMXgswCRNLXJ4a9J24rZrnPYSf4+K2+QX0BbEZ9t2sdl+1anoVG7HnhL2+c8Qf4BW2P4lT6DDvvDVp+1jvbcQA7F9LsA6A3Z/gydZ52tFsjpmD4XadwUWCfynaErhtqI6VdxkBvAmJg+j6mNnF+tiOizN+J/KgryHJga01+NtWotj0iTDwDv8gb5AsxP4G+j2j216kYBm4ELoSmXG4hkgqsT+FoJ3HecvmxJMIXLVjGnu2LYz9CVrC+0OtmHFhetwLA3b5AjMWyXhfL398BRYJ7VR1aqv5r7S0wl78jLvEDO6bwupVPWirY+tGMbFSyfRi1FwpjMQHpiJjhyCP1dbVwBoASTe6zVaUOofakmUxUB6Y84WXSpXW3kFsrWGuCMvieBdasry3JYqxybYmqQJMlNq+YSgW5sRjssf4O6Ie7Uy50obQotyalBZI4Xk9yb77NuaA283A0amSj2ZMKr6N157ezh8lUHaz/BZo2My/EXZAFyK+YfyOO/DGwHxjr8zNTLmjQQN9OAjIic9R+SPYjgkpLwMQAAAABJRU5ErkJggg=='
      "
      [src]="productDetails.cdn"
      [alt]="productDetails.gtin" />

    <table *ngIf="productDetails">
      <tr *ngIf="productDetails.gtin">
        <td class="property-title">{{ 'product-detail-gtin' | translate }}</td>
        <td>{{ productDetails.gtin }}</td>
      </tr>
      <tr *ngIf="productDetails.description">
        <td class="property-title">{{ 'product-detail-description' | translate }}</td>
        <td>{{ productDetails.description }}</td>
      </tr>
      <tr *ngIf="productDetails.deeplink">
        <td class="property-title">{{ 'product-detail-deeplink' | translate }}</td>
        <td>
          <a href="{{ productDetails.deeplink }}" target="_blank">{{ productDetails.deeplink }}</a>
        </td>
      </tr>
      <tr *ngIf="productDetails.manufacturer_name">
        <td class="property-title">{{ 'product-detail-manufacturer' | translate }}</td>
        <td>{{ productDetails.manufacturer_name }}</td>
      </tr>
      <tr *ngIf="productDetails.country_of_production?.length">
        <td class="property-title">{{ 'product-detail-countries' | translate }}</td>
        <td>{{ productDetails.country_of_production?.join(',') }}</td>
      </tr>
      <tr *ngIf="productDetails.weight">
        <td class="property-title">{{ 'product-detail-weight' | translate }}</td>
        <td>{{ productDetails.weight }} {{ productDetails.weight_unit ?? '' | translate }}</td>
      </tr>
    </table>
  </div>
  <div class="product-details-popup__content loading" *ngIf="state === 'loading'">
    {{ 'product-detail-loading' | translate }}
    <div class="small-spinner"></div>
  </div>
  <div class="product-details-popup__content loading" *ngIf="state === 'error'">
    {{ 'product-detail-no-product' | translate }}
  </div>
</div>
<div class="product-details-popup__background" [class.active]="active"></div>
