import { BuildingObject, Category, Level, Room } from './building-elements';
import {
  BimObjectGraphDto,
  BuildingInformationModelDetailedDto,
  ObjectCategory,
} from '@api-clients/bim';
import {
  Box3,
  BufferGeometry,
  Camera,
  Group,
  Mesh,
  MeshStandardMaterial,
  Raycaster,
  Vector2,
} from 'three';
import { uniq } from 'ramda';

interface HighlightedElement {
  id: string;
  batchId: number;
  colors: Uint8Array;
}

export class BuildingModel extends Group {
  categories: Category[] = [];
  levels: Level[] = [];
  rooms: Room[] = [];
  objects: BuildingObject[] = [];
  raycaster: Raycaster = new Raycaster();
  boundingBox!: Box3;
  batchTable: string[];
  highlightedElements: HighlightedElement[] = [];
  hiddenBatchIds = new Set<number>();

  constructor(model: Group, bim: BuildingInformationModelDetailedDto, batchTable: string[]) {
    super();

    const objectIndices: ([number, number] | undefined)[][] = [];
    model.children.forEach((child) => {
      const bufferGeometry = child['geometry'] as BufferGeometry;
      // The vertex batch id buffer stores the batch id for each vertex
      // This batch id is linked 1-to-1 to a bim id using the batch table that's also in the glb file.
      const vertexBatchIdBuffer = bufferGeometry.getAttribute('_batch_ids').array as Uint32Array;
      const max = vertexBatchIdBuffer.at(-1)!;
      // This code fills each batch id with the start and end index of the vertices that belong to that batch id
      const indices: ([number, number] | undefined)[] = Array(max).fill(undefined);
      let lastValue = vertexBatchIdBuffer.at(0)!;
      let lastIndex = 0;
      vertexBatchIdBuffer.forEach((value, i) => {
        if (value != lastValue) {
          indices[lastValue] = [lastIndex, i];
          lastIndex = i;
          lastValue = value;
        }
      });
      indices.push([lastIndex, vertexBatchIdBuffer.length]);

      objectIndices.push(indices);
    });
    // Object indices now contains the start and end index of each object in the vertex buffer for each mesh and each batch id.

    const levels: Level[] = bim.levels.map(
      (level) => new Level(level.id, level.name, level.level, [], [])
    );

    const categories = categoriesFromBuildingObjects(bim.objects);

    const rooms: Room[] = bim.rooms.map((room) => {
      const batchId = batchTable.indexOf(room.id);
      const indices: [meshId: number, [vBufferStartId: number, vBufferEndId: number]] | undefined =
        objectIndices
          .map((objectIndicesInMesh, meshIndex): [number, [number, number]] | undefined => {
            return objectIndicesInMesh[batchId] === undefined
              ? undefined
              : [meshIndex, objectIndicesInMesh[batchId]!];
          })
          .find((indices) => indices !== undefined);
      return new Room(
        room.id,
        room.category,
        [],
        levels.filter((level) => room.levels.includes(level.bimId)),
        batchId,
        indices
      );
    });

    // Rooms have links to levels. Now add links from levels to rooms
    for (const room of rooms) {
      for (const level of room.levels) {
        level.rooms.push(room);
      }
    }

    // Construct building objects
    const objects = bim.objects.map((object) => {
      const objectLevels = levels.filter((level) => object.levels.includes(level.bimId));
      const objectRooms = rooms.filter((room) => object.rooms.includes(room.bimId));
      const objectCategory = categories.find((category) => category.type === object.category);
      // Get the batch id of the object based on its bim_id.
      const batchId = batchTable.indexOf(object.id);
      // Each element can claim its own indices from the objectIndices array based on its batch id
      const indices: [meshId: number, [vBufferStartId: number, vBufferEndId: number]] | undefined =
        objectIndices
          .map((objectIndicesInMesh, meshIndex): [number, [number, number]] | undefined => {
            return objectIndicesInMesh[batchId] === undefined
              ? undefined
              : [meshIndex, objectIndicesInMesh[batchId]!];
          })
          .find((indices) => indices !== undefined);
      const buildingElement = new BuildingObject(
        object.id,
        batchId,
        indices,
        objectRooms,
        objectLevels,
        objectCategory
      );

      // Element already links to levels. Now also reverse the link
      for (const level of buildingElement.levels) {
        level.objects.push(buildingElement);
      }
      // Element already links to rooms. Now also reverse the link
      for (const room of buildingElement.rooms) {
        room.objects.push(buildingElement);
      }
      // If element already links to category. Now also reverse the link
      if (buildingElement.category) {
        buildingElement.category.objects.push(buildingElement);
      }

      return buildingElement;
    });

    this.children = model.children;
    this.batchTable = batchTable;
    this.objects = objects;
    this.rooms = rooms;
    this.levels = levels;
    this.categories = categories;
    this.boundingBox = new Box3().setFromObject(model);
    this.raycaster = new Raycaster();
    model.children.forEach((child) => {
      const mesh = child as Mesh;
      mesh.geometry.computeVertexNormals();
      (mesh.material as MeshStandardMaterial).depthWrite = true;
    });
  }

  toggleCategoryVisibility(category: ObjectCategory): void {
    const categoryGroup = this.categories.find((categoryGroup) => categoryGroup.type === category);
    if (!categoryGroup) throw new Error('Category not found');
    this.setCategoryVisibility(category, !categoryGroup.visible);
  }

  setCategoryVisibility(category: ObjectCategory, visibility: boolean): void {
    const categoryGroup = this.categories.find((categoryGroup) => categoryGroup.type === category);
    if (!categoryGroup) throw new Error('Category not found');
    if (visibility) this.unhideCategory(categoryGroup);
    else this.hideCategory(categoryGroup);
  }

  private hideCategory(category: Category): void {
    const toBeHidden = category.objects
      .filter((object) => !this.hiddenBatchIds.has(object.batchId))
      .filter((object) => object.indices !== undefined);
    this.hideBuildingObjects(toBeHidden);
    category.visible = false;
  }

  private hideLevel(level: Level): void {
    const toBeHidden = level.objects
      .filter((object) => !this.hiddenBatchIds.has(object.batchId))
      .filter((object) => object.indices !== undefined);
    this.hideBuildingObjects(toBeHidden);
    level.visible = false;
  }

  private unhideCategory(category: Category): void {
    const toBeUnhidden = category.objects.filter(
      (object) =>
        this.hiddenBatchIds.has(object.batchId) &&
        object.indices !== undefined &&
        !object.levels.some((level) => !level.visible) // Only unhide objects on visible levels
    );
    this.unhideBuildingObjects(toBeUnhidden);
    category.visible = true;
  }

  private unhideLevel(level: Level): void {
    const toBeUnhidden = level.objects.filter(
      (object) =>
        this.hiddenBatchIds.has(object.batchId) &&
        object.indices !== undefined &&
        object.category?.visible === true // Only unhide objects with visible categories
    );
    this.unhideBuildingObjects(toBeUnhidden);
    level.visible = true;
  }

  toggleLevelVisibility(level: number): void {
    const levelGroup = this.levels.find((levelGroup) => levelGroup.level === level);
    if (!levelGroup) throw new Error('Level not found');
    this.setLevelVisibility(level, !levelGroup.visible);
  }

  setLevelVisibility(level: number, visibility: boolean): void {
    const levelGroup = this.levels.find((levelGroup) => levelGroup.level === level);
    if (!levelGroup) throw new Error('Level not found');
    if (visibility) this.unhideLevel(levelGroup);
    else this.hideLevel(levelGroup);
  }

  private unhideBuildingObjects(objects: BuildingObject[]): void {
    objects.forEach((object) => this.hiddenBatchIds.delete(object.batchId));

    const buffers = this.children.map(
      (child) => child['geometry'].getAttribute('color').array as Uint8Array
    );
    for (const object of objects) {
      const [meshIndex, indices] = object.indices!;
      buffers[meshIndex].set(object.colorCache!, indices[0] * 4 + 3);
      delete object.colorCache;
    }
    this.children.forEach((child) => (child['geometry'].getAttribute('color').needsUpdate = true));
  }

  private hideBuildingObjects(objects: BuildingObject[]): void {
    objects.forEach((object) => {
      this.hiddenBatchIds.add(object.batchId);
    });

    const buffers = this.children.map(
      (child) => child['geometry'].getAttribute('color').array as Uint8Array
    );

    for (const object of objects) {
      const buffer = buffers[object.indices![0]];
      const startColorIndex = object.indices![1][0] * 4 + 3;
      const endColorIndex = object.indices![1][1] * 4 + 3;
      object.colorCache = Uint8Array.from(buffer.subarray(startColorIndex, endColorIndex));
      for (let i = startColorIndex; i < endColorIndex; i += 4) {
        buffer[i] = 0;
      }
    }

    this.children.forEach((child) => (child['geometry'].getAttribute('color').needsUpdate = true));
  }

  highlightElement(element_id: string): void {
    const batchId = this.batchTable.indexOf(element_id);
    for (const child of this.children) {
      const bufferGeometry = child['geometry'] as BufferGeometry;
      const colorBuffer = bufferGeometry.getAttribute('color').array as Uint8Array;
      const batchTable = bufferGeometry.getAttribute('_batch_ids').array as Uint32Array;
      const startIndex = batchTable.indexOf(batchId);
      if (startIndex === -1) continue;
      const length = batchTable.subarray(startIndex).findIndex((id) => id != batchId);
      const colors = colorBuffer.subarray(startIndex * 4, (startIndex + length) * 4);
      this.highlightedElements.push({
        id: element_id,
        batchId: batchId,
        colors: Uint8Array.from(colors),
      });
      for (let i = 0; i < length; i++) {
        colors.set([100, 110, 181, 255], i * 4);
      }
      bufferGeometry.getAttribute('color').needsUpdate = true;
    }
  }

  unHighlightAllElements(): void {
    for (const element of this.highlightedElements) {
      for (const child of this.children) {
        const bufferGeometry = child['geometry'] as BufferGeometry;
        const colorBuffer = bufferGeometry.getAttribute('color').array as Uint8Array;
        const batchTable = bufferGeometry.getAttribute('_batch_ids').array as Uint32Array;
        const startIndex = batchTable.indexOf(element.batchId);
        if (startIndex === -1) continue;
        colorBuffer.set(element.colors, startIndex * 4);

        bufferGeometry.getAttribute('color').needsUpdate = true;
      }
    }
    this.highlightedElements = [];
  }

  public getFirstVisibleBuildingElementId(
    canvas: HTMLCanvasElement,
    camera: Camera,
    mouseX: number,
    mouseY: number
  ): string | undefined {
    const ids = this.objects.map((element) => element.bimId);
    return this.getFirstVisibleIntersection(canvas, camera, mouseX, mouseY, ids);
  }

  private getFirstVisibleIntersection(
    canvas: HTMLCanvasElement,
    camera: Camera,
    mouseX: number,
    mouseY: number,
    allowedUuids: string[]
  ): string | undefined {
    const pointer = new Vector2(
      (mouseX / canvas.clientWidth) * 2 - 1,
      -(mouseY / canvas.clientHeight) * 2 + 1
    );
    this.raycaster.setFromCamera(pointer, camera);
    const intersects = this.raycaster.intersectObject(this, true);
    let uuid: string | undefined = undefined;
    for (const intersect of intersects) {
      if (!intersect.face) continue;
      const bufferGeometry = intersect.object['geometry'] as BufferGeometry | undefined;
      if (!bufferGeometry?.hasAttribute('_batch_ids')) continue;
      const colors = bufferGeometry.getAttribute('color').array;
      const transparency = colors[intersect.face.a * 4 + 3];
      if (transparency === 0) continue;
      const batchIds = bufferGeometry.getAttribute('_batch_ids').array;
      const batchId = batchIds[intersect.face.a];
      const foundUuid = this.batchTable[batchId];
      // If the uuid is allowed, return it. This prevents room/level selection.
      if (allowedUuids.includes(foundUuid) || 0 === 0) {
        uuid = foundUuid;
        break;
      }
    }
    return uuid;
  }
}

// Gets the categories out of the objects and makes a unique list of them
function categoriesFromBuildingObjects(objects: BimObjectGraphDto[]): Category[] {
  return uniq(objects.map((object) => object.category)).map(
    (category) => new Category(category, [])
  );
}
