<div class="list-view">
  <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

  <h2 class="h5">{{ 'residentialPortfolio' | translate }}</h2>
  <p>{{ buildings.length }} {{ 'unitsFound' | translate }}</p>

  <app-table-filter></app-table-filter>

  <table class="table responsive-table">
    <thead>
      <tr>
        <th>{{ 'Name' | translate }}</th>
        <th>{{ 'Address' | translate }}</th>
        <th>{{ 'Zipcode' | translate }}</th>
        <th>{{ 'City' | translate }}</th>
        <th>{{ 'Woningtype' | translate }}</th>
        <th>{{ 'Kadaster id' | translate }}</th>
        <th>{{ '3D model' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let building of filteredBuildings"
        [routerLink]="['/buildings/' + building.real_estate_id + '/dossier']">
        <ng-container *ngIf="building.buildingMetadata as metadata">
          <td [attr.data-title]="'Name' | translate">
            {{ building.buildingMetadata.name || building.buildingMetadata.address }}
          </td>
          <td [attr.data-title]="'Address' | translate">{{ metadata.address }}</td>
          <td [attr.data-title]="'Zipcode' | translate">{{ metadata.postalCode }}</td>
          <td [attr.data-title]="'City' | translate">{{ metadata.city }}</td>
          <td [attr.data-title]="'Woningtype' | translate">1</td>
        </ng-container>
        <td [attr.data-title]="'Kadaster id' | translate">
          {{ building.external_id }}
        </td>
        <td [attr.data-title]="'3D model' | translate">
          <!--        <div *ngIf="hasFiles(building)">
          <button
            (click)="navigateToModel($event, file)"
            *ngFor="let file of building.metadata?.additionalFiles"
            class="material-symbols-rounded model-link">
            home
          </button>
        </div>-->
        </td>
      </tr>
    </tbody>
  </table>
  <app-table-pagination
    (pageChange)="onPageChange($event)"
    [total]="buildings.length"
    [pageSize]="defaultPageSize"
    [page]="1"></app-table-pagination>
</div>
