import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ContextMenuItem {
  name?: string | undefined;
  icon?: string | (() => string);
  visible?: boolean | (() => boolean);
  style?: string;
  link?: string | undefined;
  action?: () => void;
  divider?: boolean;
  checked?: () => boolean;
  interpolationParams?: Record<string, string | undefined>;
}

export interface Context {
  active: boolean;
  animate: boolean;
  x: number;
  y: number;
  list: ContextMenuItem[];
}

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  context: Context = { active: false, animate: false, x: 0, y: 0, list: [] };
  contextChange: Subject<Context> = new Subject<Context>();

  get(): Context {
    return this.context;
  }

  animate(boolean): void {
    this.context.animate = boolean;
  }

  hide(): void {
    this.context.animate = false;
    this.context.active = false;
  }

  set(x: number, y: number, menu: ContextMenuItem[]): void {
    if (menu.length) {
      this.context.active = true;
      this.context.x = x;
      this.context.y = y;
      this.context.list = menu.map((item) => ({
        ...item,
        icon: typeof item.icon === 'function' ? item.icon() : item.icon,
      }));
      this.contextChange.next(this.context);
    } else {
      this.hide();
    }
  }
}
