/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InspectionValueValueText } from './inspectionValueValueText';
import { InspectionValueCheckListValues } from './inspectionValueCheckListValues';
import { InspectionValueRadioListValue } from './inspectionValueRadioListValue';
import { InspectionValueValueNumber } from './inspectionValueValueNumber';
import { InspectionValueValueBoolean } from './inspectionValueValueBoolean';
import { InspectionValueValueImages } from './inspectionValueValueImages';


/**
 * @type InspectionValue
 * @export
 */
export type InspectionValue = InspectionValueCheckListValues | InspectionValueRadioListValue | InspectionValueValueBoolean | InspectionValueValueImages | InspectionValueValueNumber | InspectionValueValueText;

