<div class="user-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'user-detail-page' | translate }}</h1>
    </div>
  </div>

  <form [formGroup]="userDetailForm" (submit)="updateUser()" class="form">
    <div class="form-group">
      <app-profile-picture></app-profile-picture>
    </div>

    <div class="form-group half">
      <label for="first-name">{{ 'first-name' | translate }}</label>
      <input id="first-name" type="text" formControlName="firstName" />
      <div class="error half" *ngIf="userDetailForm.controls.firstName.errors?.['required']">
        {{ 'first-name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group half">
      <label for="last-name">{{ 'last-name' | translate }}</label>
      <input id="last-name" class="half" type="text" formControlName="lastName" />
      <div class="error" *ngIf="userDetailForm.controls.lastName.errors?.['required']">
        {{ 'last-name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="email">{{ 'email-address' | translate }}</label>
      <input id="email" class="input" type="text" formControlName="email" />
      <div class="error" *ngIf="userDetailForm.controls.email.errors?.['required']">
        {{ 'email-required-error' | translate }}
      </div>
      <div class="error" *ngIf="userDetailForm.controls.email.errors?.['email']">
        {{ 'email-invalid-error' | translate }}
      </div>
    </div>

    <div class="form-group" *ngIf="canSetOrganization">
      <label for="organization">{{ 'organization' | translate }}</label>
      <select formControlName="organizationId" id="organization">
        <option *ngFor="let organization of organizations" [value]="organization.id">
          {{ organization.name }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label for="organizationRole">{{ 'role' | translate }}</label>
      <select formControlName="organizationRole" id="organizationRole">
        <option [ngValue]="OrganizationRole.User">{{ 'user' | translate }}</option>
        <option [ngValue]="OrganizationRole.Administrator">
          {{ 'administrator' | translate }}
        </option>
      </select>
    </div>

    <div formGroupName="address" class="subform">
      <div class="form-group big">
        <label for="street-name">{{ 'address' | translate }}</label>
        <input id="street-name" class="input" type="text" formControlName="streetName" />
        <div
          class="error"
          *ngIf="userDetailForm.controls.address?.controls?.streetName?.errors?.['required']">
          {{ 'street-name-required-error' | translate }}
        </div>
      </div>

      <div class="form-group small">
        <!--      MISSING ADDRESS NUMBER DTO-->
        <label for="address-number">{{ 'address-number' | translate }}</label>
        <input id="address-number" class="input" type="text" formControlName="number" />
        <div
          class="error"
          *ngIf="userDetailForm.controls.address.controls?.number?.errors?.['required']">
          {{ 'address-number-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="city">{{ 'city' | translate }}</label>
        <input id="city" class="input" type="text" formControlName="city" />
        <div
          class="error"
          *ngIf="userDetailForm.controls.address.controls?.city?.errors?.['required']">
          {{ 'city-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="country">{{ 'country' | translate }}</label>
        <input id="country" class="input" type="text" formControlName="country" />
        <div
          class="error"
          *ngIf="userDetailForm.controls.address.controls?.country?.errors?.['required']">
          {{ 'country-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="postal-code">{{ 'postal-code' | translate }}</label>
        <input id="postal-code" class="input" type="text" formControlName="postalCode" />
        <div
          class="error"
          *ngIf="userDetailForm.controls.address.controls?.postalCode?.errors?.['required']">
          {{ 'postal-code-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="state">{{ 'state' | translate }}</label>
        <input id="state" class="input" type="text" formControlName="state" />
        <div
          class="error"
          *ngIf="userDetailForm.controls.address.controls?.state?.errors?.['required']">
          {{ 'state-required-error' | translate }}
        </div>
      </div>
    </div>

    <button type="submit" class="button-primary button--has-icon" [disabled]="isSaving">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'user-save' | translate }}
    </button>
    <button
      type="button"
      *ngIf="canDelete && !isNew"
      (click)="openRemovePopup()"
      class="button-remove">
      {{ 'user-remove' | translate }}
    </button>
  </form>

  <app-confirmation-dialog
    [title]="'user-delete-title' | translate"
    [description]="'user-delete-description' | translate"
    [icon]="'bin'"
    [confirmText]="'user-delete-confirm' | translate"
    (confirmOutput)="removeUser()"></app-confirmation-dialog>
</div>
