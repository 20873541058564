/**
 * Real Estate API
 * This api is used to maintain buildings and their addresses.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinates } from './coordinates';


export interface CreateUpdateBuildingRequest { 
    coordinates: Coordinates;
    external_id?: string;
}

