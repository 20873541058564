/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MimeTypeDto } from './mimeTypeDto';
import { ContentItemDto } from './contentItemDto';
import { RequestContentItemDto } from './requestContentItemDto';
import { RequestContentItemMessageDto } from './requestContentItemMessageDto';
import { SectionItemType } from './sectionItemType';
import { SectionDto } from './sectionDto';
import { QuestionType } from './questionType';
import { SectionTypeDto } from './sectionTypeDto';
import { QuestionDto } from './questionDto';
import { ContentItemMetaDto } from './contentItemMetaDto';
import { ItemStatusTypeDto } from './itemStatusTypeDto';
import { PermissionTypeDto } from './permissionTypeDto';


/**
 * @type SectionDtoItemsInner
 * @export
 */
export type SectionDtoItemsInner = ContentItemDto | QuestionDto | RequestContentItemDto | SectionDto;

