import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-lightswitch',
  templateUrl: './lightswitch.component.html',
  styleUrls: ['./lightswitch.component.scss'],
})
export class LightswitchComponent {
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() preLabel: boolean = false;
  @Input() label: string = '';
  @Output() changedState = new EventEmitter(this.checked);

  constructor() {}

  change(): void {
    this.checked = !this.checked;
    this.changedState.emit(this.checked);
  }
}
