/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SectionItemType = 'Section' | 'FileRequest' | 'Question' | 'ContentItem';

export const SectionItemType = {
    Section: 'Section' as SectionItemType,
    FileRequest: 'FileRequest' as SectionItemType,
    Question: 'Question' as SectionItemType,
    ContentItem: 'ContentItem' as SectionItemType
};

