/**
 * s3wrapper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A GetUploadUrlResponse
 */
export interface GetUploadUrlResponse { 
    /**
     * Signed URL for uploading an item
     */
    url?: string | null;
    /**
     * This guid is the unique directory path filename within the bucket
     */
    id?: string | null;
    /**
     * Custom message to be included in the response
     */
    message?: string | null;
}

