<div #viewport id="viewport">
  <app-point-lock-controls
    #controls
    *ngIf="camera && scene"
    [camera]="camera"
    [canvas]="canvasRef"></app-point-lock-controls>

  <canvas #canvasRef id="canvas" tabindex="0"></canvas>

  <ng-content></ng-content>
</div>
