<div class="organization-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>
      <h1 class="h4">{{ 'organization-detail-page' | translate }}</h1>
    </div>
  </div>

  <form class="form" [formGroup]="organizationDetailForm" (submit)="updateOrganization()">
    <div class="form-group">
      <label for="logo">{{ 'company-picture' | translate }}</label>
      <app-profile-picture [isRectangle]="true"></app-profile-picture>
    </div>

    <div class="form-group">
      <label for="colors">{{ 'theme-colors' | translate }}</label>
      <app-color-picker></app-color-picker>
    </div>

    <div class="form-group">
      <label for="name">{{ 'name' | translate }}</label>
      <input id="name" name="name" type="text" formControlName="name" />
      <div class="error" *ngIf="organizationDetailForm.controls.name.hasError('required')">
        {{ 'name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="phoneNumber">{{ 'telephone-number' | translate }}</label>
      <input id="phoneNumber" name="phoneNumber" type="text" formControlName="phoneNumber" />
      <div class="error" *ngIf="organizationDetailForm.controls.phoneNumber?.hasError('pattern')">
        {{ 'phone-invalid-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="email">{{ 'email-address' | translate }}</label>
      <input id="email" name="email" type="text" formControlName="emailAddress" />
      <div class="error" *ngIf="organizationDetailForm.controls.emailAddress?.hasError('required')">
        {{ 'email-required-error' | translate }}
      </div>
      <div class="error" *ngIf="organizationDetailForm.controls.emailAddress?.hasError('email')">
        {{ 'email-invalid-error' | translate }}
      </div>
    </div>

    <div formGroupName="address" class="subform">
      <div class="form-group big">
        <label for="streetName">{{ 'address' | translate }}</label>
        <input id="streetName" name="streetName" type="text" formControlName="streetName" />
        <div
          class="error"
          *ngIf="organizationDetailForm.controls.address.controls.streetName.errors?.['required']">
          {{ 'street-name-required-error' | translate }}
        </div>
      </div>

      <div class="form-group small">
        <label for="address-number">{{ 'address-number' | translate }}</label>
        <input
          id="address-number"
          name="address-number"
          class="input"
          type="text"
          formControlName="number" />
        <div
          class="error"
          *ngIf="organizationDetailForm.controls.address.controls.number.errors?.['required']">
          {{ 'address-number-required-error' | translate }}
        </div>
      </div>

      <div class="form-group half">
        <label for="postalCode" type="text">{{ 'postal-code' | translate }}</label>
        <input id="postalCode" name="postalCode" type="text" formControlName="postalCode" />
        <div
          class="error"
          *ngIf="organizationDetailForm.controls.address.controls.postalCode.errors?.['required']">
          {{ 'postal-code-required-error' | translate }}
        </div>
      </div>

      <div class="form-group half">
        <label for="city">{{ 'city' | translate }}</label>
        <input id="city" name="city" type="text" formControlName="city" />
        <div
          class="error"
          *ngIf="organizationDetailForm.controls.address.controls.city.errors?.['required']">
          {{ 'city-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="state">{{ 'state' | translate }}</label>
        <input id="state" name="state" type="text" formControlName="state" />
        <div
          class="error"
          *ngIf="organizationDetailForm.controls.address.controls.state.errors?.['required']">
          {{ 'state-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="country">{{ 'country' | translate }}</label>
        <input id="country" name="country" type="text" formControlName="country" />
        <div
          class="error"
          *ngIf="organizationDetailForm.controls.address.controls.country.errors?.['required']">
          {{ 'country-required-error' | translate }}
        </div>
      </div>
    </div>

    <button
      type="submit"
      class="button-primary button--has-icon"
      [disabled]="isSaving"
      *ngIf="canUpdate">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'organization-save' | translate }}
    </button>

    <button *ngIf="canDelete" (click)="organizationToRemovePopup()" class="button-remove">
      {{ 'organization-remove' | translate }}
    </button>
  </form>
</div>

<app-confirmation-dialog
  [title]="'organization-delete-title' | translate"
  [description]="'organization-delete-description' | translate"
  [icon]="'bin'"
  [confirmText]="'organization-delete-confirm' | translate"
  (confirmOutput)="removeOrganization()"></app-confirmation-dialog>
