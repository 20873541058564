/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SectionDtoItemsInner } from './sectionDtoItemsInner';
import { SectionItemType } from './sectionItemType';
import { SectionTypeDto } from './sectionTypeDto';
import { PermissionTypeDto } from './permissionTypeDto';


export interface SectionDto { 
    id: string;
    name: string;
    description: string;
    creationTimestampUtc: Date;
    items?: Array<SectionDtoItemsInner> | null;
    sectionType: SectionTypeDto;
    permissionType?: PermissionTypeDto;
    type: SectionItemType;
    workspaceId: string;
    parentId?: string | null;
    metadata?: any | null;
    copiedFrom?: string | null;
}
export namespace SectionDto {
}


