<div class="breadcrumb">
  <a [routerLink]="['/']" class="home">
    <span class="material-symbols-rounded">home</span>
  </a>

  <button
    *ngFor="let treeItem of tree"
    class="link"
    (click)="changeRouteSection(treeItem.link, treeItem.queryParams)">
    <span *ngIf="treeItem.translate">
      {{ treeItem.translate | translate }}
    </span>
    <span *ngIf="treeItem.name">
      {{ treeItem.name | translate }}
    </span>
  </button>
</div>
