import { Component } from '@angular/core';
import { AccountService, OrganizationDto, OrganizationService } from '@api-clients/user';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  templateUrl: './organization-overview.component.html',
  styleUrls: ['./organization-overview.component.scss'],
})
export class OrganizationOverviewComponent {
  //TO FIX eventually this needs to be done at the backend so getting only the filtered organizations instead of getting all organizations and filtering them here
  organizations: OrganizationDto[] = [];
  filteredOrganizations: OrganizationDto[] = [];
  defaultPageSize: number = 2;
  sortBy: string = '';
  sortOrder: number = 1; // 1 = ascending, -1 = descending
  searchText: string = '';
  selectedOrganization: string = '';

  breadcrumbTree = [
    {
      translate: 'organization-overview-page',
      link: '/organizations',
    },
  ];

  // column data is added in html through a loop.
  columns = [
    { key: 'name', label: 'name' },
    { key: 'email', label: 'email' },
    { key: 'chamberOfCommerce', label: 'chamber-of-commerce' },
    { key: 'invoiceAddress', label: 'invoice-address' },
    { key: '', label: 'remove' },
  ];

  constructor(
    private accountService: AccountService,
    private router: Router,
    private organizationService: OrganizationService,
    private userService: UserService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    organizationService.getAll().subscribe((organizations: OrganizationDto[]) => {
      this.organizations = organizations;
      this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
      this.updateOrganizations(1, this.defaultPageSize);
    });
  }

  organizationToRemovePopup(organizationId, event): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectedOrganization = organizationId;
    this.confirmationDialogService.open();
  }

  async removeOrganization(): Promise<void> {
    this.organizationService._delete(this.selectedOrganization).subscribe(() => {
      this.organizations = this.organizations.filter(
        (organization) => organization.id !== this.selectedOrganization
      );
      this.updateOrganizations(1, this.defaultPageSize);
    });
  }

  updateOrganizations(page, size, organizations = this.organizations): void {
    this.filteredOrganizations = organizations.slice((page - 1) * size, page * size);
  }

  onPageChange(event): void {
    this.updateOrganizations(event.page, event.pageSize);
  }

  createNewOrganization(): void {
    void this.router.navigate(['/organizations/', 'new']);
  }

  goToOrganizationDetail(organizationId: string): void {
    void this.router.navigate(['/organizations/', organizationId]);
  }

  toggleSort(property: string): void {
    if (this.sortBy === property) {
      this.sortOrder = -this.sortOrder;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property;
      this.sortOrder = 1;
    }

    this.organizations = this.organizations.slice().sort((a, b) => {
      // the OR case is to catch empty cells
      const nameA = (a?.[property] || '').toLocaleLowerCase();
      const nameB = (b?.[property] || '').toLocaleLowerCase();
      return this.sortOrder === 1 ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    this.search();
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.filteredOrganizations = this.organizations.filter((organization) => {
        return (
          organization.name?.toLocaleLowerCase().match(searchValue) ||
          organization.emailAddress?.toLocaleLowerCase().match(searchValue) ||
          organization.chamberOfCommerce?.toLocaleLowerCase().match(searchValue) ||
          organization.invoiceAddress?.toString().toLocaleLowerCase().match(searchValue)
        );
      });
    } else {
      this.updateOrganizations(1, JSON.parse(localStorage.getItem('pageSize') || 'null') || 10);
    }
    this.updateOrganizations(
      1,
      JSON.parse(localStorage.getItem('pageSize') || 'null') || 10,
      this.filteredOrganizations
    );
  }
}
