<div class="page-slider" [class.active]="expanded" [class.menu-expanded]="menuExpanded">
  <button class="page-slider__toggle" (click)="toggle()">
    <span class="material-symbols-rounded">expand_content</span>
  </button>
  <ng-content></ng-content>
</div>

<div
  class="page-slider__underlay"
  [class.active]="expanded"
  (focus)="toggle()"
  (mouseover)="toggle()"></div>
