export * from './bimLinks.service';
import { BimLinksService } from './bimLinks.service';
export * from './constructionReports.service';
import { ConstructionReportsService } from './constructionReports.service';
export * from './dossiers.service';
import { DossiersService } from './dossiers.service';
export * from './events.service';
import { EventsService } from './events.service';
export * from './inspections.service';
import { InspectionsService } from './inspections.service';
export * from './locations.service';
import { LocationsService } from './locations.service';
export * from './notes.service';
import { NotesService } from './notes.service';
export * from './roomPlan.service';
import { RoomPlanService } from './roomPlan.service';
export const APIS = [BimLinksService, ConstructionReportsService, DossiersService, EventsService, InspectionsService, LocationsService, NotesService, RoomPlanService];
