/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Provincie } from './provincie';
import { StatusGemeente } from './statusGemeente';


/**
 * Een bronhouder is een gemeente of samenwerkingsverband van gemeenten en  heeft een code en naam net als een gemeente. 
 */
export interface Gemeente { 
    /**
     * Een officiële code die aan een gemeente is toegekend, ontleend is aan  tabel 33 en zoals bijgehouden in de LVBAG.  Dit gegeven is geen officieel BAG gegeven en wordt niet als kenmerk van  het BAG object Woonplaats object bijgehouden.  Het wordt als hulpgegeven meegeleverd. 
     */
    code: string;
    /**
     * Een officiële naam die aan een gemeente is toegekend.
     */
    naam: string;
    /**
     * De datum waarop een versie van een bronhouder geldig is in de werkelijkheid.  Dit tijdstip wordt vastgelegd in de beginGeldigheid. 
     */
    beginGeldigheid: string;
    /**
     * De datum waarop de periode van geldigheid van een versie van een bronhouder  eindigt.  Wanneer er geen tijdstip is ingevuld, dan is de versie nog geldig.  Dit tijdstip wordt vastgelegd in de eindGeldigheid. 
     */
    eindGeldigheid?: string;
    provincie: Provincie;
    status: StatusGemeente;
    /**
     * De identificatie van een voorkomen van een object.
     */
    versie: number;
    /**
     * Dit is het tijdstip (conform RFC 3339) waarop een versie van een object of een relatie is opgenomen in de registratie van de landelijke voorziening. De gegevens komen daarmee beschikbaar voor afnemers.
     */
    tijdstipRegistratieLV: string;
    /**
     * Dit is het tijdstip (conform RFC 3339) waarop een versie van een object of een relatie is beëindigd in de registratie van de landelijke voorziening.
     */
    tijdstipEindRegistratieLV?: string;
}
export namespace Gemeente {
}


