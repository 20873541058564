import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface InspectionStatus {
  active: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InspectionPopupService {
  public inspectionStatus: InspectionStatus = { active: false };
  public inspectionStatusChange: Subject<InspectionStatus> = new Subject<InspectionStatus>();
  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  public shown: EventEmitter<boolean> = new EventEmitter<boolean>();
  public inspectionAdded = new EventEmitter();

  constructor() {}

  public hide(): void {
    this.inspectionStatus.active = false;
    this.hidden.emit(true);
  }

  public show(): void {
    this.inspectionStatus.active = true;
    this.shown.emit(true);
  }
}
