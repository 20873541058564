import { Injectable } from '@angular/core';
import { DefaultService as BimApi } from '@api-clients/bim';
import { ChangedElement, NewElement, UnsavedElement } from '../components/changes-summary/change';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { Property } from '../views/model-viewer/property';
import propertyDefinitions from '../views/model-viewer/properties.json';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DossierDetailService } from './dossier-detail.service';

@Injectable({
  providedIn: 'root',
})
export class BimPropertyService {
  private unsavedElements$ = new BehaviorSubject<UnsavedElement[]>([]);
  public unsavedElements = this.unsavedElements$.asObservable();

  public changeCount: Observable<number> = this.unsavedElements$.pipe(
    map((elements) =>
      elements.map((element) => element.getChangeCount()).reduce((a, b) => a + b, 0)
    )
  );

  private selectedElement$ = new BehaviorSubject<UnsavedElement | undefined>(undefined);

  public selectedElement = this.selectedElement$.asObservable();

  private bimId?: string;

  constructor(
    private readonly bimApi: BimApi,
    private readonly dossierDetailService: DossierDetailService
  ) {
    this.dossierDetailService.dossier.pipe(takeUntilDestroyed()).subscribe((dossier) => {
      this.bimId = dossier.linked_bim_ids.at(0);
    });
  }

  public selectBimElement(elementId: string | undefined): void {
    if (!this.bimId) return;
    if (!elementId) {
      this.selectedElement$.next(undefined);
      return;
    }
    const unsavedElement = this.unsavedElements$.value.find((element) => element.id === elementId);
    if (unsavedElement) {
      this.selectedElement$.next(unsavedElement.deepCopy());
    } else {
      this.bimApi.bimBimIdElementsElementIdGet(this.bimId, elementId).subscribe((elementDto) => {
        const propertyDefinition: Map<string, Property> | undefined =
          propertyDefinitions[elementDto.element['category']]?.properties;
        this.selectedElement$.next(
          new ChangedElement(
            elementDto.id,
            elementDto.element,
            new Map(),
            undefined,
            propertyDefinition
          )
        );
      });
    }
  }

  public updateChangedElement(changedElement: UnsavedElement): void {
    const newChanges = this.unsavedElements$.value;
    const existing = newChanges.find((e) => e.id === changedElement.id);
    if (existing) {
      this.unsavedElements$.next(
        newChanges.map((element) => (element.id === changedElement.id ? changedElement : element))
      );
    } else {
      this.unsavedElements$.next([...newChanges, changedElement]);
    }
  }

  // Todo: go to observable based
  public async submitChanges(): Promise<void> {
    if (!this.bimId) return;
    // Todo: changes made by someone else after staging are now discarded. Make this robust.
    await Promise.all(
      this.unsavedElements$.value.map((changedElement) => {
        if (changedElement instanceof NewElement) {
          lastValueFrom(this.bimApi.bimBimIdElementsPost(this.bimId!, changedElement.element));
        } else if (changedElement instanceof ChangedElement) {
          lastValueFrom(
            this.bimApi.bimBimIdElementsElementIdPut(
              this.bimId!,
              changedElement.id,
              changedElement.element
            )
          );
        }
      })
    );
    this.unsavedElements$.next([]);
  }

  public removeAddedElement(element: NewElement): void {
    this.unsavedElements$.next(this.unsavedElements$.value.filter((e) => e.id !== element.id));
  }

  public deleteChanges(): void {
    this.unsavedElements$.next([]);
  }
}
