/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Claim } from './claim';


export interface ClaimsIdentity { 
    readonly authenticationType?: string | null;
    readonly isAuthenticated: boolean;
    actor?: ClaimsIdentity;
    bootstrapContext?: any | null;
    readonly claims: Array<Claim>;
    readonly name?: string | null;
    readonly nameClaimType: string;
    readonly roleClaimType: string;
}

