import { ItemShareDto, PermissionTypeDto } from '@api-clients/workspace';
import { WorkspaceService } from '../services/workspace.service';

export interface CreateShareRequest {
  personalMessage: string;
  workspaceId: string;
  email?: string;
  name: string;
  permissionType: PermissionTypeDto;
  relationId?: string;
  organizationId?: string;
  userId?: string;
  membershipId?: string;
  isDefaultShare?: boolean;
  sectionItemId?: string;
}

export class ShareUpdateRequest {
  permissionType?: PermissionTypeDto;
  isDefaultShare?: boolean;
}

// export interface IItemShareDto {
//   id?: string;
//   name?: string;
//   permissionType?: PermissionTypeDto;
//   email?: string;
//   relationId?: string;
//   organizationId?: string;
//   userId?: string;
//   membershipId?: string;
//   isDefaultShare?: boolean;
//   sectionItemId?: string;
// }

// export interface IItemShare extends IItemShareDto {
//   user?: IUserAccount;
//   organization?: Organization;
//   personalMessage?: string;
// }

export class ItemShare implements ItemShareDto {
  id!: string;
  workspaceId?: string;
  sectionItemId?: string;
  creatorId!: string;
  relationId?: string;
  userId?: string;
  membershipId?: string;
  contactId?: string;
  email?: string;
  organizationId?: string;
  creationTimestamp!: Date;
  creationTimestampUtc!: Date;
  lastModifiedTimestampUtc!: Date;
  permissionType!: PermissionTypeDto;
  lastVisitUtc!: Date;
  isDefaultShare = false;
  isLink = false;
  personalMessage?: string;
  _name?: string;

  // user?: IUserAccount;
  // organization?: Organization;
  private workspaceService: WorkspaceService;

  constructor(share: ItemShareDto, workspaceService: WorkspaceService) {
    // const { name, ...rest } = share;
    Object.assign(this, share);
    // this._name = name ?? undefined;
    this.workspaceService = workspaceService;
  }

  // get avatar(): string {
  //   return this.user?.avatar ?? this.organization?.logoUrl;
  // }

  // get avatarColor(): string {
  //   return this.user?.avatarColor ?? this.organization?.primaryColor;
  // }

  // get loaded(): boolean {
  //   return (!this.userId && !this.organizationId) || !!this.user || !!this.organization;
  // }

  // get name(): string | undefined {
  //   return /*this.user?.fullName ?? this.organization?.name ??*/ this._name;
  // }

  // set name(value: string) {
  //   this._name = value;
  // }
}

// export class ItemShareUpdateRequest {
//   permissionType: ItemPermissionType;
// }

// export class ItemShareCreateRequest {
//   workspaceId: string;
//   userId: string;
//   email: string;
//   membershipId: string;
//   organizationId: string;
//   personalMessage: string;
//   permissionType: number;
//   relationId: string;
//   contactId: string;
// }
