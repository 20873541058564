import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BlobReader, BlobWriter, Entry, ZipReader } from '@zip.js/zip.js';
import { DefaultService as BimApi, Pose } from '@api-clients/bim';
import Dexie from 'dexie';
import { BuildingModel } from './assets/building-model';
import { UserService } from '@services/user.service';
import { Group } from 'three';

@Injectable({
  providedIn: 'root',
})
export class ModelLoaderService {
  private gltfLoader: GLTFLoader = new GLTFLoader();
  private db: Dexie;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private bimApi: BimApi
  ) {
    this.db = new Dexie('models');
    this.db.version(1).stores({
      models: 'id, model',
    });
  }

  async loadCompositeModelWithMetadata(bimId: string): Promise<BuildingModel | undefined> {
    const BimDetailed = await lastValueFrom(this.bimApi.bimBimIdDetailedModelViewGet(bimId));

    const gltf = await this.loadModel(BimDetailed.model_3d_url);
    if (!gltf) {
      console.error('Failed to load model.');
      return;
    }

    return new BuildingModel(
      gltf.scene.children[0] as Group,
      BimDetailed,
      gltf.scene.userData['batch_table']
    );
  }

  async loadFusedModelForBuilding(bimId: string): Promise<[GLTF, Pose?] | undefined> {
    // Get link to fused model
    const fusedModels = await lastValueFrom(this.bimApi.bimFused3dModelsGet([bimId]));
    const model = fusedModels[0];
    if (!model) return;
    const zippedModel = await this.getPotentiallyCachedModel(model.model_3d_url);
    if (!zippedModel) throw new Error('Failed to load model.');

    const unzipped = await this.unzipBlob(zippedModel).catch(() => zippedModel);
    if (!unzipped) throw new Error('Failed to unzip model.');

    const gltf = await this.gltfLoader.parseAsync(await unzipped.arrayBuffer(), '');
    return [gltf, model.pose];
  }

  async loadModel(url: string): Promise<GLTF | undefined> {
    const zipped = await this.getPotentiallyCachedModel(url);
    if (!zipped) {
      console.error('Failed to load model.');
      return;
    }
    const unzipped = await this.unzipBlob(zipped).catch(() => zipped);
    if (!unzipped) {
      console.error('Failed to unzip model.');
      return;
    }
    return await this.gltfLoader.parseAsync(await unzipped.arrayBuffer(), '');
  }

  async loadModelFromFile(file: Blob): Promise<GLTF | undefined> {
    return await this.gltfLoader.parseAsync(await file.arrayBuffer(), '');
  }

  private async getPotentiallyCachedModel(url: string): Promise<Blob | undefined> {
    // Get model checksum
    const headRequest = this.httpClient.head(url, { observe: 'response' });
    const headResponse = await lastValueFrom(headRequest);
    const fileChecksum = headResponse.headers.get('x-amz-checksum-crc32c');
    if (!fileChecksum) {
      console.error('Failed to get file checksum.');
      return;
    }
    // Check if model is already cached
    const cachedModel = await this.db['models'].get(fileChecksum);
    if (cachedModel) {
      return cachedModel.model;
    }
    // If not cached, download it
    const getResponse = await lastValueFrom(
      this.httpClient.get(url, { observe: 'response', responseType: 'blob' })
    );
    if (!getResponse.body) {
      console.error('Failed to load model.');
      return;
    }
    // Put model in cache
    await this.db['models'].put({ id: fileChecksum, model: getResponse.body });
    return getResponse.body;
  }

  private async unzipBlob(blob: Blob): Promise<Blob | undefined> {
    const reader = new BlobReader(blob);
    const zipReader = new ZipReader(reader);
    const entries: Entry[] = await zipReader.getEntries();
    if (entries.length !== 1) {
      console.error('ZIP archive should contain a single file. It contains ' + entries.length);
      return;
    }

    return await entries[0].getData!(new BlobWriter());
  }
}
