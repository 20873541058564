<app-search-bar (addressSelected$)="selectPand($event.id)"></app-search-bar>
<app-sidebar>
  <app-building-info [newSelectedBuilding]="selectedBuilding$"></app-building-info>
</app-sidebar>

<map-2d [map3dLayer]="map3dLayer"></map-2d>

<map-3d-layer
  #map3dLayer
  [ownedBuildings$]="buildingOverviewService.ownedBuildings$"
  [selectedBuilding$]="selectedBuilding$"></map-3d-layer>
