import { Component, Input } from '@angular/core';
import { InspectionPopupService, InspectionStatus } from './inspection-popup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Inspection, InspectionsService, InspectionTemplateList } from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-popup',
  templateUrl: './inspection-popup.component.html',
  styleUrl: './inspection-popup.component.scss',
})
export class InspectionPopupComponent {
  protected inspectionStatus: InspectionStatus;
  protected inspectionTemplateList: InspectionTemplateList[] = [];
  protected pageIndex: number = 0;
  protected selectedTemplateId?: string;
  protected inspection?: Inspection;

  @Input() dossierId?: string;

  constructor(
    private readonly inspectionPopupService: InspectionPopupService,
    private readonly inspectionsService: InspectionsService
  ) {
    this.inspectionStatus = inspectionPopupService.inspectionStatus;
    inspectionPopupService.inspectionStatusChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.inspectionStatus = value;
    });
    inspectionPopupService.shown.subscribe(() => {
      this.inspectionsService.templateGet().subscribe((next) => {
        this.pageIndex = 0;
        this.inspectionTemplateList = next;
        this.selectedTemplateId = next[0].id; // TODO: remove
      });
    });
  }

  async submit(): Promise<void> {
    if (
      this.selectedTemplateId === undefined ||
      this.dossierId === undefined ||
      this.inspection === undefined
    )
      return;
    this.inspectionsService
      .dossiersDossierIdInspectionPut(this.dossierId, this.inspection)
      .subscribe((next) => {
        this.inspection = next;
      });
    this.inspectionPopupService.hide();
  }

  close(): void {
    this.inspectionPopupService.hide();
  }

  next(): void {
    if (this.selectedTemplateId === undefined || this.dossierId === undefined) return;
    this.pageIndex = 1;
    this.inspectionsService
      .dossiersDossierIdStartInspectionInspectionTemplateIdPost(
        this.dossierId,
        this.selectedTemplateId
      )
      .subscribe((next: Inspection) => {
        this.inspection = next;
        this.inspectionPopupService.inspectionAdded.emit(true);
      });
  }
}
