/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RequestContentItemMessageDto { 
    id: string;
    userId: string;
    message: string;
    timestamp: Date;
    requestContentItemId: string;
    creationTimestampUtc: Date;
    lastModifiedTimestampUtc: Date;
}

