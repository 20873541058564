<div class="inspection-popup" [class.active]="inspectionStatus.active">
  <div class="inspection-popup__header">
    <h4>{{ 'add-inspection' | translate }}</h4>
    <button class="close-button" (click)="close()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>
  <div class="inspection-popup__content">
    <ng-container *ngIf="pageIndex === 0">
      <div class="inspection-popup__content__container big-input">
        <label for="template">
          {{ 'choose-your-template' | translate }}
        </label>
        <select id="template" [(ngModel)]="selectedTemplateId">
          <option [value]="template.id" *ngFor="let template of inspectionTemplateList">{{template.description}}</option>
        </select>
      </div>
    </ng-container>

    <ng-container *ngIf="pageIndex === 1">
      <p>{{ 'Inspection' | translate }}</p>
      <ng-container *ngIf="inspection?.content">
        <app-inspection-section
          [edit]="false"
          [inspection]="inspection"
          [section]="section"
          *ngFor="let section of inspection!.content.sections"
        ></app-inspection-section>
      </ng-container>
    </ng-container>

    <div class="inspection-popup__content__buttons">
      <button (click)="close()" class="cancel-button">{{ 'cancel' | translate }}</button>
      <button *ngIf="pageIndex === 1" (click)="submit()" class="button-primary">{{ 'save-and-finish' | translate }}</button>
      <button *ngIf="pageIndex === 0" (click)="next()" class="button-primary" [disabled]="selectedTemplateId === undefined">{{ 'next' | translate }}</button>
    </div>
  </div>
</div>

<button
  class="inspection-popup__background"
  (click)="close()"
  [class.active]="inspectionStatus.active">&nbsp;</button>
