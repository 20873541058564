/**
 * s3wrapper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object representing an item stored in the Google Cloud Bucket
 */
export interface ItemReference { 
    /**
     * Bucket containing the Item
     */
    bucketName?: string | null;
    /**
     * Path to the Item in the defined bucket
     */
    path?: string | null;
    /**
     * This guid serves as the unique directory path filename within the bucket
     */
    id?: string | null;
    /**
     * Filename of the item as it was uploaded. This is stored as metadata within Google Storage
     */
    fileName?: string | null;
}

