/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MimeTypeDto = 'Unknown' | 'Pdf' | 'Image' | 'Doc' | 'Audio' | 'Video' | 'Ppt' | 'Zip';

export const MimeTypeDto = {
    Unknown: 'Unknown' as MimeTypeDto,
    Pdf: 'Pdf' as MimeTypeDto,
    Image: 'Image' as MimeTypeDto,
    Doc: 'Doc' as MimeTypeDto,
    Audio: 'Audio' as MimeTypeDto,
    Video: 'Video' as MimeTypeDto,
    Ppt: 'Ppt' as MimeTypeDto,
    Zip: 'Zip' as MimeTypeDto
};

