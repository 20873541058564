<div class="color-picker">
  <button
    class="color-half"
    type="button"
    [style.background]="primaryHalfColor"
    (click)="primaryColorPicker.click()">
    <span class="color-descriptor" [style.color]="primaryContrast">
      {{ 'primary-color' | translate }}
    </span>
    <input
      #primaryColorPicker
      class="color-input"
      type="color"
      [(ngModel)]="primaryHalfColor"
      (input)="updateContrastColors()" />
  </button>
  <button
    class="color-half"
    type="button"
    [style.background]="secondaryHalfColor"
    (click)="secondaryColorPicker.click()">
    <span class="color-descriptor" [style.color]="secondaryContrast">
      {{ 'secondary-color' | translate }}
    </span>
    <input
      #secondaryColorPicker
      class="color-input"
      type="color"
      [(ngModel)]="secondaryHalfColor"
      (input)="updateContrastColors()" />
  </button>
</div>
