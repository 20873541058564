import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { Map3DLayerComponent } from '@shared/components/viewer/map-3d/map-3d-layer';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingOverviewService } from '@services/building-overview.service';

export type LatLngZoom = { lat: number; lng: number; zoom: number };

const initialPose: LatLngZoom = { lat: 52.242789, lng: 6.849455, zoom: 16 }; // UT

@Component({
  selector: 'viewer',
  templateUrl: './viewer.component.html',
  styleUrl: './viewer.component.scss',
})
export class ViewerComponent implements OnInit {
  private currentPose: BehaviorSubject<LatLngZoom>;
  public currentPose$: Observable<LatLngZoom>;
  private selectedBuilding = new BehaviorSubject<BuildingOverviewEntry | undefined>(undefined);
  public selectedBuilding$: Observable<BuildingOverviewEntry | undefined> =
    this.selectedBuilding.asObservable();

  @ViewChild('map3dLayer')
  protected map3dLayer!: Map3DLayerComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected buildingOverviewService: BuildingOverviewService
  ) {
    const { lat, lng, zoom, cadastralId } = this.activatedRoute.snapshot.queryParams;
    this.currentPose = new BehaviorSubject({
      lat: lat || initialPose.lat,
      lng: lng || initialPose.lng,
      zoom: zoom || initialPose.zoom,
    });
    this.currentPose$ = this.currentPose.asObservable();

    if (cadastralId) {
      this.buildingOverviewService
        .getLatLonFromCadastralId(cadastralId)
        .subscribe(({ lng, lat }) => {
          this.currentPose.next({ lat, lng, zoom });
        });
      this.selectPand(cadastralId);
    }
  }

  ngOnInit(): void {
    this.buildingOverviewService.ownedBuildings$.subscribe((buildings) => {
      // Reselect the building if it is still in the list
      const selectedBuilding = buildings.find(
        (building) => building.external_id === this.selectedBuilding.value?.external_id
      );
      if (selectedBuilding) {
        this.selectedBuilding.next(selectedBuilding);
      }
    });
  }

  selectPand(cadastralId: string | undefined): void {
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { cadastralId: cadastralId || null },
      queryParamsHandling: 'merge',
    });

    if (!cadastralId) return;

    this.buildingOverviewService.getBuildingByCadId(cadastralId).subscribe((building) => {
      this.selectedBuilding.next(building);
    });
  }

  changeLatLngZoom(event: LatLngZoom): void {
    const { lat, lng, zoom } = event;
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        lat: lat.toFixed(6),
        lng: lng.toFixed(6),
        zoom: zoom.toFixed(1),
      },
      queryParamsHandling: 'merge',
    });
    this.currentPose.next(event);
  }
}
