/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdresInOnderzoek } from './adresInOnderzoek';
import { Geconstateerd } from './geconstateerd';


export interface AdresIO { 
    /**
     * Een naam die aan een openbare ruimte is toegekend in een daartoe  strekkend formeel gemeentelijk besluit. Het betreft hier de straatnaam. 
     */
    openbareRuimteNaam: string;
    /**
     * De officiële openbare ruimte naam als deze niet langer is dan 24 tekens of de volgens de NEN5825 verkorte naam van maximaal 24 tekens.
     */
    korteNaam?: string;
    /**
     * Een door of namens het gemeentebestuur ten aanzien van een adresseerbaar object toegekende nummering.
     */
    huisnummer: number;
    /**
     * Een door of namens het gemeentebestuur ten aanzien van een adresseerbaar object toegekende toevoeging aan een huisnummer in de vorm van een alfanumeriek teken.
     */
    huisletter?: string;
    /**
     * Een door of namens het gemeentebestuur ten aanzien van een adresseerbaar object toegekende nadere toevoeging aan een huisnummer of een combinatie van huisnummer en huisletter.
     */
    huisnummertoevoeging?: string;
    /**
     * De door PostNL vastgestelde code behorende bij een bepaalde combinatie van een straatnaam en een huisnummer.
     */
    postcode?: string;
    /**
     * De benaming van een door het gemeentebestuur aangewezen woonplaats.
     */
    woonplaatsNaam: string;
    /**
     * De unieke aanduiding van een nummeraanduiding.
     */
    nummeraanduidingIdentificatie: string;
    /**
     * De unieke aanduiding van een openbare ruimte.
     */
    openbareRuimteIdentificatie: string;
    /**
     * De unieke aanduiding van een woonplaats, zoals opgenomen in de landelijke  woonplaatsentabel. Een natuurlijk getal tussen 0001 en 9999.  
     */
    woonplaatsIdentificatie: string;
    /**
     * De unieke aanduiding van een adresseerbaar object (ligplaats, standplaats of verblijfsobject)  uit de BAG. 
     */
    adresseerbaarObjectIdentificatie?: string;
    /**
     * Identificatie(s) van het pand of de panden waar het verblijfsobject deel van uit maakt.
     */
    pandIdentificaties?: Array<string>;
    /**
     * Indicatie dat dit adres een nevenadres is van een adresseerbaar object. Het adres is een hoofdadres als deze indicatie niet wordt meegeleverd.
     */
    indicatieNevenadres?: boolean;
    /**
     * Adresregel 5 zoals beschreven in NEN 5825:2002 paragraaf 7.2. Bevat:  - straatnaam en indien aanwezig huisnummer en huisnummertoevoeging,    waarbij huisnummertoevoeging een huisletter, huisnummertoevoeging of een combinatie van beide kan bevatten of - straatnaam en woonboot- of woonwagenverwijziging of - de aanduiding \"Postbus\" en het postbusnummer of - de aanduiding \"Antwoordnummer\" en het antwoordnummer of - de aanduiding \"PostApart\" en het PostApartnummer 
     */
    adresregel5?: string;
    /**
     * Adresregel 6 zoals beschreven in NEN 5825:2002 paragraaf 7.2. Bevat:   postcode en woonplaats 
     */
    adresregel6?: string;
    geconstateerd?: Geconstateerd;
    inonderzoek?: AdresInOnderzoek;
}

