export class LinePiece {
  public p1: number;
  public p2: number;
  public length: number;

  constructor(p1: number, p2: number) {
    this.p1 = p1;
    this.p2 = p2;
    this.length = p2 - p1;
  }

  public intersect(other: LinePiece): boolean {
    return (
      (this.p1 < other.p1 && this.p2 > other.p1) ||
      (this.p1 < other.p2 && this.p2 > other.p2) ||
      (other.p1 < this.p1 && other.p2 > this.p1) ||
      (other.p1 < this.p2 && other.p2 > this.p2)
    );
  }
}
