/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InspectionTemplateProperty } from './inspectionTemplateProperty';
import { InspectionTemplateSubject } from './inspectionTemplateSubject';
import { InspectionValue } from './inspectionValue';


export interface InspectionFinding { 
    description: string;
    subject: InspectionTemplateSubject;
    property: InspectionTemplateProperty;
    value?: InspectionValue;
}

