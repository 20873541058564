import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public progress = new BehaviorSubject<ProgressEvent>(new ProgressEvent());
}

export class ProgressEvent {
  percentage?: number;

  constructor(percentage?: number) {
    this.percentage = percentage;
  }

  get isActive(): boolean {
    return this.percentage !== undefined;
  }
}
