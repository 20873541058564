import { Component, Input } from '@angular/core';
import { NotePopupService } from './note-popup.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-note-popup',
  templateUrl: './note-popup.component.html',
  styleUrls: ['./note-popup.component.scss'],
})
export class NotePopupComponent {
  protected notePopupStatus;
  @Input() buildingId?: string;

  constructor(
    private readonly notePopupService: NotePopupService,
    protected readonly translateService: TranslateService
  ) {
    this.notePopupStatus = notePopupService.notePopupStatus;
    notePopupService.noteStatusChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.notePopupStatus = value;
    });
  }

  close(): void {
    this.notePopupService.hide();
  }
}
