<div class="inspection-template-detail">
  <div class="inspection-template-detail__container">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

    <div class="intro">
      <h1 *ngIf="!edit">{{ this.inspectionTemplate.description }}</h1>

      <input
        class="h1 title-input"
        type="text"
        name="description"
        autocomplete="off"
        [(ngModel)]="this.inspectionTemplate!.description"
        placeholder="{{ 'dossier-module.inspection-template-title' | translate }}"
        *ngIf="edit" />

      <app-lightswitch
        [label]="'view-preview' | translate"
        [checked]="!edit"
        (changedState)="changeSwitch($event)"></app-lightswitch>

      <button (click)="save()" *ngIf="edit" [disabled]="isSaving" class="button-primary">
        <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
        {{ 'save' | translate }}
      </button>
    </div>
    <div cdkDropList cdkDropListGroup (cdkDropListDropped)="itemDropped($event)" class="item-list">
      <app-inspection-section
        *ngFor="let section of inspectionTemplate.definition.sections; let i = index"
        (click)="activateSection(section)"
        [section]="section"
        [edit]="edit"
        [disasbled]="!edit"
        [sectionIndex]="i"
        (deleteSection)="deleteSection(section)"></app-inspection-section>
    </div>

    <app-inspection-toolbox
      [edit]="edit"
      [inspectionTemplate]="inspectionTemplate"
      [currentSection]="currentSection"></app-inspection-toolbox>
  </div>
</div>
