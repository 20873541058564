/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type PermissionType = 'Create' | 'Edit' | 'Delete';

export const PermissionType = {
    Create: 'Create' as PermissionType,
    Edit: 'Edit' as PermissionType,
    Delete: 'Delete' as PermissionType
};

