/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateUserRequestHonorific } from './createUserRequestHonorific';
import { CreateUserRequestAddress } from './createUserRequestAddress';
import { UpdateUserRequestOrganizationRole } from './updateUserRequestOrganizationRole';


export interface UpdateUserRequest { 
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    organizationRole?: UpdateUserRequestOrganizationRole | null;
    isActive?: boolean | null;
    address?: CreateUserRequestAddress | null;
    languageCode?: string | null;
    middleName?: string | null;
    honorific?: CreateUserRequestHonorific | null;
    jobTitle?: string | null;
    phoneNumber?: string | null;
}

