/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateUserRequestAddress } from './createUserRequestAddress';


export interface CreateOrganizationRequest { 
    name?: string;
    phoneNumber?: string | null;
    emailAddress?: string | null;
    address?: CreateUserRequestAddress | null;
    chamberOfCommerce?: string | null;
    websiteUrl?: string | null;
    invoiceAddress?: CreateUserRequestAddress | null;
    financialEmailAddress?: string | null;
    financialEmailBccAddress?: string | null;
    vatId?: string | null;
    preferredCurrency?: string | null;
}

