<div class="legend">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <!-- use a disabled button to provide padding for tree leaf -->
      <mat-checkbox
        (change)="_toggleLayer(node)"
        [checked]="checklistSelection.isSelected(node)"
        class="mat-checkbox">
        {{ node.id | translate }}
      </mat-checkbox>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" class="node" matTreeNodePadding>
      <button [attr.aria-label]="'Toggle ' + node.id" mat-icon-button matTreeNodeToggle>
        <mat-icon class="mat-symbol-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>
      <mat-checkbox
        (change)="todoItemSelectionToggle(node)"
        [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        class="node-checkbox">
        {{ node.id | translate }}
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>
