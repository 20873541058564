/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SectionTypeDto = 'Default' | 'ContainsOnlySections' | 'AdditionalFiles';

export const SectionTypeDto = {
    Default: 'Default' as SectionTypeDto,
    ContainsOnlySections: 'ContainsOnlySections' as SectionTypeDto,
    AdditionalFiles: 'AdditionalFiles' as SectionTypeDto
};

