import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkspaceService } from '../../services/workspace.service';
import { Workspace } from '../../models';
import { WorkspaceDto } from '@api-clients/workspace';
import { ContextMenuItem } from '@services/context.service';
import { WorkspaceSelectDialogService } from '../../components/workspace-select-dialog/workspace-select-dialog.service';
import { InspectionPopupService } from '../../components/inspection-popup/inspection-popup.service';
import { DossierDetailService } from '../../../building-module/services/dossier-detail.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-inspection-overview',
  templateUrl: './inspection-overview.component.html',
  styleUrls: ['./inspection-overview.component.scss'],
})
export class InspectionOverviewComponent implements OnInit {
  buildingId: string | null = '';
  dossierId: string | null = '';
  workspaces: Workspace[] = [];
  filteredWorkspaces: Workspace[] = [];
  defaultPageSize: number = 10;
  sortBy: string = '';
  sortOrder: number = 1; // 1 = ascending, -1 = descending
  searchText: string = '';
  activeWorkspace: string = '';
  menu: ContextMenuItem[] = [];

  breadcrumbTree = [
    {
      translate: 'workspaces-overview-page',
      link: '/forms',
    },
  ];

  columns = [
    { key: 'id', label: 'id' },
    { key: 'name', label: 'name' },
    { key: '', label: 'coupled-building' },
    { key: 'status', label: 'status' },
    { key: '', label: 'actions' },
  ];

  constructor(
    private readonly workspaceService: WorkspaceService,
    private readonly router: Router,
    private readonly workspaceSelectDialogService: WorkspaceSelectDialogService,
    protected readonly inspectionPopupService: InspectionPopupService,
    protected readonly dossierDetailService: DossierDetailService
  ) {
    this.dossierDetailService.dossier.pipe(takeUntilDestroyed()).subscribe((dossier) => {
      this.buildingId = dossier.building_id;
      this.dossierId = dossier.id;
    });
  }

  async ngOnInit(): Promise<void> {
    this.buildMenu();
    this.workspaceService.listWorkspaces(false, false).then((workspaces) => {
      this.workspaces = workspaces;
      this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
      this.updateWorkspaces(1, this.defaultPageSize);
    });
  }

  showInspectionPopup(): void {
    this.inspectionPopupService.show();
  }

  scrollDown(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'Edit',
        icon: 'edit',
        action: () => this.navigateToWorkspace(this.activeWorkspace),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: () => this.deleteWorkspace(this.activeWorkspace),
      },
    ];
  }

  setActive(workspaceId: string): void {
    this.activeWorkspace = workspaceId;
    this.buildMenu();
  }

  updateWorkspaces(page, size, workspaces = this.workspaces): void {
    this.filteredWorkspaces = workspaces.slice((page - 1) * size, page * size);
  }

  onPageChange(event): void {
    this.updateWorkspaces(event.page, event.pageSize);
  }

  toggleSort(property: string): void {
    if (this.sortBy === property) {
      this.sortOrder = -this.sortOrder;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property;
      this.sortOrder = 1;
    }

    this.workspaces = this.workspaces.slice().sort((a: Workspace, b: Workspace) => {
      // the OR case is to catch empty cells
      const nameA = (a?.[property] || '').toLocaleLowerCase();
      const nameB = (b?.[property] || '').toLocaleLowerCase();
      return this.sortOrder === 1 ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    this.search();
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.filteredWorkspaces = this.workspaces.filter((workspace) => {
        return (
          workspace.name?.toLocaleLowerCase().match(searchValue) ||
          workspace.description?.toLocaleLowerCase().match(searchValue)
        );
      });
    } else {
      this.updateWorkspaces(1, JSON.parse(localStorage.getItem('pageSize') || 'null') || 10);
    }
    this.updateWorkspaces(
      1,
      JSON.parse(localStorage.getItem('pageSize') || 'null') || 10,
      this.filteredWorkspaces
    );
  }

  createNewPopup(): void {
    this.workspaceSelectDialogService.open();
  }

  createNewWorkspace(buildingId: string): void {
    const workspaceName = buildingId ? 'Form for building: ' + buildingId : 'New Workspace';
    this.workspaceService
      .createWorkspace({
        name: workspaceName,
        description: '',
        isTemplate: false,
      })
      .then((workspace: WorkspaceDto) => {
        if (workspace) {
          this.navigateToWorkspace(workspace.id);
        }
      });
  }

  deleteWorkspace(workspaceId): void {
    void this.workspaceService.deleteWorkspace(workspaceId);
  }

  navigateToWorkspace(workspaceId: string): void {
    //This eventually needs to be "go to form inside buildingId if it exists in the form, else go to form without buildingId"
    if (this.buildingId) {
      void this.router.navigate(['/buildings/' + this.buildingId + '/forms/' + workspaceId]);
    } else {
      void this.router.navigate(['/forms/' + workspaceId]);
    }
  }

  goToBuilding(): void {
    if (this.buildingId) {
      void this.router.navigate(['/buildings/' + this.buildingId]);
    } else {
      //DUMMY code this should never happen in the end product.
      //The end product should only have goToBuilding() if buildingId is not null on the form.
      console.error('No buildingId found');
      void this.router.navigate(['/buildings']);
    }
  }
}
