<div>
  <h1>Hallo</h1>
  <h2>Hallo</h2>
  <h3>Hallo</h3>
  <h4>Hallo</h4>
  <h5>Hallo</h5>
  <h6>Hallo</h6>

  <p>
    Lorem
    <a href="#">ipsum</a>
    dolor sit amet, consectetur adipisicing elit. Aliquam eius quas ut. Amet deleniti dolor dolore
    perspiciatis quas quisquam sunt suscipit. Asperiores cupiditate ducimus modi nemo quis,
    recusandae rem ut?
  </p>
  <p class="p-small">
    Lorem
    <a href="#">ipsum</a>
    dolor sit amet, consectetur adipisicing elit. Atque, cupiditate dignissimos facere fugit ipsa
    laboriosam mollitia pariatur porro quidem unde. Aperiam assumenda consequatur eaque esse itaque
    officiis omnis quam quo!
  </p>
  <p class="p-smaller">
    Lorem
    <a href="#">ipsum</a>
    dolor sit amet, consectetur adipisicing elit. Eligendi fuga odit suscipit. Adipisci at iure
    officia quam repellat. Doloremque enim id labore laboriosam maxime nisi perferendis quibusdam
    quidem repellendus vel?
  </p>
  <p class="bold">
    Lorem
    <a href="#">ipsum</a>
    dolor sit amet, consectetur adipisicing elit. Accusantium atque autem doloribus laudantium
    minima, necessitatibus nisi nulla odio officia perferendis perspiciatis, quis quo saepe sed sint
    sunt tempora veritatis voluptatibus!
  </p>
</div>

<button onclick="window.location.href='speckle.com';">Open in Speckle</button>
<button class="button-primary" onclick="window.location.href='speckle.com';">
  Open in Speckle
</button>

<button class="button-secondary" onclick="window.location.href='speckle.com';">
  Open in Speckle
</button>

<table>
  <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
  </tr>
  <tr>
    <td>Ernst Handel</td>
    <td>Roland Mendel</td>
    <td>Austria</td>
  </tr>
  <tr>
    <td>Island Trading</td>
    <td>Helen Bennett</td>
    <td>UK</td>
  </tr>
  <tr>
    <td>Laughing Bacchus Winecellars</td>
    <td>Yoshi Tannamuri</td>
    <td>Canada</td>
  </tr>
  <tr>
    <td>Magazzini Alimentari Riuniti</td>
    <td>Giovanni Rovelli</td>
    <td>Italy</td>
  </tr>
</table>

<table class="table responsive-table">
  <thead>
    <tr>
      <th>Company</th>
      <th>Contact</th>
      <th>Country</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-title="Company">Alfreds Futterkiste</td>
      <td data-title="Contact">Maria Anders</td>
      <td data-title="Country">Germany</td>
    </tr>
    <tr>
      <td data-title="Company">Centro comercial Moctezuma</td>
      <td data-title="Contact">Francisco Chang</td>
      <td data-title="Country">Mexico</td>
    </tr>
    <tr>
      <td data-title="Company">Ernst Handel</td>
      <td data-title="Contact">Roland Mendel</td>
      <td data-title="Country">Austria</td>
    </tr>
    <tr>
      <td data-title="Company">Island Trading</td>
      <td data-title="Contact">Helen Bennett</td>
      <td data-title="Country">UK</td>
    </tr>
    <tr>
      <td data-title="Company">Laughing Bacchus Winecellars</td>
      <td data-title="Contact">Yoshi Tannamuri</td>
      <td data-title="Country">Canada</td>
    </tr>
    <tr>
      <td data-title="Company">Magazzini Alimentari Riuniti</td>
      <td data-title="Contact">Giovanni Rovelli</td>
      <td data-title="Country">Italy</td>
    </tr>
  </tbody>
</table>

<div class="color-blocks">
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
