import { ItemStatusTypeDto, PermissionTypeDto, SectionItemType } from '@api-clients/workspace';
import { ContentItem, INamed } from './content.item.model';
import { RequestContentItem } from './filerequest.model';
import { Question } from './question.model';
import { Section } from './section.model';

export interface ISectionItem extends ISectionItemDto {
  selected?: boolean;
  hoverActive?: boolean;
  focused: boolean;
  isOwner: boolean;
  isVisible: boolean;
  sectionId: string;

  insertSectionItem(sectionItem: SectionItem, index: number): void;
}

export interface ISectionItemDto extends INamed {
  id: string;
  type: SectionItemType;
  workspaceId: string;
  parentId?: string;
  name: string;
  description?: string;
  permissionType: PermissionTypeDto;
  status?: ItemStatusTypeDto;
  copiedFrom?: string;
}

export type SectionItem = Section | RequestContentItem | Question | ContentItem;

export abstract class SectionItemBase implements ISectionItemDto {
  id!: string;
  type!: SectionItemType;
  workspaceId!: string;
  parentId?: string;
  abstract name: string;
  description!: string;
  permissionType!: PermissionTypeDto;
  status!: ItemStatusTypeDto;
  copiedFrom?: string;
  selected?: boolean;
  hoverActive?: boolean = false;
  focused = false;
  abstract isVisible: boolean;

  get isOwner(): boolean {
    return this.permissionType === PermissionTypeDto.Owner;
  }

  get canEdit(): boolean {
    return (
      this.permissionType === PermissionTypeDto.ReadWrite ||
      this.permissionType === PermissionTypeDto.Owner
    );
  }

  isSection(): this is Section {
    return this.type === SectionItemType.Section;
  }

  isQuestion(): this is Question {
    return this.type === SectionItemType.Question;
  }

  isFileRequest(): this is RequestContentItem {
    return this.type === SectionItemType.FileRequest;
  }

  isContentItem(): this is ContentItem {
    return this.type === SectionItemType.ContentItem;
  }
}
