/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkspaceSettingsDto } from './workspaceSettingsDto';
import { ItemStatusTypeDto } from './itemStatusTypeDto';


export interface WorkspaceUpdateRequest { 
    newName?: string | null;
    newDescription?: string | null;
    workspaceSettings: WorkspaceSettingsDto;
    workspaceStatus?: ItemStatusTypeDto;
}
export namespace WorkspaceUpdateRequest {
}


