import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent {
  @Input() total = 0;
  @Input() page = 1;
  @Input() pageSize = 10;
  @Output() pageChange = new EventEmitter<{ page: number; pageSize: number }>();

  pageSizeOptions = [10, 25, 50, 100];

  get totalPages(): number {
    return Math.ceil(this.total / this.pageSize);
  }

  get hasPrevious(): boolean {
    return this.page > 1;
  }

  get hasNext(): boolean {
    return this.page < this.totalPages;
  }

  get start(): number {
    return (this.page - 1) * this.pageSize + 1;
  }

  get end(): number {
    return Math.min(this.page * this.pageSize, this.total);
  }

  get isLastPage(): boolean {
    return this.page === this.totalPages;
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isSinglePage(): boolean {
    return this.totalPages === 1;
  }

  firstPage(): void {
    this.page = 1;
    this.updatePage();
  }

  lastPage(): void {
    this.page = this.totalPages;
    this.updatePage();
  }

  nextPage(): void {
    if (this.hasNext) {
      this.page++;
      this.updatePage();
    }
  }

  previousPage(): void {
    if (this.hasPrevious) {
      this.page--;
      this.updatePage();
    }
  }

  changePageSize(event): void {
    this.pageSize = event.target.value;
    localStorage.setItem('pageSize', JSON.stringify(this.pageSize));
    this.page = 1;
    this.updatePage();
  }

  updatePage(): void {
    this.pageChange.emit({ page: this.page, pageSize: this.pageSize });
  }
}
