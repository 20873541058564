import {
  BimElement,
  BimElementBimLevelDto,
  BimElementBimObjectDto,
  BimElementBimRoomDto,
} from '@api-clients/bim';

export function isBimObject(bimElement: BimElement): bimElement is BimElementBimObjectDto {
  return bimElement.type === BimElementBimObjectDto.TypeEnum.Object;
}

export function isBimRoom(bimElement: BimElement): bimElement is BimElementBimRoomDto {
  return bimElement.type === BimElementBimRoomDto.TypeEnum.Room;
}

export function isBimLevel(bimElement: BimElement): bimElement is BimElementBimLevelDto {
  return bimElement.type === BimElementBimLevelDto.TypeEnum.Level;
}
