<div class="floor-plan-viewer">
  <div class="big-spinner" *ngIf="(floorPlan.currentLevel < 0 || this.isSaving) && !floorPlan.hasError"></div>
  <div class="floor-plan-viewer-no-model" *ngIf="noLinkedBimAvailable">
    {{ 'no-floor-plan-available' | translate }}
  </div>

  <div class="sidebar">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

    <div class="big-input select-version" *ngIf="this.floorPlan.floorPlan">
      <select
        [(ngModel)]="this.floorPlan.currentLevel"
        (change)="selectBimId(undefined)"
        id="current_level_select">
        <option [ngValue]="index" *ngFor="let level of this.floorPlan.floorPlan.levelNames; index as index"
                [disabled]="!this.floorPlan.floorPlan.levels.at(index)">
          {{ level }}
        </option>
      </select>
      <div class="sidebar__content" [class.hide]="visibleMenu !== 'details'">
        <app-element-popup></app-element-popup>
      </div>
      <div class="sidebar__content" [class.hide]="visibleMenu !== 'changes'">
        <app-changes-summary></app-changes-summary>
      </div>
    </div>

  </div>

  <div class="floor-plan__container">
    <app-floor-plan
      #floorPlan
      [bimId]="bimId"
      [selectedElementId]="selectedElementId"
      (bimIdClicked)="selectBimId($event)"></app-floor-plan>

    <div class="button-group">
      <button
        (click)="downloadSvg()"
        class="button-secondary button--has-icon"
        *ngIf="floorPlan.currentLevel >= 0">
        <span class="material-symbols-rounded">download</span>
        {{ 'download' | translate }}
      </button>
      <button
        class="button-primary cta-button"
        *ngIf="visibleMenu !== 'changes'"
        [disabled]="shoppingCartCount === 0"
        [class.animate]="bounceShoppingCartButton"
        (click)="showShoppingCart()">
        ({{ shoppingCartCount }}) {{ 'changes' | translate }}
      </button>
      <button
        class="button-primary cta-button"
        *ngIf="visibleMenu === 'changes'"
        [disabled]="shoppingCartCount === 0 || isSaving"
        (click)="saveShoppingCart()">
        <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
        {{ 'save' | translate }}
      </button>
    </div>

    <div class="navigation-group" *ngIf="floorPlan.currentLevel >= 0">
      <button (click)="floorPlan.rotate()">
        <span class="material-symbols-rounded">rotate_right</span>
      </button>
      <button (click)="floorPlan.resetViewPort()">
        <span class="material-symbols-rounded">reset_image</span>
      </button>
      <button (click)="floorPlan.zoomOut()">
        <span class="material-symbols-rounded">zoom_out</span>
      </button>
      <button (click)="floorPlan.zoomIn()">
        <span class="material-symbols-rounded">zoom_in</span>
      </button>
    </div>
  </div>
</div>
