export interface Layer {
  id: string;
  type?: 'fill' | 'line';
  disabled?: boolean;
  subLayers?: string[];
  postGISColumn?: string;
  isMapLayer?: boolean;
}

const allLayers: Layer[] = [];

export const layers = allLayers.filter((layer) => !layer.disabled);
