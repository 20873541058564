<div
  class="timeline-event"
  *ngIf="item"
  [class.last]="lastItem"
  [class.small-version]="smallVersion"
  [class.has-preview]="item.item_type !== TimeLineType.Note">
  <div class="timeline-event__header">
    <div
      class="timeline-event__header__type {{ item.item_type | lowercase }}"
      [ngSwitch]="item.item_type">
      <span *ngSwitchCase="TimeLineType.Note" class="material-symbols-rounded">stylus_note</span>
      <span *ngSwitchCase="TimeLineType.BimLink" class="material-symbols-rounded">
        deployed_code
      </span>
      <span *ngSwitchCase="TimeLineType.ConstructionReport" class="material-symbols-rounded">
        handyman
      </span>
      <span *ngSwitchCase="TimeLineType.Inspection" class="material-symbols-rounded">
        data_table
      </span>
    </div>

    <div class="timeline-event__header__text" *ngIf="item?.item_type !== TimeLineType.BimLink">
      {{ item.userName }} {{ 'added-a' | translate }}
      <span class="light">
        {{ item.item_type | translate }}
      </span>
      {{ 'added-on' | translate }}
      <span class="light">
        {{
          item.timestamp_utc
            | date: 'dd MMMM yyyy' : '' : translateService.currentLang || 'Loading...'
        }}
      </span>
    </div>

    <div class="timeline-event__header__text" *ngIf="item?.item_type === TimeLineType.BimLink">
      {{ item.userName }} {{ 'uploaded-a' | translate }}
      <span class="light">
        {{ (buildingInfo?.source ?? BimSource.Manual).toString() | translate }}
      </span>
      {{ 'on' | translate }}
      <span class="light">
        {{
          item.timestamp_utc
            | date: 'dd MMMM yyyy' : '' : translateService.currentLang || 'Loading...'
        }}
      </span>
    </div>
  </div>

  <div class="timeline-event__content {{ item.item_type }}">
    <ng-container *ngIf="item?.item_type === TimeLineType.BimLink">
      {{ 'status' | translate }} :
      {{ (buildingInfo?.status ?? BimStatus.Error).toString() | translate }}
    </ng-container>
    <div [innerHTML]="!item ? 'Loading...' : item.description"></div>
    <div
      class="timeline-event__content__attachment"
      *ngIf="item?.attachments?.images?.length || item?.attachments?.documents?.length">
      <button
        *ngFor="let attachment of item?.attachments?.images"
        (click)="sendImage(attachment.id)">
        <span class="material-symbols-rounded">image</span>
        {{ attachment.file_name }}
      </button>

      <button
        *ngFor="let attachment of item?.attachments?.documents"
        (click)="openDocument(item.dossier_id, attachment.id)">
        <span class="material-symbols-outlined">save</span>
        {{ attachment.file_name }}
      </button>
    </div>
  </div>
</div>
