/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentItemDto } from './contentItemDto';
import { RequestContentItemMessageDto } from './requestContentItemMessageDto';
import { SectionItemType } from './sectionItemType';
import { ItemStatusTypeDto } from './itemStatusTypeDto';
import { PermissionTypeDto } from './permissionTypeDto';


export interface RequestContentItemDto { 
    id: string;
    description: string;
    name?: string | null;
    status: ItemStatusTypeDto;
    isRequired: boolean;
    allowedFileTypes: Array<string>;
    messages: Array<RequestContentItemMessageDto>;
    creationTimestampUtc: Date;
    lastModifiedTimestampUtc: Date;
    permissionType?: PermissionTypeDto;
    parentId?: string | null;
    type: SectionItemType;
    workspaceId: string;
    contentItems: Array<ContentItemDto>;
    metadata?: any | null;
    copiedFrom?: string | null;
}
export namespace RequestContentItemDto {
}


