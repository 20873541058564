/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionType } from './questionType';
import { ItemStatusTypeDto } from './itemStatusTypeDto';


export interface UpdateQuestionRequest { 
    label?: string | null;
    questionType?: QuestionType;
    properties?: any | null;
    value?: any | null;
    isRequired?: boolean | null;
    status?: ItemStatusTypeDto;
    parentSection?: string | null;
    insertAt?: number | null;
}
export namespace UpdateQuestionRequest {
}


