/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationRole } from './organizationRole';
import { UserDtoHonorific } from './userDtoHonorific';


export interface UserInfoDto { 
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    languageCode?: string;
    lastLoginUtc?: string | null;
    isActive?: boolean;
    /**
     * Gets or sets the middle name.             
     */
    middleName?: string | null;
    honorific?: UserDtoHonorific;
    /**
     * Gets or sets the job title.             
     */
    jobTitle?: string | null;
    /**
     * Gets or sets the phone-number of the user.             
     */
    phoneNumber?: string | null;
    organizationId?: string;
    organizationRole?: OrganizationRole;
}
export namespace UserInfoDto {
}


