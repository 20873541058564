import { Component, EventEmitter, Output } from '@angular/core';
import { WorkspaceSelectDialogService } from './workspace-select-dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { BuildingOverviewService, CadastralId } from '@services/building-overview.service';

@Component({
  selector: 'app-workspace-select-dialog',
  templateUrl: './workspace-select-dialog.component.html',
  styleUrl: './workspace-select-dialog.component.scss',
})
export class WorkspaceSelectComponent {
  @Output() selectedOutput = new EventEmitter();

  buildings: BuildingOverviewEntry[] = [];
  filteredBuildings: BuildingOverviewEntry[] = [];

  createFromWorkspace: CadastralId = '';
  searchText: string = '';
  noResults: boolean = false;
  active: boolean = false;

  constructor(
    private readonly workspaceSelectDialogService: WorkspaceSelectDialogService,
    private readonly buildingOverviewService: BuildingOverviewService
  ) {
    this.workspaceSelectDialogService.dialogStatus$
      .pipe(takeUntilDestroyed())
      .subscribe((status) => {
        this.active = status.active;
      });

    void this.getBuildings();
  }

  async getBuildings(): Promise<void> {
    this.buildingOverviewService.ownedBuildings$.subscribe((next) => {
      this.buildings = next;
      this.updateBuildings();
    });
    /*    const { buildings } = await lastValueFrom(
      this.buildingApi.ownedBuildingsGet(this.userService.organizationId)
    );
    this.buildings = buildings;*/

    this.updateBuildings();
  }

  selectWorkspace(id: CadastralId | undefined): void {
    this.createFromWorkspace = id === this.createFromWorkspace || id === undefined ? '' : id;
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.filteredBuildings = this.buildings.filter((building) => {
        return (
          building.buildingMetadata?.name?.toLocaleLowerCase().match(searchValue) ||
          building.buildingMetadata?.address?.toLocaleLowerCase().match(searchValue) ||
          building.buildingMetadata?.city?.toLocaleLowerCase().match(searchValue)
        );
      });
    } else {
      this.updateBuildings();
    }
    this.updateBuildings(this.filteredBuildings);
  }

  updateBuildings(buildings: BuildingOverviewEntry[] = this.buildings): void {
    this.filteredBuildings = buildings;
    this.noResults = this.filteredBuildings.length === 0;
  }

  confirm(buildingId: CadastralId): void {
    this.selectedOutput.emit(buildingId);
    this.workspaceSelectDialogService.close();
  }

  cancel(): void {
    this.workspaceSelectDialogService.close();
  }
}
