/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InspectionValueRadioListValue { 
    value: string;
    type: InspectionValueRadioListValue.TypeEnum;
}
export namespace InspectionValueRadioListValue {
    export type TypeEnum = 'RadioList';
    export const TypeEnum = {
        RadioList: 'RadioList' as TypeEnum
    };
}


