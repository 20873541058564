/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDtoAddress } from './userDtoAddress';
import { OrganizationRole } from './organizationRole';
import { UserDtoHonorific } from './userDtoHonorific';


export interface UserDto { 
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    isActive?: boolean;
    /**
     * Gets or sets the middle name.             
     */
    middleName?: string | null;
    honorific?: UserDtoHonorific;
    /**
     * Gets or sets the job title.             
     */
    jobTitle?: string | null;
    /**
     * Gets or sets the phone number of the user.             
     */
    phoneNumber?: string | null;
    languageCode?: string;
    lastLoginUtc?: string | null;
    address?: UserDtoAddress | null;
    /**
     * The identifier of the address instance (foreign key).             
     */
    addressId?: string | null;
    creationTimestampUtc?: string;
    lastModifiedTimestampUtc?: string;
    organizationId?: string;
    organizationRole?: OrganizationRole;
}
export namespace UserDto {
}


