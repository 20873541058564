import { IUniform, Shader, ShaderLib, ShaderMaterial, UniformsUtils } from 'three';
import { Shaders } from '../assets/shader';

export class Bag3DShader implements Shader {
  uniforms: { [uniform: string]: IUniform };
  vertexShader = new Shaders().vertexShader;
  fragmentShader = new Shaders().fragmentShader;
  extensions = { derivatives: true };
  lights = true;

  constructor() {
    this.uniforms = {
      ...UniformsUtils.clone(ShaderLib.lambert.uniforms),
    };
  }
}

export class Bag3DShaderMaterial extends ShaderMaterial {
  constructor() {
    super(new Bag3DShader());
    this.transparent = true;
  }
}
