import { Component, Input } from '@angular/core';
import {
  DossierDocument,
  DossierFile,
  DossierImage,
  Inspection,
  InspectionsService,
  TimeLineDto,
  TimeLineType,
} from '@api-clients/dossier';
import { EnrichedTimeLineDto } from '../EnrichedTimeLineDto';
import {
  isBooleanAnswer,
  isCheckListAnswer,
  isImagesAnswer,
  isNumberAnswer,
  isRadioListAnswer,
  isTextAnswer,
} from '../../../utils';
import { environment } from '@env/environment';
import { DossierService } from '@services/dossier.service';

@Component({
  selector: 'app-inspection-result',
  templateUrl: './inspection-result.component.html',
  styleUrl: './inspection-result.component.scss',
})
export class InspectionResultComponent {
  private _item: EnrichedTimeLineDto = {} as EnrichedTimeLineDto;
  protected inspection: Inspection | undefined = undefined;
  protected selectedFile: DossierImage | DossierFile | DossierDocument | undefined;
  protected selectedFileUrl: string | undefined;

  @Input() user: string | undefined = '';
  @Input() timeStamp: string | undefined = '';
  @Input()
  set item(value: EnrichedTimeLineDto) {
    this._item = value;
    // Then get the inspection result for this timeline item
    this.inspectionsService
      .dossiersDossierIdInspectionIdGet(this._item.dossier_id, this._item.item_id)
      .subscribe((inspection) => {
        this.inspection = inspection;
      });
  }

  constructor(
    private readonly inspectionsService: InspectionsService,
    private readonly dossierService: DossierService
  ) {}

  get item(): EnrichedTimeLineDto {
    return this._item;
  }

  protected async openImage(item: TimeLineDto, image_id: string): Promise<void> {
    this.selectedFileUrl = await this.dossierService.getImagePresignedUrl(
      item.dossier_id,
      image_id
    );
    this.selectedFile = await this.dossierService.getImage(item.dossier_id, image_id);
  }

  protected deselectFile(): void {
    this.selectedFile = undefined;
    this.selectedFileUrl = undefined;
  }

  protected keyDown(e: KeyboardEvent): void {
    if (e.key == 'Escape') {
      this.deselectFile();
    }
  }

  protected readonly environment = environment;
  protected readonly TimeLineType = TimeLineType;
  protected readonly isImagesAnswer = isImagesAnswer;
  protected readonly isTextAnswer = isTextAnswer;
  protected readonly isNumberAnswer = isNumberAnswer;
  protected readonly isBooleanAnswer = isBooleanAnswer;
  protected readonly isCheckListAnswer = isCheckListAnswer;
  protected readonly isRadioListAnswer = isRadioListAnswer;
}
