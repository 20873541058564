import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModelLoaderService } from '@shared/services/model-loader.service';
import { Group, Material, Mesh } from 'three';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { CadastralId } from '@services/building-overview.service';
import { Pose } from '@api-clients/bim';

export type RealEstateId = string;

@UntilDestroy()
@Component({
  selector: 'hd-building-manager',
  template: '',
})
export class HdBuildingManagerComponent {
  private hdModels: Map<RealEstateId, Group | undefined> = new Map();
  private cadastralIdToIdMapping: Map<CadastralId, RealEstateId> = new Map();

  constructor(private modelLoader: ModelLoaderService) {}

  // Todo: loading fused Hd models can be done simultaneously for multiple buildings to improve performance
  async addHdBuilding(building: BuildingOverviewEntry): Promise<[Group, Pose?] | undefined> {
    if (!building.real_estate_id) throw new Error('Building is missing required IDs');
    if (this.hdModels.has(building.real_estate_id!))
      throw new Error('Model was already added or is pending');
    this.hdModels.set(building.real_estate_id!, undefined); // Set model to pending

    const fusedBuilding = await this.modelLoader.loadFusedModelForBuilding(building.bim_id!);
    if (!fusedBuilding) return;
    const [gltf, pose] = fusedBuilding;
    const hdModel = gltf.scene;
    hdModel.userData['realEstateId'] = building.real_estate_id;

    if (building.external_id) {
      hdModel.userData['cadastralIds'] = building.external_id;
      this.cadastralIdToIdMapping.set(building.external_id, building.real_estate_id!);
    }
    this.hdModels.set(building.real_estate_id!, hdModel);
    const transparentMesh = hdModel.getObjectByName('transparent_mesh') as Mesh | undefined;
    if (transparentMesh) {
      transparentMesh.renderOrder = 1;
      const transparentMaterial = transparentMesh.material as Material;
      transparentMaterial.transparent = true;
      transparentMaterial.alphaTest = 0.05;
      transparentMaterial.depthWrite = true;
    }
    const opaqueMesh: Mesh | undefined = hdModel.getObjectByName('opaque_mesh') as Mesh | undefined;
    if (opaqueMesh) {
      const opaqueMaterial = opaqueMesh.material as Material;
      opaqueMaterial.transparent = true;
      opaqueMaterial.alphaTest = 0.05;
      opaqueMaterial.depthWrite = true;
    }

    this.setBuildingEmissive([0.05, 0.05, 0.05], building.real_estate_id!);
    return [hdModel, pose];
  }

  removeHdBuilding(realEstateId: string): void {
    const model = this.hdModels.get(realEstateId);
    if (!model) return;
    model.parent?.remove(model);
    this.hdModels.delete(realEstateId);
    this.cadastralIdToIdMapping.forEach((value, key) => {
      if (value === realEstateId) this.cadastralIdToIdMapping.delete(key);
    });
  }

  /// Returns the cadastral IDs of all HD buildings that are currently loaded.
  getHdCadastralIds(): CadastralId[] {
    return Array.from(this.cadastralIdToIdMapping.keys());
  }

  setAllBuildingVisiblity(visible: boolean): void {
    this.hdModels.forEach((model) => {
      if (model) model.visible = visible;
    });
  }

  get(realEstateId: RealEstateId): Group | undefined {
    return this.hdModels.get(realEstateId);
  }

  has(realEstateId: RealEstateId): boolean {
    return this.hdModels.has(realEstateId);
  }

  clear(): void {
    this.hdModels.clear();
  }

  // Set the emission color for the meshes of the given buildings.
  setBuildingEmissive(rgb: number[], ...realEstateIds: RealEstateId[]): void {
    for (const realEstateId of realEstateIds) {
      const buildingModel = this.hdModels.get(realEstateId);
      buildingModel?.children[0].children[0]['material']?.emissive.set(...rgb);
      buildingModel?.children[0].children[1]?.['material']?.emissive.set(...rgb);
    }
  }

  // Sets the visibility of all HD buildings found in the list and returns the remaining buildings.
  setVisibility(
    visible: boolean,
    buildings: BuildingOverviewEntry[]
  ): { remainingBuildings: BuildingOverviewEntry[] } {
    const remainingBuildings: BuildingOverviewEntry[] = [];
    for (const building of buildings) {
      if (building.real_estate_id && this.hdModels.get(building.real_estate_id)) {
        this.hdModels.get(building.real_estate_id)!.visible = visible;
      } else {
        remainingBuildings.push(building);
      }
    }
    return { remainingBuildings };
  }

  // Unhighlights all HD buildings found in the list and returns the remaining buildings.
  unhighlightBuildings(buildings: BuildingOverviewEntry[]): {
    remainingBuildings: BuildingOverviewEntry[];
  } {
    return this.setEmissive([0.05, 0.05, 0.05], buildings);
  }

  // Highlights all HD buildings found in the list and returns the remaining buildings.
  highlightBuildings(buildings: BuildingOverviewEntry[]): {
    remainingBuildings: BuildingOverviewEntry[];
  } {
    return this.setEmissive([0, 0, 0], buildings);
  }

  // Sets the emissive value of all HD buildings found in the list and returns the remaining buildings
  private setEmissive(
    rgb: number[],
    buildings: BuildingOverviewEntry[]
  ): {
    remainingBuildings: BuildingOverviewEntry[];
  } {
    const remainingBuildings: BuildingOverviewEntry[] = [];
    for (const building of buildings) {
      if (building.real_estate_id && this.hdModels.get(building.real_estate_id)) {
        this.setBuildingEmissive(rgb, building.real_estate_id);
      } else {
        remainingBuildings.push(building);
      }
    }
    return { remainingBuildings };
  }
}
