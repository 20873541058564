/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimeLineType = 'Note' | 'ConstructionReport' | 'BimLink' | 'Inspection';

export const TimeLineType = {
    Note: 'Note' as TimeLineType,
    ConstructionReport: 'ConstructionReport' as TimeLineType,
    BimLink: 'BimLink' as TimeLineType,
    Inspection: 'Inspection' as TimeLineType
};

