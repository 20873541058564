import { MapMouseEvent } from 'maplibre-gl';
import {
  Camera,
  DirectionalLight,
  Intersection,
  Scene,
  SRGBColorSpace,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { CadastralId } from '@services/building-overview.service';
import { batchIdsToCadastralIds } from '@shared/components/viewer/map-3d/utils/indexing';
import { RealEstateId } from '@shared/components/viewer/map-3d/assets/hd-building-manager';

export type AmbiguousBuildingId = { cadastralId: CadastralId } | { realEstateId: RealEstateId };

export function initRenderer(canvas: HTMLElement, glContext: WebGLRenderingContext): WebGLRenderer {
  const renderer = new WebGLRenderer({
    canvas: canvas,
    context: glContext,
    antialias: true,
  });
  renderer.outputColorSpace = SRGBColorSpace;
  renderer.autoClear = false;
  return renderer;
}

export function addLightsAround(scene: Scene): void {
  const dirLight = new DirectionalLight(0xffffff, 3);
  dirLight.position.set(0.6, 0.6, 0);
  scene.add(dirLight);
  const dirLight2 = new DirectionalLight(0xffffff, 2.6);
  dirLight2.position.set(-1, 0.6, 0.4);
  scene.add(dirLight2);
  const dirLight3 = new DirectionalLight(0xffffff, 2.6);
  dirLight3.position.set(-1, 0.6, -0.4);
  scene.add(dirLight3);
}

export function mapMouseEventToGlMouseCoordinates(mapMouseEvent: MapMouseEvent): Vector2 {
  const canvas = mapMouseEvent.target.getCanvas();
  const point = mapMouseEvent.point;
  return new Vector2(
    ((point.x - canvas.clientLeft) / canvas.clientWidth) * 2 - 1,
    -((point.y - canvas.clientTop) / canvas.clientHeight) * 2 + 1
  );
}

export function camDistanceToTarget(camera: Camera): number {
  const height = camera.position.y;
  const direction = camera.getWorldDirection(new Vector3());
  return height / -direction.y;
}

export function controlsToTranslate(controls: TransformControls): void {
  controls.showX = true;
  controls.showZ = true;
  controls.showY = false;
  controls.setMode('translate');
}

export function controlsToRotate(controls: TransformControls): void {
  controls.showX = false;
  controls.showZ = false;
  controls.showY = true;
  controls.setMode('rotate');
}

// Returns the cadastralId of the closest intersection or undefined if there is no intersection
export function cadastralIdFromRaycasterIntersections(
  intersections: Intersection[]
): AmbiguousBuildingId | undefined {
  // Get the data from the closest intersection.
  for (const hit of intersections) {
    const { face, object } = hit;
    if (!face) continue;

    // Check if the object is an HD model
    if (object.parent?.name == 'world') {
      const realEstateId = object.parent?.parent?.userData['realEstateId'];
      return { realEstateId };
    }

    const batch_id = object['geometry'].getAttribute('_batchid').getX(face.a);
    const [cadastralId] = batchIdsToCadastralIds(object.parent!, [batch_id]);
    return { cadastralId };
  }
  return;
}
