import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-filter',
  templateUrl: './information-filter.component.html',
  styleUrls: ['./information-filter.component.scss'],
})
export class InformationFilterComponent {
  @Input() filters = [];
}
