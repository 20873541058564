<div class="table-pagination">
  <div class="table-pagination__size">
    {{ 'rowsPerPage' | translate }}

    <div class="select">
      <select (change)="changePageSize($event)" [(ngModel)]="pageSize">
        <option
          *ngFor="let option of pageSizeOptions"
          [value]="option"
          [selected]="option === pageSize">
          {{ option }}
        </option>
      </select>
    </div>
  </div>

  <div class="table-pagination__index">
    {{ start }} - {{ end }} {{ 'from' | translate }} {{ total }}
  </div>

  <div class="table-pagination__arrows">
    <button
      class="material-symbols-rounded"
      (click)="firstPage()"
      [class.disabled]="isFirstPage || isSinglePage">
      first_page
    </button>
    <button
      class="material-symbols-rounded back"
      (click)="previousPage()"
      [class.disabled]="!hasPrevious || isSinglePage">
      chevron_left
    </button>

    <button
      class="material-symbols-rounded next"
      (click)="nextPage()"
      [class.disabled]="!hasNext || isSinglePage">
      chevron_right
    </button>
    <button
      class="material-symbols-rounded"
      (click)="lastPage()"
      [class.disabled]="isLastPage || isSinglePage">
      last_page
    </button>
  </div>
</div>
