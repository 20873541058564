import { Injectable } from '@angular/core';
import { Section, Workspace } from '../models';
import { WorkspaceService } from './workspace.service';
import {
  AddSectionRequest,
  ItemStatusTypeDto,
  QuestionType,
  SectionTypeDto,
} from '@api-clients/workspace';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceDetailService {
  public currentWorkspace?: Workspace; // Loaded workspace
  public currentSection?: Section; // Section that is currently the root of the view
  public activeSection?: Section; // Section that was last selected in the current view

  edit = new BehaviorSubject<boolean>(false);

  constructor(private workspaceService: WorkspaceService) {}

  public async loadWorkspace(workspaceId: string, initialSectionId?: string): Promise<void> {
    this.currentWorkspace = await this.workspaceService.getWorkspace(workspaceId);
    if (initialSectionId) {
      this.currentSection = this.currentWorkspace.getSection(initialSectionId);
    } else {
      this.currentSection = this.currentWorkspace.rootSection;
    }
  }

  public async updateWorkspace(): Promise<void> {
    if (!this.currentWorkspace) return;
    const ws = this.currentWorkspace;
    const update = {
      newName: ws.name,
      newDescription: ws.description,
      workspaceSettings: ws.workspaceSettings,
    };
    await this.workspaceService.updateWorkspace(this.currentWorkspace.id, update);
  }

  /// Inserts a section in the current section
  public async insertSection(folder?: boolean, insertAt?: number): Promise<void> {
    if (!this.currentWorkspace || !this.currentSection) return;
    const addSectionRequest: AddSectionRequest = {
      name: '',
      sectionType: folder ? SectionTypeDto.ContainsOnlySections : SectionTypeDto.Default,
      description: '',
      insertAt,
    };

    const newSection = await this.currentSection.insertSection(addSectionRequest);

    this.currentWorkspace.sections = [...this.currentWorkspace.sections, newSection];
  }

  /// Inserts a question in the current section
  public async insertQuestion(questionType: QuestionType, insertAt?: number): Promise<void> {
    if (!this.currentWorkspace || !this.activeSection) return;
    this.activeSection.insertQuestion({
      label: '',
      questionType,
      insertAt,
      isRequired: false,
      status: ItemStatusTypeDto.NotApplicable,
      properties: undefined,
      value: undefined,
    });
  }

  /// The path of the current section from the workspace root
  public currentPath(): { name: string; id: string }[] {
    let id = this.currentSection?.id;
    if (!this.currentWorkspace) return [];
    const path: { name: string; id: string }[] = [];
    while (id && id != this.currentWorkspace.rootSection?.id) {
      const section = this.currentWorkspace.sections.find((section) => section.id === id)!;
      path.push({ name: section.name, id: section.id });
      id = section.parentId;
    }
    return path.reverse();
  }
}
