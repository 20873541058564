/**
 * s3wrapper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A DeleteContentItemsRequest
 */
export interface DeleteRequest { 
    /**
     * A dictionary of item/file names.
     */
    contentItemIds?: Array<string> | null;
    /**
     * If true, handles workspace deletion.  If false, handles contentItemId deletion in workspace. Defaults to false.
     */
    deleteWorkspace?: boolean | null;
}

