/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressDto { 
    id?: string;
    /**
     * The street name of the address             
     */
    streetName?: string;
    /**
     * The house number and optional addition             
     */
    number?: string;
    /**
     * The postal code of the address
     */
    postalCode?: string;
    /**
     * The city of the address             
     */
    city?: string;
    /**
     * The country of the address.             
     */
    country?: string;
    /**
     * The state or province the address resides in.             
     */
    state?: string | null;
}

