/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BimObjectGraphDto } from './bimObjectGraphDto';
import { BimRoomGraphDto } from './bimRoomGraphDto';
import { BimLevelGraphDto } from './bimLevelGraphDto';

export interface BuildingInformationModelDetailedDto {
  id: string;
  properties: any | null;
  model_3d_url: string;
  levels: Array<BimLevelGraphDto>;
  rooms: Array<BimRoomGraphDto>;
  objects: Array<BimObjectGraphDto>;
}
