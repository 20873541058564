<div class="checklist" [class.edit]="edit" [contextMenu]="menu">
  <h3 class="checklist__title">
    {{ edit ? ('checklist' | translate) : this.question.description }}
    <span class="subject" *ngIf="!edit && question.subject.title">
      ({{ question.subject.title }})
    </span>
  </h3>

  <div class="checklist__wrapper">
    <div class="checklist__input big-input" *ngIf="edit">
      <input
        placeholder="{{ 'question-name' | translate }}"
        [(ngModel)]="question.description"
        type="text"
        name="input"
        id="input"
        autocomplete="off" />

      <div class="checklist__additional_fields">
        <label for="subject" class="checklist__subject" *ngIf="edit">
          {{ 'subject' | translate }}
        </label>

        <input
          placeholder="{{ 'subject' | translate }}"
          type="text"
          name="input"
          id="subject"
          autocomplete="off"
          [(ngModel)]="question.subject.title"
          *ngIf="edit" />
      </div>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>

    <div class="checklist__list">
      <div
        class="list-item"
        *ngFor="let field of fields; let i = index; trackBy: trackByFn"
        [class.disabled]="disabled">
        <label [class]="radio ? 'radiocontainer' : 'checkboxcontainer'">
          <input
            name="field"
            [type]="radio ? 'radio' : 'checkbox'"
            [checked]="isChecked(field)"
            (click)="alterValue(field)"
            [disabled]="disabled" />
          <span [class]="radio ? 'radiomark' : 'checkmark'"></span>

          <span class="list-item__text" *ngIf="!edit">{{ field }}</span>
        </label>

        <input *ngIf="edit" class="list-item__input" type="text" [(ngModel)]="fields[i]" />

        <button
          *ngIf="edit"
          class="material-symbols-rounded list-item__remove"
          (click)="removeField(field)">
          close
        </button>
      </div>

      <div class="list-item new">
        <label [class]="radio ? 'radiocontainer' : 'checkboxcontainer'">
          <input type="checkbox" />
          <span [class]="radio ? 'radiomark' : 'checkmark'"></span>
        </label>

        <span class="list-item__text" *ngIf="!edit">{{ 'add-check' | translate }}</span>
        <input
          type="text"
          class="list-item__input"
          [(ngModel)]="potentialFieldName"
          placeholder="{{ 'add-check' | translate }}"
          *ngIf="edit"
          (keyup.enter)="addField()" />

        <button class="material-symbols-rounded list-item__add" (click)="addField()">add</button>
      </div>
    </div>
  </div>
</div>
