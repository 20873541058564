/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PermissionTypeDto = 'Denied' | 'Read' | 'ReadWrite' | 'Owner' | 'PublicShare';

export const PermissionTypeDto = {
    Denied: 'Denied' as PermissionTypeDto,
    Read: 'Read' as PermissionTypeDto,
    ReadWrite: 'ReadWrite' as PermissionTypeDto,
    Owner: 'Owner' as PermissionTypeDto,
    PublicShare: 'PublicShare' as PermissionTypeDto
};

