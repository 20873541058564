import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BimPropertyService, TimelineService } from '../../services';
import { Product } from '@api-clients/product';
import { ObjectCategory, RoomCategory } from '@api-clients/bim';
import { isBimObject, isBimRoom } from '../../views/model-viewer/utils/types';
import {
  isBooleanProperty,
  isEnumProperty,
  isFloatProperty,
} from '../../views/model-viewer/property';
import { ChangedElement, UnsavedElement } from '../changes-summary/change';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-element-popup',
  templateUrl: './element-popup.component.html',
  styleUrls: ['./element-popup.component.scss'],
})
export class ElementPopupComponent {
  protected animateCart: boolean = false;
  protected visible: boolean = false;
  protected selectedElement?: UnsavedElement;
  // Todo: Can we make this cleaner? Is there a way to find the categories in the element itself?
  protected categories: string[] = [];
  protected productsMenuState: 'default' | 'add' | 'detail' = 'default';
  protected detailedProduct = new Subject<Product>();

  protected elementTab = {
    properties: 'properties',
    dossier: 'dossier',
    addNote: 'add-note',
    products: 'products',
  };

  protected currentTab: string = this.elementTab.properties;
  protected amountOfProducts: number = 0;

  constructor(
    protected readonly bimPropertyService: BimPropertyService,
    protected readonly timelineService: TimelineService
  ) {
    this.bimPropertyService.selectedElement
      .pipe(takeUntilDestroyed())
      .subscribe((selectedChangedElement) => {
        this.animateVisibility(selectedChangedElement);
        this.productsMenuState = 'default';
        if (!selectedChangedElement) return;
        if (isBimRoom(selectedChangedElement.element)) {
          this.categories = Object.keys(RoomCategory);
        } else if (isBimObject(selectedChangedElement.element)) {
          this.categories = Object.keys(ObjectCategory);
        }
        // Todo: Make product properties a separate element in ChangedElement
        //get the amount of products
        this.amountOfProducts = selectedChangedElement.getProducts().length;
      });
  }

  protected addProperties(): void {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    void this.bimPropertyService.updateChangedElement(this.selectedElement);
    void this.bimPropertyService.selectBimElement(undefined);
    this.cartAnimate();
  }

  protected selectCategory(category: ObjectCategory | RoomCategory): void {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    this.selectedElement.changeCategory(category);
  }

  protected propertyChanged(
    key: string,
    newValue: string | number | boolean | Product[] | undefined
  ): void {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    this.selectedElement.changeProperty(key, newValue);
  }

  protected addProducts(productsToAdd: Product[]): void {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    this.selectedElement.addProducts(productsToAdd);
    this.productsMenuState = 'default';
  }

  protected removeProduct(product: Product): void {
    this.selectedElement?.removeProduct(product);
  }

  protected openProductsAdd(): void {
    this.productsMenuState = 'add';
  }

  protected openProductDetail(product: Product): void {
    this.productsMenuState = 'detail';
    this.detailedProduct.next(product);
  }

  protected closeProductPopup(): void {
    this.productsMenuState = 'default';
  }

  protected categoryIsDisabled(selectedElement: UnsavedElement): boolean {
    return (
      selectedElement instanceof ChangedElement && selectedElement.originalCategory !== undefined
    );
  }

  protected hasOriginalValue(selectedElement: UnsavedElement): boolean {
    return selectedElement instanceof ChangedElement && selectedElement.originalValues.size > 0;
  }

  cartAnimate(): void {
    this.animateCart = true;

    setTimeout(() => {
      this.animateCart = false;
    }, 500);
  }

  animateVisibility(unsavedElement: UnsavedElement | undefined): void {
    if (unsavedElement) this.selectedElement = unsavedElement;
    this.visible = unsavedElement !== undefined;
    if (!unsavedElement) {
      setTimeout(() => {
        this.selectedElement = undefined;
      }, 200);
    }
  }

  dossierTimeline(): void {
    if (this.currentTab === this.elementTab.dossier) return;
    this.currentTab = this.elementTab.dossier;
    // TODO: implement dossier filtering on selected element/room
    this.timelineService.resetTimeline();
  }

  protected readonly console = console;
  protected readonly isFloatProperty = isFloatProperty;
  protected readonly isBooleanProperty = isBooleanProperty;
  protected readonly isEnumProperty = isEnumProperty;
}
