/**
 * Share.Api
 * TODO
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemStatusTypeDto } from './itemStatusTypeDto';


export interface FileRequestItem { 
    isRequired?: boolean | null;
    status?: ItemStatusTypeDto;
    contentItemIds: Array<string>;
    originalRequestItemId?: string | null;
    description?: string | null;
    name?: string | null;
    allowedFileTypes: Array<string>;
    parentId?: string | null;
    insertAt?: number | null;
    metadata?: any | null;
}
export namespace FileRequestItem {
}


