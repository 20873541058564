<div class="images-question" [class.edit]="edit" [contextMenu]="menu">
  <label for="input" class="images-question__title">
    {{ edit ? ('images-question' | translate) : question.description }}
    <span class="subject" *ngIf="!edit && question.subject.title">
      ({{ question.subject.title }})
    </span>
  </label>

  <div class="images-question__wrapper">
    <div class="images-question__input big-input">
      <input
        placeholder="{{ 'question-name' | translate }}"
        type="text"
        name="input"
        id="input"
        autocomplete="off"
        [(ngModel)]="question.description"
        *ngIf="edit" />

      <div class="images-question__additional_fields">
        <label for="subject" class="images-question__subject" *ngIf="edit">
          {{ 'subject' | translate }}
        </label>

        <input
          placeholder="{{ 'subject' | translate }}"
          type="text"
          name="input"
          id="subject"
          autocomplete="off"
          [(ngModel)]="question.subject.title"
          *ngIf="edit" />
      </div>

      <div
        class="images-question__answer"
        *ngIf="!edit && !isInspectionFinding(question)"
        [class.disabled]="disabled">
        <button class="button-primary button--has-icon">
          <span class="material-symbols-rounded">camera</span>
          {{ 'dossier-module.take-photo' | translate }}
        </button>

        <button class="button-secondary button--has-icon">
          <span class="material-symbols-rounded">photo</span>
          {{ 'dossier-module.add-photo' | translate }}
        </button>
      </div>
      <div class="images-question__answer" *ngIf="!edit && isInspectionFinding(question)">
        <input
          type="file"
          #imageInput
          accept="image/png, image/gif, image/jpeg"
          style="display: none"
          (change)="imageInputChanged()" />
        <button (click)="uploadImage()" class="button-secondary button--has-icon">
          <span class="material-symbols-rounded">add_a_photo</span>
          ({{ image_ids.length }})
        </button>
      </div>
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>
  </div>
</div>
