/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDtoAddress } from './userDtoAddress';


export interface OrganizationDto { 
    /**
     * The identifier of the organization             
     */
    id: string;
    /**
     * The name of the company.             
     */
    name: string;
    /**
     * The general phone number of the organization.             
     */
    phoneNumber?: string | null;
    /**
     * The email address of this organization.             
     */
    emailAddress?: string | null;
    address?: UserDtoAddress | null;
    /**
     * The identifier of the address instance (foreign key).             
     */
    addressId?: string | null;
    /**
     * The chamber of commerce identifier for the organization.             
     */
    chamberOfCommerce?: string | null;
    /**
     * General website url for the organization.             
     */
    websiteUrl?: string | null;
    invoiceAddress?: UserDtoAddress | null;
    /**
     * The address of the organization.             
     */
    invoiceAddressId?: string | null;
    financialEmailAddress?: string | null;
    financialEmailBccAddress?: string | null;
    vatId?: string | null;
    preferredCurrency?: string | null;
    creationTimestampUtc?: string;
    lastModifiedTimestampUtc?: string;
}

