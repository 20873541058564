<div class="profile-container">
  <button
    class="profile-picture-circle"
    type="button"
    [ngClass]="{ rectangle: isRectangle }"
    (click)="fileUpload.click()"
    [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }">
    <div class="new-picture-icon" [class.visible]="!imageUrl">
      <input
        type="file"
        class="file-input"
        #fileUpload
        (change)="onFileSelected($event)"
        accept="image/PNG, image/JPG, image/JPEG" />
      <span class="material-symbols-rounded upload-icon">add_photo_alternate</span>
    </div>
  </button>
  <div class="info-box">
    <h5 *ngIf="!isRectangle">{{ 'profile-picture' | translate }}</h5>
    <h5 *ngIf="isRectangle">{{ 'upload-company-picture' | translate }}</h5>
    <span>{{ 'supported-file-types' | translate }}</span>
    <span>{{ 'max-file-size' | translate }}</span>
  </div>
</div>
