/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BimLink } from '../model/bimLink';
// @ts-ignore
import { CreateBimLinkRequest } from '../model/createBimLinkRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BimLinksService {

    protected basePath = 'https://api.dev.twinspot.com/dossiers';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Retrieves all BIM (Building Information Modeling) links for a specific dossier.
     * # Parameters  - &#x60;dossier_id&#x60;: The unique identifier of the dossier. - &#x60;auth&#x60;: The authentication information of the user.  # Returns  Returns a list of BIM links associated with the specified dossier.  # Errors  Returns an error if: - The user does not have the required permissions. - The user is not part of the organization associated with the dossier. - There is an issue processing the request.
     * @param dossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersDossierIdBimLinksGet(dossierId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Array<BimLink>>;
    public dossiersDossierIdBimLinksGet(dossierId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Array<BimLink>>>;
    public dossiersDossierIdBimLinksGet(dossierId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Array<BimLink>>>;
    public dossiersDossierIdBimLinksGet(dossierId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossiersDossierIdBimLinksGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/bim_links`;
        return this.httpClient.request<Array<BimLink>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to get a linked BIM in a dossier.
     * # Path Parameters: - &#x60;dossier_id&#x60; (path parameter): The ID of the dossier containing the linked BIM. - &#x60;id&#x60; (path parameter): The ID of the linked BIM to retrieve.  # Authorization: - **Scope:** &#x60;timeline_events:Get&#x60; - **Permissions Required:** Users must have permission to retrieve timeline events.  # Responses: - **200 OK:** Returns the requested linked BIM. - **404 Not Found:** If the specified linked BIM is not found within the dossier. - **500 Internal Server Error:** If there is a server error during the retrieval process.  Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;1a82a9ac-354b-4fe2-91c6-fcc8bf4f7d3b\&quot;, \&quot;dossier_id\&quot;: \&quot;fd9c4e3a-95b5-45e8-837d-d2c2bb9d1887\&quot;, \&quot;user_id\&quot;: \&quot;4e7a1b57-60f4-4d79-a41c-5b33e837c32e\&quot;, \&quot;linked_bim_id\&quot;: \&quot;17aef01b-7e6e-4f42-a7a3-478a05f11d7f\&quot;, \&quot;description\&quot;: \&quot;Detailed description of the BIM\&quot;, } &#x60;&#x60;&#x60;
     * @param dossierId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersDossierIdBimLinksIdGet(dossierId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BimLink>;
    public dossiersDossierIdBimLinksIdGet(dossierId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BimLink>>;
    public dossiersDossierIdBimLinksIdGet(dossierId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BimLink>>;
    public dossiersDossierIdBimLinksIdGet(dossierId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossiersDossierIdBimLinksIdGet.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersDossierIdBimLinksIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/bim_links/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<BimLink>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to create a link to bim in a dossier.
     * # Path Parameters: - &#x60;dossier_id&#x60; (path parameter): The ID of the dossier containing the linked BIM.  # Request Body: The request body should be a JSON object with the following fields: - &#x60;bim_id&#x60; (UUID, required): Identifier of the BIM to link. - &#x60;description&#x60; (string, required): Description of the BIM.  Example Request Body: &#x60;&#x60;&#x60;json { \&quot;bim_id\&quot;: \&quot;17aef01b-7e6e-4f42-a7a3-478a05f11d7f\&quot;, \&quot;description\&quot;: \&quot;Detailed description of the BIM\&quot; } &#x60;&#x60;&#x60;  # Authorization: - **Scope:** &#x60;timeline_events:Create&#x60; - **Permissions Required:** Users must have permission to create timeline events.  # Responses: - **200 OK:** Returns the created linked BIM. - **404 Not Found:** If the specified dossier is not found. - **500 Internal Server Error:** If there is a server error during the retrieval process.  Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;1a82a9ac-354b-4fe2-91c6-fcc8bf4f7d3b\&quot;, \&quot;dossier_id\&quot;: \&quot;fd9c4e3a-95b5-45e8-837d-d2c2bb9d1887\&quot;, \&quot;user_id\&quot;: \&quot;4e7a1b57-60f4-4d79-a41c-5b33e837c32e\&quot;, \&quot;linked_bim_id\&quot;: \&quot;17aef01b-7e6e-4f42-a7a3-478a05f11d7f\&quot;, \&quot;description\&quot;: \&quot;Detailed description of the BIM\&quot;, } &#x60;&#x60;&#x60;
     * @param dossierId 
     * @param createBimLinkRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersDossierIdBimLinksPost(dossierId: string, createBimLinkRequest: CreateBimLinkRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BimLink>;
    public dossiersDossierIdBimLinksPost(dossierId: string, createBimLinkRequest: CreateBimLinkRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BimLink>>;
    public dossiersDossierIdBimLinksPost(dossierId: string, createBimLinkRequest: CreateBimLinkRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BimLink>>;
    public dossiersDossierIdBimLinksPost(dossierId: string, createBimLinkRequest: CreateBimLinkRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossiersDossierIdBimLinksPost.');
        }
        if (createBimLinkRequest === null || createBimLinkRequest === undefined) {
            throw new Error('Required parameter createBimLinkRequest was null or undefined when calling dossiersDossierIdBimLinksPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "dossierId", value: dossierId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/bim_links`;
        return this.httpClient.request<BimLink>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createBimLinkRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
