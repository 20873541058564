/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InspectionTemplatePropertyType = 'ImageList' | 'Text' | 'Number' | 'Boolean' | 'CheckList' | 'RadioList';

export const InspectionTemplatePropertyType = {
    ImageList: 'ImageList' as InspectionTemplatePropertyType,
    Text: 'Text' as InspectionTemplatePropertyType,
    Number: 'Number' as InspectionTemplatePropertyType,
    Boolean: 'Boolean' as InspectionTemplatePropertyType,
    CheckList: 'CheckList' as InspectionTemplatePropertyType,
    RadioList: 'RadioList' as InspectionTemplatePropertyType
};

