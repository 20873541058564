import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceSelectDialogService {
  private dialogStatus = new BehaviorSubject<{ active: boolean }>({
    active: false,
  });

  public dialogStatus$ = this.dialogStatus.asObservable();

  open(): void {
    this.dialogStatus.next({ active: true });
  }

  close(): void {
    this.dialogStatus.next({ active: false });
  }
}
