/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AdresUitgebreidHal } from '../model/adresUitgebreidHal';
// @ts-ignore
import { AdresUitgebreidHalCollection } from '../model/adresUitgebreidHalCollection';
// @ts-ignore
import { ExtendedProblemDetails } from '../model/extendedProblemDetails';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface BevraagAdresUitgebreidMetNumIdRequestParams {
    /** De identificatie van een nummeraanduiding uit de BAG. */
    nummeraanduidingIdentificatie: string;
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
    /** Een indicatie of ook actuele voorkomens met een eind status geleverd moeten worden. */
    inclusiefEindStatus?: boolean;
}

export interface ZoekAdresUitgebreidRequestParams {
    /** Postcode behorende bij een nummeraanduiding. */
    postcode?: string;
    /** Huisnummer behorende bij een nummeraanduiding. */
    huisnummer?: number;
    /** Huisnummertoevoeging behorende bij een nummeraanduiding. */
    huisnummertoevoeging?: string;
    /** Huisletter behorende bij een nummeraanduiding. */
    huisletter?: string;
    /** Een indicatie of de resultaten van een zoekoperatie exact overeen  moeten komen met de zoekcriteria, zie  [functionele specificatie exacte match](https://github.com/lvbag/BAG-API/blob/master/Features/exacte_match.feature).  */
    exacteMatch?: boolean;
    /** Identificatie van een adresseerbaar object uit de BAG. */
    adresseerbaarObjectIdentificatie?: string;
    /** Naam van een woonplaats. */
    woonplaatsNaam?: string;
    /** Naam van een openbare ruimte. */
    openbareRuimteNaam?: string;
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
    /** Pagina nummer */
    page?: number;
    /** Aantal resultaten per pagina */
    pageSize?: number;
    /** Zoekterm op postcode, woonplaats, straatnaam, huisnummer, huisletter,  huisnummertoevoeging.  */
    q?: string;
    /** Een indicatie of ook actuele voorkomens met een eind status geleverd moeten worden. */
    inclusiefEindStatus?: boolean;
    /** De identificatie van een pand uit de BAG. */
    pandIdentificatie?: string;
}


@Injectable({
  providedIn: 'root'
})
export class AdresUitgebreidService {

    protected basePath = 'https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Bevragen van de uitgebreide informatie van één huidig adres met de  identificatie van een nummeraanduiding.
     * Bevragen van de uitgebreide informatie van één huidig adres met de  identificatie van een nummeraanduiding.  Als inclusiefEindStatus&#x3D;true, dan worden ook actuele adressen met een eind status geleverd, zie [functionele specificatie inclusiefEindstatus](https://github.com/lvbag/BAG-API/blob/master/Features/inclusief-eind-status.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bevraagAdresUitgebreidMetNumId(requestParameters: BevraagAdresUitgebreidMetNumIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<AdresUitgebreidHal>;
    public bevraagAdresUitgebreidMetNumId(requestParameters: BevraagAdresUitgebreidMetNumIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<AdresUitgebreidHal>>;
    public bevraagAdresUitgebreidMetNumId(requestParameters: BevraagAdresUitgebreidMetNumIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<AdresUitgebreidHal>>;
    public bevraagAdresUitgebreidMetNumId(requestParameters: BevraagAdresUitgebreidMetNumIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const nummeraanduidingIdentificatie = requestParameters.nummeraanduidingIdentificatie;
        if (nummeraanduidingIdentificatie === null || nummeraanduidingIdentificatie === undefined) {
            throw new Error('Required parameter nummeraanduidingIdentificatie was null or undefined when calling bevraagAdresUitgebreidMetNumId.');
        }
        const acceptCrs = requestParameters.acceptCrs;
        const inclusiefEindStatus = requestParameters.inclusiefEindStatus;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (inclusiefEindStatus !== undefined && inclusiefEindStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>inclusiefEindStatus, 'inclusiefEindStatus');
        }

        let localVarHeaders = this.defaultHeaders;
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/adressenuitgebreid/${this.configuration.encodeParam({name: "nummeraanduidingIdentificatie", value: nummeraanduidingIdentificatie, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<AdresUitgebreidHal>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Bevragen van de uitgebreide informatie van één of meer huidige adressen  op basis van verschillende combinaties van parameters.
     * De volgende (combinaties van) parameters worden ondersteund:  1. Bevragen van de uitgebreide informatie van één of meer huidige     adressen met een postcode, huisnummer en optioneel     huisnummertoevoeging en huisletter.    Het opgeven van een combinatie van parameters levert niet altijd     een exacte match met één adres, bv. in geval van meerdere objecten     met huisnummertoevoegingen en/of huisletters.     Met de exacteMatch parameter kan worden aangegeven dat alleen     object(en) die exact overeenkomen met de opgegeven parameters,     geretourneerd moeten worden.  2. Bevragen van de uitgebreide informatie van één of meer huidige     adressen met de identificatie van een adresseerbaar object.  3. Bevragen van de uitgebreide informatie van één of meer huidige     adressen met woonplaats naam, openbare ruimte naam, huisnummer     en optioneel huisnummertoevoeging en huisletter.    Het opgeven van een combinatie van parameters levert niet altijd     een exacte match met één adres, bv. in geval van meerdere objecten     met huisnummertoevoegingen en/of huisletters.     Met de exacteMatch parameter kan worden aangegeven dat alleen     object(en) die exact overeenkomen met de opgegeven parameters,     geretourneerd moeten worden.  4. Zoek uitgebreide adres informatie van huidige adressen met een     zoekterm.  5. Bevragen van de uitgebreide informatie van één of meer huidige     adressen met de identificatie van een pand.   Voor paginering, zie: [functionele specificatie paginering](https://github.com/lvbag/BAG-API/blob/master/Features/paginering.feature).  Als inclusiefEindStatus&#x3D;true, dan worden ook actuele adressen met een eind status geleverd, zie [functionele specificatie inclusiefEindstatus](https://github.com/lvbag/BAG-API/blob/master/Features/inclusief-eind-status.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoekAdresUitgebreid(requestParameters: ZoekAdresUitgebreidRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<AdresUitgebreidHalCollection>;
    public zoekAdresUitgebreid(requestParameters: ZoekAdresUitgebreidRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<AdresUitgebreidHalCollection>>;
    public zoekAdresUitgebreid(requestParameters: ZoekAdresUitgebreidRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<AdresUitgebreidHalCollection>>;
    public zoekAdresUitgebreid(requestParameters: ZoekAdresUitgebreidRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const postcode = requestParameters.postcode;
        const huisnummer = requestParameters.huisnummer;
        const huisnummertoevoeging = requestParameters.huisnummertoevoeging;
        const huisletter = requestParameters.huisletter;
        const exacteMatch = requestParameters.exacteMatch;
        const adresseerbaarObjectIdentificatie = requestParameters.adresseerbaarObjectIdentificatie;
        const woonplaatsNaam = requestParameters.woonplaatsNaam;
        const openbareRuimteNaam = requestParameters.openbareRuimteNaam;
        const acceptCrs = requestParameters.acceptCrs;
        const page = requestParameters.page;
        const pageSize = requestParameters.pageSize;
        const q = requestParameters.q;
        const inclusiefEindStatus = requestParameters.inclusiefEindStatus;
        const pandIdentificatie = requestParameters.pandIdentificatie;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (postcode !== undefined && postcode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>postcode, 'postcode');
        }
        if (huisnummer !== undefined && huisnummer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>huisnummer, 'huisnummer');
        }
        if (huisnummertoevoeging !== undefined && huisnummertoevoeging !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>huisnummertoevoeging, 'huisnummertoevoeging');
        }
        if (huisletter !== undefined && huisletter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>huisletter, 'huisletter');
        }
        if (exacteMatch !== undefined && exacteMatch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>exacteMatch, 'exacteMatch');
        }
        if (adresseerbaarObjectIdentificatie !== undefined && adresseerbaarObjectIdentificatie !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>adresseerbaarObjectIdentificatie, 'adresseerbaarObjectIdentificatie');
        }
        if (woonplaatsNaam !== undefined && woonplaatsNaam !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>woonplaatsNaam, 'woonplaatsNaam');
        }
        if (openbareRuimteNaam !== undefined && openbareRuimteNaam !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>openbareRuimteNaam, 'openbareRuimteNaam');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (inclusiefEindStatus !== undefined && inclusiefEindStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>inclusiefEindStatus, 'inclusiefEindStatus');
        }
        if (pandIdentificatie !== undefined && pandIdentificatie !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pandIdentificatie, 'pandIdentificatie');
        }

        let localVarHeaders = this.defaultHeaders;
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/adressenuitgebreid`;
        return this.httpClient.request<AdresUitgebreidHalCollection>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
