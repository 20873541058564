import { WorkspaceService } from '../services/workspace.service';
import { ContentItem } from './content.item.model';
import { RequestContentItem } from './filerequest.model';
import { Question } from './question.model';
import { SectionItem, SectionItemBase } from './section-item.model';
import {
  AddQuestionRequest,
  AddSectionRequest,
  ContentItemDto,
  FileRequestItem,
  QuestionDto,
  RequestContentItemDto,
  SectionDto,
  SectionItemType,
  SectionTypeDto,
  UpdateFileRequest,
  UpdateQuestionRequest,
  UpdateSectionRequest,
} from '@api-clients/workspace';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export class Section extends SectionItemBase implements SectionDto {
  items: SectionItem[];
  creationTimestampUtc!: Date;
  sectionType!: SectionTypeDto;
  name!: string;
  isVisible = true;
  expanded = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;

  private workspaceService: WorkspaceService;

  constructor(section: SectionDto, workspaceService: WorkspaceService) {
    super();
    Object.assign(this, section);
    this.items = (section.items || [])
      .map((item) => {
        switch (item.type) {
          case SectionItemType.Section:
            return new Section(item as SectionDto, workspaceService);
          case SectionItemType.FileRequest:
            return new RequestContentItem(item as RequestContentItemDto, workspaceService);
          case SectionItemType.Question:
            return new Question(item as QuestionDto, workspaceService);
          case SectionItemType.ContentItem:
            return new ContentItem(item as ContentItemDto);
          default:
            throw new Error(`Unknown section item type ${item.type}}`);
        }
      })
      .filter((item) => (item as ContentItem)?.isUploadComplete != false);
    this.workspaceService = workspaceService;
  }

  public get sections(): Section[] {
    return filter<Section>(this.items, SectionItemType.Section);
  }

  get allItems(): SectionItem[] {
    return [this, ...this.sections].flatMap((section) => section.items);
  }

  // get rcis(): RequestContentItem[] {
  //   return filter<RequestContentItem>(this.allItems, SectionItemType.FileRequest);
  // }

  // get visibleItems(): SectionItem[] {
  //   return this.items.filter((item) => item.isVisible);
  // }

  // get contentItems(): ContentItem[] {
  //   return filter<ContentItem>(this.allItems, SectionItemType.ContentItem);
  // }

  get isFolder(): boolean {
    return this.sectionType === SectionTypeDto.ContainsOnlySections;
  }

  // getChildren<TType>(type: SectionItemType): TType[] {
  //   return this.addChildren<TType>([], type);
  // }

  // private addChildren<TType>(items: TType[], type: SectionItemType): TType[] {
  //   if (type) items.push(...filter<TType>(this.items, type));
  //   else items.push(...(this.items as TType[]));
  //   this.items.forEach((item) => {
  //     if (item.type === SectionItemType.Section) {
  //       const section = item as Section;
  //       section.addChildren<TType>(items, type);
  //     } else if (
  //       item.type === SectionItemType.FileRequest &&
  //       (type === SectionItemType.ContentItem || !type)
  //     ) {
  //       const fileRequest = item as RequestContentItem;
  //       items.push(...(fileRequest.contentItems as TType[]));
  //     }
  //   });
  //   return items;
  // }

  /// Inserts a section as a child of this section
  async insertSection(addSectionRequest: AddSectionRequest): Promise<Section> {
    const index = addSectionRequest.insertAt;
    const section = await this.workspaceService.addSection(
      this.workspaceId,
      this.id,
      addSectionRequest
    );
    if (index) {
      this.items.splice(index, 0, section);
    } else {
      this.items.push(section);
    }
    return section;
  }

  /// Inserts a question as a child of this section
  insertQuestion(addQuestionRequest: AddQuestionRequest): void {
    const index = addQuestionRequest.insertAt;
    this.workspaceService
      .addQuestion(this.workspaceId, this.id, addQuestionRequest)
      .then((question) => {
        if (index) {
          this.items.splice(index, 0, question);
        } else {
          this.items = [...this.items, question];
        }
      });
  }

  /// Inserts a file request as a child of this section
  insertFileRequest(fileRequestItem: FileRequestItem): void {
    const index = fileRequestItem.insertAt;
    this.workspaceService
      .addFileRequest(this.workspaceId, this.id, fileRequestItem)
      .then((fileRequest) => {
        if (index) {
          this.items.splice(index, 0, fileRequest);
        } else {
          this.items.push(fileRequest);
        }
      });
  }

  /// Deletes a section that is a child of this section
  deleteSection(sectionId: string): void {
    this.workspaceService.deleteSection(this.workspaceId, sectionId).then(() => {
      this.items = this.items.filter((item) => item.id !== sectionId);
    });
  }

  /// Deletes a question that is a child of this section
  deleteQuestion(questionId: string): void {
    this.workspaceService.deleteQuestion(this.workspaceId, this.id, questionId).then(() => {
      this.items = this.items.filter((item) => item.id !== questionId);
    });
  }

  /// Deletes a file request that is a child of this section
  deleteFileRequest(fileRequestId: string): void {
    this.workspaceService.deleteFileRequest(this.workspaceId, this.id, fileRequestId).then(() => {
      this.items = this.items.filter((item) => item.id !== fileRequestId);
    });
  }

  updateChildPosition(previousIndex: number, newIndex: number): void {
    const child = this.items.at(previousIndex);
    if (!child) throw new Error('Child not found');
    moveItemInArray(this.items, previousIndex, newIndex);
    if (child instanceof Section) {
      void this.workspaceService.updateSection(this.workspaceId, child.id, {
        insertAt: newIndex,
      });
    } else if (child instanceof Question) {
      void this.workspaceService.updateQuestion(this.workspaceId, this.id, child.id, {
        insertAt: newIndex,
      });
    } else if (child instanceof RequestContentItem) {
      void this.workspaceService.updateFileRequest(this.workspaceId, this.id, child.id, {
        insertAt: newIndex,
        lastModifiedTimestampUtc: new Date(),
      });
    }
  }

  updateParentSection(newIndex: number, parentSectionId: string): void {
    const child = this.items.at(newIndex);
    if (!child) throw new Error('Child not found');
    if (child instanceof Section) {
      void this.workspaceService.updateSection(this.workspaceId, child.id, {
        insertAt: newIndex,
        parentSection: parentSectionId,
      });
    } else if (child instanceof Question) {
      void this.workspaceService.updateQuestion(this.workspaceId, this.id, child.id, {
        insertAt: newIndex,
        parentSection: parentSectionId,
      });
    } else if (child instanceof RequestContentItem) {
      void this.workspaceService.updateFileRequest(this.workspaceId, this.id, child.id, {
        insertAt: newIndex,
        parentSection: parentSectionId,
        lastModifiedTimestampUtc: new Date(),
      });
    }
  }

  /// Updates a section that is a child of this section
  updateSection(sectionId: string, sectionRequest: UpdateSectionRequest): void {
    void this.workspaceService.updateSection(this.workspaceId, sectionId, sectionRequest);
  }

  /// Updates a question that is a child of this section
  updateQuestion(questionId: string, questionRequest: UpdateQuestionRequest): void {
    void this.workspaceService.updateQuestion(
      this.workspaceId,
      this.id,
      questionId,
      questionRequest
    );
  }

  /// Updates a file request that is a child of this section
  updateFileRequest(fileRequestId: string, fileRequest: UpdateFileRequest): void {
    void this.workspaceService.updateFileRequest(
      this.workspaceId,
      this.id,
      fileRequestId,
      fileRequest
    );
  }

  /// Syncs section changes with the backend (e.g. name, description, etc.)
  update(): void {
    void this.workspaceService.updateSection(this.workspaceId, this.id, {
      name: this.name,
      description: this.description,
      metadata: this.metadata,
    });
  }
}

function filter<TType>(items: SectionItem[], ...types: SectionItemType[]): TType[] {
  return items.filter((section) => types.includes(section['type'])) as TType[];
}

export interface SectionRequestItem {
  name: string;
  description: string;
  SectionType: SectionTypeDto;
  items: SectionItem[];
  insertAt?: number;
}
