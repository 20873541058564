import { SectionItem, SectionItemBase } from './section-item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Download } from '../services/download.service';
import { ProgressEvent } from '../services/loader.service';
import {
  ContentItemDto,
  ContentItemMetaDto,
  MimeTypeDto,
  SectionItemType,
} from '@api-clients/workspace';

export interface INamed {
  name: string;
}

export class ContentItem extends SectionItemBase implements ContentItemDto {
  fileHash!: string;
  creationTimestampUtc!: Date;
  lastModifiedTimestampUtc!: Date;
  size!: number;
  userId!: string;
  download!: Observable<Download>;
  name!: string;
  isVisible = true;
  mimeType!: MimeTypeDto;
  thumbnail!: string;
  blobFileName!: string;
  contentItemMetas!: ContentItemMetaDto[];

  constructor(contentItem: ContentItemDto, isUploadComplete = true) {
    super();
    Object.assign(this, contentItem);
    this.type = SectionItemType.ContentItem;
    this.uploadProgress = new BehaviorSubject<ProgressEvent>(
      new ProgressEvent(isUploadComplete ? undefined : 0)
    );
  }

  public uploadProgress;

  public get isUploadComplete(): boolean {
    return this.uploadProgress.value.isActive || this.size > 0;
  }

  insertSectionItem(sectionItem: SectionItem, index: number): void {
    console.error('Cannot add a section item to a question.', sectionItem, index);
  }
}
