/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InspectionValueValueImages { 
    image_ids: Array<string>;
    type: InspectionValueValueImages.TypeEnum;
}
export namespace InspectionValueValueImages {
    export type TypeEnum = 'ImageList' | 'Text' | 'Number' | 'Boolean' | 'CheckList' | 'RadioList';
    export const TypeEnum = {
        ImageList: 'ImageList' as TypeEnum,
        Text: 'Text' as TypeEnum,
        Number: 'Number' as TypeEnum,
        Boolean: 'Boolean' as TypeEnum,
        CheckList: 'CheckList' as TypeEnum,
        RadioList: 'RadioList' as TypeEnum
    };
}


