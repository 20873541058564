/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Alle tijdslijn metagegevens die worden bijgehouden bij een voorkomen van een object en die te maken hebben met het historiemodel van de BAG en LVBAG conform de BAG Catalogus 2018 hoofdstuk 9 aangevuld met de historie die alleen in de LVBAG wordt opgebouwd.
 */
export interface VoorkomenLV { 
    /**
     * Het tijdstip waarop een versie van een BAG-object is geregistreerd in de registratie van een bronhouder en daarmee authentiek wordt gemaakt.
     */
    tijdstipRegistratie: string;
    /**
     * De identificatie van een voorkomen van een object.
     */
    versie: number;
    /**
     * Het tijdstip waarop een versie van een BAG-object is beëindigd in de registratie van een bronhouder.
     */
    eindRegistratie?: string;
    /**
     * De datum waarop een versie van een BAG-object geldig is in de werkelijkheid conform de ingangsdatum in het brondocument. Dit tijdstip wordt vastgelegd in de beginGeldigheid. Als er geen expliciete ingangsdatum van geldigheid is opgenomen, wordt de datum van het brondocument overgenomen.
     */
    beginGeldigheid: string;
    /**
     * De datum waarop de periode van geldigheid van een versie van een BAG-object eindigt. Bijvoorbeeld als gevolg van de vaststelling van een nieuw brondocument. Wanneer er geen tijdstip is ingevuld, dan is de versie nog geldig.
     */
    eindGeldigheid?: string;
    /**
     * Het tijdstip waarop een op dat moment toekomstig voorkomen door een bronhouder uit de geldige levenscylcus in de LV BAG is verwijderd.
     */
    tijdstipInactief?: string;
    /**
     * Dit is het tijdstip waarop een versie van een BAG-object is opgenomen in de registratie van de landelijke voorziening. De gegevens komen daarmee beschikbaar voor afnemers.
     */
    tijdstipRegistratieLV: string;
    /**
     * Het tijdstip waarop een voorkomen door een synchronisatie bericht van een bronhouder is verwijderd uit de geldige levenscylcus in de registratie van de landelijke voorziening.
     */
    tijdstipNietBAG?: string;
    /**
     * Dit is het tijdstip waarop een versie van een BAG-object is beëindigd in de registratie van de landelijke voorziening.
     */
    tijdstipEindRegistratieLV?: string;
    /**
     * Dit is het tijdstip waarop een toekomstig voorkomen van een BAG-object uit de geldige levenscylcus is verwijderd in de registratie van de landelijke voorziening.
     */
    tijdstipInactiefLV?: string;
}

