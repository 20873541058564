/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type OrganizationRole = 'Superadmin' | 'Administrator' | 'User';

export const OrganizationRole = {
    Superadmin: 'Superadmin' as OrganizationRole,
    Administrator: 'Administrator' as OrganizationRole,
    User: 'User' as OrganizationRole
};

