import { Dimensions } from './dimensions';
import { BoundingBox } from './bounding-box';
import { Level as BuildingModelLevel } from '@shared/services/assets/building-elements';
import * as clipperLib from 'js-angusj-clipper/web';
import { Feature, FeatureWithAngle, RoomFeature } from './features';
import {
  BatchIdToFaceIndices,
  calculateParametersForSimpleFeature,
  objectToPolygons,
  stairsToPolygons,
} from './utils';
import { ObjectCategory } from '@api-clients/bim/model/models';
import { Buffers } from './buffers';

const SCALE = 1e3;

export interface LevelContent {
  description: string;
  rooms: RoomFeature[];
  floors: Feature[];
  walls: Feature[];
  windows: FeatureWithAngle[];
  doors: FeatureWithAngle[];
  stairs: Feature[];
  dimensions: Dimensions;
  boundingBox?: BoundingBox;
}

export class Level {
  description: string = '';
  rooms: RoomFeature[] = [];
  floors: Feature[] = [];
  walls: Feature[] = [];
  windows: FeatureWithAngle[] = [];
  doors: FeatureWithAngle[] = [];
  stairs: Feature[] = [];
  dimensions: Dimensions;
  boundingBox?: BoundingBox;

  constructor({
    description,
    rooms,
    floors,
    walls,
    windows,
    doors,
    stairs,
    dimensions,
    boundingBox,
  }: LevelContent) {
    this.description = description;
    this.rooms = rooms;
    this.floors = floors;
    this.walls = walls;
    this.windows = windows;
    this.doors = doors;
    this.stairs = stairs;
    this.dimensions = dimensions;
    this.boundingBox = boundingBox;
  }

  public static fromBuildingModelLevel(
    level: BuildingModelLevel,
    buffers: Buffers[],
    batchIdToFaceIndices: BatchIdToFaceIndices,
    clipper: clipperLib.ClipperLibWrapper
  ): Level {
    const objectsWithGeometry = level.objects.filter((object) => object.indices);
    const floors = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Floor)
      .flatMap((object) => objectToPolygons(object, buffers, batchIdToFaceIndices, clipper, SCALE));
    const windows = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Window)
      .flatMap((object) => objectToPolygons(object, buffers, batchIdToFaceIndices, clipper, SCALE))
      .map((feature) => ({ ...feature, ...calculateParametersForSimpleFeature(feature) }));
    const walls = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Wall)
      .flatMap((object) => objectToPolygons(object, buffers, batchIdToFaceIndices, clipper, SCALE));
    const doors = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Door)
      .flatMap((object) => objectToPolygons(object, buffers, batchIdToFaceIndices, clipper, SCALE))
      .map((feature) => ({ ...feature, ...calculateParametersForSimpleFeature(feature) }));
    const stairs = objectsWithGeometry
      .filter((object) => object.category?.type === ObjectCategory.Stair)
      .flatMap((object) => stairsToPolygons(object, buffers, batchIdToFaceIndices, clipper, SCALE));

    const rooms = level.rooms
      .filter((room) => room.indices)
      .map((room) =>
        objectToPolygons(room, buffers, batchIdToFaceIndices, clipper, SCALE).map((feature) => {
          return { ...feature, category: room.category };
        })
      )
      .flat();

    const elements = walls.concat(...windows, ...doors, ...stairs, ...floors);
    const boundingBox = elements.length
      ? elements.map((object) => object.bbox).reduce((acc, box) => acc.join(box))
      : undefined;

    const dimensions = new Dimensions(rooms);

    return new Level({
      description: level.name,
      rooms,
      floors,
      walls,
      windows,
      doors,
      stairs,
      dimensions,
      boundingBox,
    });
  }

  public findChildById(id: string): Feature | undefined {
    return [
      ...this.floors,
      ...this.walls,
      ...this.windows,
      ...this.doors,
      ...this.stairs,
      ...this.rooms,
    ].find((feature) => feature.bimId == id);
  }

  public findRoomById(id: string): RoomFeature | undefined {
    return this.rooms.find((room) => room.bimId == id);
  }
}
