import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeLineDto } from '@api-clients/dossier';
import { FormControl } from '@angular/forms';
import { TimelineService } from '../../services';

@Component({
  selector: 'app-dossier-timeline',
  templateUrl: './dossier-timeline.component.html',
  styleUrl: './dossier-timeline.component.scss',
})
export class DossierTimelineComponent {
  @Input() control: FormControl = new FormControl();
  @Input() smallVersion: boolean = false;

  @Output() selectedTimelineItem: TimeLineDto | undefined;
  @Output() timelineEventClick: EventEmitter<TimeLineDto> = new EventEmitter<TimeLineDto>();
  @Output() imageClick: EventEmitter<{ item: TimeLineDto; imageId: string }> = new EventEmitter<{
    item: TimeLineDto;
    imageId: string;
  }>();

  constructor(public timelineService: TimelineService) {}

  emitImageClick(item: TimeLineDto, imageId: string): void {
    this.imageClick.emit({ item, imageId });
  }
}
