import { Pipe, PipeTransform } from '@angular/core';
import { BuildingMetadata } from '@core/models/building-model';

@Pipe({
  name: 'additionalProperties',
})
export class AdditionalPropertiesPipe implements PipeTransform {
  transform(value: BuildingMetadata | undefined): object {
    if (value === undefined) return {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      additionalFiles,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      location,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      rotation,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      address,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      surface,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      usages,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      addresses,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      city,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      postalCode,
      ...meta
    } = value ?? {};
    return meta ?? {};
  }
}
