import { ItemShare } from './share.model';
import { ISectionItem, SectionItem } from './section-item.model';

import {
  ItemStatusTypeDto,
  PermissionTypeDto,
  QuestionType,
  SectionItemType,
  WorkspaceDto,
  WorkspaceSettingsDto,
} from '@api-clients/workspace';
import { Section } from './section.model';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ContentItem } from './content.item.model';
import { Question } from './question.model';
import { EmptyGuid } from '@core/models/constants';
import { WorkspaceService } from '../services/workspace.service';

export class Workspace implements WorkspaceDto {
  id: string = EmptyGuid;
  name: string = '';
  isActive: boolean = true;
  description: string = '';
  accessible: boolean = true;
  sharedWith: Array<string> = [];

  workspaceSettings!: WorkspaceSettingsDto;
  shares: ItemShare[];
  siShares: ItemShare[] = [];
  requestContentItemStatus!: ItemStatusTypeDto;
  creatorId: string = EmptyGuid;

  // client side properties
  creationTimestampUtc!: Date;
  lastModifiedTimestampUtc!: Date;
  permissionType!: PermissionTypeDto;
  isTemplate: boolean = false;
  sections: Section[];
  // private _allRcis: RequestContentItem[] = [];
  // private _currentSectionId: string = EmptyGuid;

  private workspaceService: WorkspaceService;

  constructor(workspace: WorkspaceDto, workspaceService: WorkspaceService) {
    const { sections, shares, ...rest } = workspace;
    Object.assign(this, rest);
    const allShares = shares?.map((share) => new ItemShare(share, workspaceService)) || [];
    this.shares = allShares.filter((share) => !share.sectionItemId);
    this.siShares = allShares.filter((share) => share.sectionItemId);
    this.sections = sections?.map((section) => new Section(section, workspaceService)) || [];
    const findSectionItem = (si: SectionItem): SectionItem =>
      this.sections.find((section) => section.id === si.id) || si;
    this.sections.forEach((section) => (section.items = section.items.map(findSectionItem)));
    this.workspaceService = workspaceService;
  }

  // get currentSectionId(): string {
  //   return this._currentSectionId ?? this.rootSection?.id;
  // }

  // set currentSectionId(value: string) {
  //   this._currentSectionId = value;
  // }

  get expiryDate(): Date | undefined {
    if (this.isTemplate || !this.workspaceSettings) return undefined;
    return new Date(this.workspaceSettings.workspaceExpiryDate);
  }

  get showExpire(): boolean {
    return this.workspaceSettings?.workspaceRetentionInDays !== -1;
  }

  get aboutToExpire(): boolean {
    const now = new Date();
    const expiryDate = this.expiryDate?.getTime();
    return (
      !!expiryDate &&
      this.workspaceSettings?.workspaceRetentionInDays !== -1 &&
      expiryDate <= now.setDate(now.getDate() + 7)
    );
  }

  // get containsUnapprovedRcis(): boolean {
  //   return this.allRcis.some((rci) => rci.status !== ItemStatusTypeDto.Approved);
  // }

  get isEmpty(): boolean {
    return this.rootSection?.items.length === 0;
  }

  get rootSection(): Section | undefined {
    return this.sections.find((section) => !section.parentId);
  }

  get allContentItems(): ContentItem[] {
    return filter<ContentItem>(this.allItems, SectionItemType.ContentItem);
  }

  // get contentItems(): ContentItem[] {
  //   return filter<ContentItem>(this.items, SectionItemType.ContentItem);
  // }

  // get hasUploads(): boolean {
  //   const rcis = this.allRcis;
  //   return rcis.some((rci) => rci.hasContentItems) || this.allContentItems.length > 0;
  // }

  // get allRcis(): RequestContentItem[] {
  //   return this._allRcis || filter<RequestContentItem>(this.allItems, SectionItemType.FileRequest);
  // }

  // set allRcis(value: RequestContentItem[]) {
  //   this._allRcis = value;
  // }

  // get currentSection(): Section | undefined {
  //   return this.getSection(this.currentSectionId);
  // }

  // get items(): SectionItem[] {
  //   return this.currentSection?.items || [];
  // }

  get selectedItems(): SectionItem[] {
    return this.sections
      .map((section) => section.items)
      .flat()
      .filter((u) => u.selected);
  }

  // get allVisibleSelected(): boolean {
  //   return this.items.filter((u) => !u.selected).length === 0;
  // }

  get allItems(): SectionItem[] {
    return this.sections.map((section) => section.items).flat();
  }

  // get itemsSorted(): SectionItem[] {
  //   const items = this.items.filter((i) => i.type !== SectionItemType.ContentItem);
  //   const files = this.items.filter((i) => i.type === SectionItemType.ContentItem);
  //   return items.concat(files);
  // }

  get allWorkflowIds(): string[] {
    return filter<Question>(this.allItems, SectionItemType.Question)
      .filter((items) => items.questionType === QuestionType.MultipleChoice)
      .map((question) => question.value)
      .flat()
      .filter((values) => values.workflowId);
  }

  // get visibleSections(): Section[] {
  //   return filter<Section>(this.items, SectionItemType.Section) || [];
  // }

  get defaultShares(): ItemShare[] {
    return this.shares.filter((share) => share.isDefaultShare);
  }

  // get rcis(): RequestContentItem[] {
  //   return this.visibleSections
  //     .map((section) => filter<RequestContentItem>(section.items))
  //     .flat() as RequestContentItem[];
  // }

  // get canEdit(): boolean {
  //   return (
  //     this.currentSection?.permissionType === PermissionTypeDto.ReadWrite ||
  //     this.currentSection?.permissionType === PermissionTypeDto.Owner
  //   );
  // }

  // get containsOwnedSections(): boolean {
  //   return this.currentSection?.isOwner || this.visibleSections.some((section) => section.isOwner);
  // }

  get isOwner(): boolean {
    return this.permissionType === PermissionTypeDto.Owner;
  }

  // getSectionItem<TSectionItemType>(id: string): TSectionItemType | undefined {
  //   return this.getSectionItems().find((item) => item.id === id) as TSectionItemType | undefined;
  // }

  // getSectionItems(): SectionItem[] {
  //   const items = this.sections.map((section) => section.items).flat();
  //   const parentItems = this.getSection(this.currentSectionId)?.items || [];
  //   return [...items, ...parentItems];
  // }

  getSection(id: string): Section | undefined {
    return this.sections.find((section) => section.id === id);
  }

  isLocked = (item: ISectionItem): boolean => {
    return (
      (!this.isOwner && this.requestContentItemStatus !== ItemStatusTypeDto.Unknown) ||
      (!this.isOwner && item.status === ItemStatusTypeDto.Approved) ||
      this.requestContentItemStatus === ItemStatusTypeDto.Approved ||
      this.requestContentItemStatus === ItemStatusTypeDto.Archived
    );
  };

  // focusItem(itemToFocusId: string): void {
  //   const itemToFocus = this.getSectionItem<SectionItem>(itemToFocusId);
  //   if (itemToFocus) itemToFocus.focused = true;
  // }

  removeSections(condition: (section: SectionItem) => boolean): void {
    this.sections = this.sections.filter(condition);
    this.sections.forEach((section) => {
      section.items = section.items.filter(condition);
    });
  }

  getParent(itemId: string): Section | undefined {
    return this.sections.find((s) => s.items.find((item) => item.id === itemId));
  }

  // getPath(item: SectionItem, useFileRequestNames: boolean, path?: string): string | undefined {
  //   const rci = this.allRcis.find((r) => r.getContentItem(item.id));
  //   path = path ?? rci?.getFilename(item, useFileRequestNames) ?? item.name;
  //   if (rci) item = rci;
  //   const parent = this.getParent(item.id);
  //   if (parent?.parentId) {
  //     const cappedName = parent.name.substring(0, 255);
  //     return this.getPath(parent, useFileRequestNames, `${cappedName}/${path}`);
  //   }
  //   return path;
  // }

  // removeSectionItem(itemId: string): void {
  //   const section = this.getParent(itemId);
  //   if (section) {
  //     section.removeSectionItem(itemId);
  //   } else {
  //     const fr = this.allRcis.find((r) => r.getContentItem(itemId));
  //     if (fr) fr.removeContentItem(itemId);
  //   }
  // }

  moveSectionItem(item: SectionItem, fromIndex: number, toIndex: number): void {
    const section = this.getParent(item.id);
    if (!section) return;
    moveItemInArray(section.items, fromIndex, toIndex);
  }

  transferSectionItem(
    fromSectionId: string,
    toSectionId: string,
    fromIndex: number,
    toIndex: number
  ): void {
    const fromSection = this.getSection(fromSectionId);
    const toSection = this.getSection(toSectionId);
    if (fromSection && toSection) {
      transferArrayItem(fromSection.items, toSection.items, fromIndex, toIndex);
    }
  }
}

export function filter<TType>(items: SectionItem[], ...types: SectionItemType[]): TType[] {
  return items.filter((section) => types.includes(section['type'])) as TType[];
}
