export * from './adres.service';
import { AdresService } from './adres.service';
export * from './adresUitgebreid.service';
import { AdresUitgebreidService } from './adresUitgebreid.service';
export * from './adresseerbaarObject.service';
import { AdresseerbaarObjectService } from './adresseerbaarObject.service';
export * from './algemeneInformatie.service';
import { AlgemeneInformatieService } from './algemeneInformatie.service';
export * from './bronhouder.service';
import { BronhouderService } from './bronhouder.service';
export * from './ligplaats.service';
import { LigplaatsService } from './ligplaats.service';
export * from './nummeraanduiding.service';
import { NummeraanduidingService } from './nummeraanduiding.service';
export * from './openbareRuimte.service';
import { OpenbareRuimteService } from './openbareRuimte.service';
export * from './pand.service';
import { PandService } from './pand.service';
export * from './standplaats.service';
import { StandplaatsService } from './standplaats.service';
export * from './verblijfsobject.service';
import { VerblijfsobjectService } from './verblijfsobject.service';
export * from './woonplaats.service';
import { WoonplaatsService } from './woonplaats.service';
export const APIS = [AdresService, AdresUitgebreidService, AdresseerbaarObjectService, AlgemeneInformatieService, BronhouderService, LigplaatsService, NummeraanduidingService, OpenbareRuimteService, PandService, StandplaatsService, VerblijfsobjectService, WoonplaatsService];
