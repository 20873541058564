import { BuildingModel } from '@shared/services/assets/building-model';
import { Mesh } from 'three';
import { BoundingBox } from './bounding-box';
import { Level } from './level';
import { Buffers } from './buffers';
import { Observable } from 'rxjs';
import { Feature, RoomFeature } from '@shared/components/floor-plan/floorplan/features';

export class FloorPlan {
  levels: Level[] = [];
  boundingBox!: BoundingBox;
  complete: boolean = false;
  levelNames: string[] = [];

  private constructor(
    levels: Level[],
    boundingBox: BoundingBox,
    complete: boolean,
    levelNames: string[]
  ) {
    this.levels = levels;
    this.boundingBox = boundingBox;
    this.complete = complete;
    this.levelNames = levelNames;
  }

  static fromBuildingModel(model: BuildingModel): Observable<FloorPlan> {
    const batchIdToFaceIndices = model.children.map((child) => {
      const batchIdToFaceIndices = new Map<number, number[]>();
      const faceIndexArray = (child as Mesh).geometry.index!.array;
      const vertexBatchIndexArray = (child as Mesh).geometry.attributes['_batch_ids']!.array;
      for (let i = 0; i < faceIndexArray.length; i += 3) {
        const vertexIndex = faceIndexArray[i];
        const batchId = vertexBatchIndexArray[vertexIndex];
        const facesForBatchId = batchIdToFaceIndices.get(batchId);
        if (facesForBatchId) {
          facesForBatchId.push(i / 3);
        } else {
          batchIdToFaceIndices.set(batchId, [i / 3]);
        }
      }
      return batchIdToFaceIndices;
    });
    const buffers = model.children.map(
      (mesh) =>
        <Buffers>{
          positionBuffer: (mesh as Mesh).geometry.attributes['position'].array,
          indexBuffer: (mesh as Mesh).geometry.index!.array,
        }
    );

    const rotationCorrectedBoundingBox = model.boundingBox
      .clone()
      .applyMatrix4(model.children[0].matrixWorld.invert());
    const boundingBox = new BoundingBox(
      rotationCorrectedBoundingBox.min.x * 1000,
      rotationCorrectedBoundingBox.max.x * 1000,
      rotationCorrectedBoundingBox.min.z * 1000,
      rotationCorrectedBoundingBox.max.z * 1000
    );

    return new Observable<FloorPlan>((observer) => {
      const worker = new Worker(new URL('../floor-plan-calculation.worker', import.meta.url));
      const levels: Level[] = [];
      const levelNames = model.levels.map((l) => l.name);
      observer.next(new FloorPlan(levels, boundingBox, false, levelNames));
      worker.onmessage = ({ data }): void => {
        levels.push(new Level(data));
        observer.next(
          new FloorPlan(levels, boundingBox, model.levels.length === levels.length, levelNames)
        );
      };

      const input = {
        levels: model.levels,
        buffers: buffers,
        batchIdToFaceIndices: batchIdToFaceIndices,
      };

      worker.postMessage(input);
    });
  }

  findChildById(id: string): Feature | undefined {
    return this.levels
      .map((level) => level.findChildById(id))
      .find((feature) => feature !== undefined);
  }

  findFeatureAndLevelById(id: string): { feature: Feature; level: number } | undefined {
    return this.levels
      .map((level, index) => ({ feature: level.findChildById(id)!, level: index }))
      .find((feature) => feature.feature !== undefined);
  }

  findRoomById(id: string): RoomFeature | undefined {
    return this.levels
      .map((level) => level.findRoomById(id))
      .find((feature) => feature !== undefined);
  }
}
