import { TilesRenderer } from '3d-tiles-renderer';
import { Box3, BufferAttribute, Mesh, Object3D } from 'three';
import { cadastralIdsToBatchIds } from './indexing';
import { baseColor, highlightColor, ownedColor } from './colors';
import { CadastralId } from '@services/building-overview.service';

export function initTilesRenderer(tile_api: string): TilesRenderer {
  const tiles = new TilesRenderer(tile_api);
  tiles['displayBoxBounds'] = true;
  tiles['colorMode'] = 7;
  tiles.lruCache.minSize = 85;
  tiles.lruCache.maxSize = 115;
  tiles.optimizeRaycast = true;
  tiles.autoDisableRendererCulling = true;

  tiles.errorTarget = 0;
  tiles.errorThreshold = 60;
  tiles.loadSiblings = false;
  tiles.maxDepth = 15;
  tiles['showEmptyTiles'] = false;

  tiles.downloadQueue.priorityCallback = (tile): number => 1 / tile.cached.distance;
  return tiles;
}

export function updateBuildingColorsForTile(
  tile: Object3D,
  ownedCadastralIds: CadastralId[],
  selectedCadastralIds: CadastralId[]
): void {
  const ownedBuildingBatchIds: number[] = cadastralIdsToBatchIds(tile, ownedCadastralIds);
  const selectedBuildingBatchIds: number[] = cadastralIdsToBatchIds(tile, selectedCadastralIds);
  // Take the first child, which is the tile mesh. The remaining children are HD buildings.
  const object = tile.children[0];
  if (object.type !== 'Mesh') return;
  const mesh = object as Mesh;
  const raw_buffer = mesh.geometry.getAttribute('_batchid').array;
  // Convert batch ids to rgba colors
  const vertexColorArray = new Float32Array((raw_buffer.length / 7) * 4);
  const ids_length = raw_buffer.length / 7;
  for (let i = 0; i < ids_length; i++) {
    const id = raw_buffer[i * 7 + 6];
    if (selectedBuildingBatchIds.includes(id)) vertexColorArray.set(highlightColor, i * 4);
    else if (ownedBuildingBatchIds.includes(id)) vertexColorArray.set(ownedColor, i * 4);
    else vertexColorArray.set(baseColor, i * 4);
  }
  // Create a buffer attribute from the array, with 4 values (RGBA) per vertex
  const colorBuffer = new BufferAttribute(vertexColorArray, 4);
  // Set the colors on the geometry
  mesh.geometry.setAttribute('_vertexColor', colorBuffer);
}

export function setBuildingColorByCadastralId(
  tile: Object3D,
  cadastralIds: CadastralId[],
  color: number[]
): void {
  const batchIds = cadastralIdsToBatchIds(tile, cadastralIds);
  // If cadastralId is not found in this tile, return.
  if (!batchIds.length) return;

  // Traverse the mesh geometry and set all vertices that belong to the batch_id to the new color
  // This code is performance critical, so it is written in a way that avoids unnecessary property
  // lookups at the cost of readability.
  const mesh = tile.children[0] as Mesh;
  if (mesh.type !== 'Mesh') return;
  const vertex_color_attribute = mesh.geometry.getAttribute('_vertexColor');
  const vertex_color_array = vertex_color_attribute.array;
  const mesh_attributes_array = mesh.geometry.getAttribute('_batchid').array;
  const mesh_attributes_length = mesh_attributes_array.length / 7;
  for (let i = 0; i < mesh_attributes_length; i++) {
    if (batchIds.includes(mesh_attributes_array[i * 7 + 6])) {
      vertex_color_array.set(color, i * 4);
    }
  }
  vertex_color_attribute.needsUpdate = true;
}

export function recenterTiles(tiles: TilesRenderer): void {
  const box = new Box3();
  if (tiles.getBounds(box)) {
    box.getCenter(tiles.group.position);
    tiles.group.position.multiplyScalar(-1);
  }
}
