import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Mode } from './mode';
import { PermissionService } from '../permission-service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-authorized-view',
  standalone: false,
  templateUrl: './authorized-view.component.html',
  styleUrl: './authorized-view.component.scss',
})
export class AuthorizedViewComponent implements AfterViewInit {
  @ViewChild('content', { read: ViewContainerRef }) contentViewContainer!: ViewContainerRef;

  @Input() public permission: string | string[] = '';
  @Input() public scope: string = '';
  @Input() public mode: Mode = Mode.Hide; // Use the enum as an input

  constructor(private readonly permissionService: PermissionService) {
    // when a new rpt token was pushed, listen to this and update the disabled state.
    if (this.permissionService.tokenUpdated$) {
      this.permissionService.tokenUpdated$.pipe(takeUntilDestroyed()).subscribe(() => {
        this.ngAfterViewInit();
      });
    }
  }

  /**
   * Determines whether the current user has permissions with a specific scope
   */
  public hasAuth(): boolean {
    // Handle single permission
    if (typeof this.permission === 'string') {
      return this.permissionService.userHasPermissionWithScope(this.permission, this.scope);
    }

    // Handle multiple permissions. If one of them is true, return true.
    return this.permission
      .map((permission) =>
        this.permissionService.userHasPermissionWithScope(permission, this.scope)
      )
      .includes(true);
  }

  ngAfterViewInit(): void {
    if (this.mode === Mode.Disable) {
      this.disableChildren();
    }
  }

  private disableChildren(): void {
    const hasAuth = this.hasAuth();

    const contentElement = this.contentViewContainer.element.nativeElement.parentElement; // this is the app-authorized-view element
    const elements = contentElement.querySelectorAll('button,input,select');

    for (let i = 0; i < elements.length; i++) {
      const child = elements[i];
      // Disable buttons and inputs or any specific child elements as needed.
      child.disabled = !hasAuth;
    }
  }

  protected readonly Mode = Mode;
}
