/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationRole } from './organizationRole';
import { CreateUserRequestHonorific } from './createUserRequestHonorific';
import { CreateUserRequestAddress } from './createUserRequestAddress';


export interface CreateUserRequest { 
    firstName: string;
    lastName: string;
    email: string;
    organizationId: string;
    organizationRole: OrganizationRole;
    address?: CreateUserRequestAddress | null;
    languageCode?: string | null;
    middleName?: string | null;
    honorific?: CreateUserRequestHonorific | null;
    jobTitle?: string | null;
    phoneNumber?: string | null;
}
export namespace CreateUserRequest {
}


