<div class="changes-summary">
  <div class="changes-summary-header">
    <h1 class="h6">{{ 'changes' | translate }}</h1>
  </div>
  <div class="changes-summary-content">
    <ng-container *ngFor="let unsavedElement of unsavedElements">
      <div *ngIf="isInstanceOfChangedElement(unsavedElement)">
        <button
          #propertyButton
          (click)="activateElement(unsavedElement)"
          *ngFor="let property of unsavedElement.originalValues | keyvalue"
          class="changes-summary-content-item">
          <button
            (click)="removePropertyChange(unsavedElement, property.key, $event, propertyButton)"
            class="material-symbols-rounded close">
            close
          </button>

          <h3 class="h6">
            {{ getTypeName(unsavedElement) | lowercase | translate }}
          </h3>
          <!--        Todo: Use typeof??-->
          <p *ngIf="property.key === 'products'">{{ 'products-changed' | translate }}</p>
          <p *ngIf="property.key !== 'products'">
            {{ property.key | lowercase | translate }}
            {{
              property.value !== undefined
                ? ('from' | translate) + ' ' + (property.value.toString() | translate)
                : ''
            }}
            {{ 'changed_to' | translate }}
            {{ unsavedElement.element.properties[property.key].toString() ?? '' | translate }}
          </p>
        </button>

        <button
          #categoryButton
          (click)="activateElement(unsavedElement)"
          *ngIf="unsavedElement.originalCategory as originalCategory"
          class="changes-summary-content-item">
          <button
            (click)="removeCategoryChange(unsavedElement, $event, categoryButton)"
            class="material-symbols-rounded close">
            close
          </button>
          <h3 class="h6">
            {{ getTypeName(unsavedElement) | lowercase | translate }}
          </h3>
          <p>
            {{ 'category' | lowercase | translate }}
            {{ ('from' | translate) + ' ' + (originalCategory.toString() | translate) }}
            {{ 'changed_to' | translate }}
            {{ unsavedElement.element['category'].toString() ?? '' | translate }}
          </p>
        </button>
      </div>

      <div *ngIf="isInstanceOfAddedElement(unsavedElement)">
        <button
          #newElementButton
          (click)="activateElement(unsavedElement)"
          class="changes-summary-content-item">
          <button
            (click)="removeAddedElement(unsavedElement, $event, newElementButton)"
            class="material-symbols-rounded close">
            close
          </button>
          <h3 class="h6">
            {{ getTypeName(unsavedElement) | lowercase | translate }}
          </h3>
          <p>
            {{ ('newly-added' | translate) }}
          </p>
        </button>
      </div>
    </ng-container>
  </div>
</div>
